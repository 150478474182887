import { Box, Button, CircularProgress, CircularProgressLabel, Divider, Drawer, DrawerContent, DrawerOverlay, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Heading, IconButton, Input, Link, List, SlideFade, Stack, Switch, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tag, TagLabel, TagLeftIcon, Text, Textarea, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { ChevronsUpDownIcon, InfoIcon, MicIcon, PauseIcon, PhoneMissedIcon, PlayIcon, PlusIcon, RotateCcwIcon, ShieldIcon, Trash2Icon, VerifiedIcon, XIcon } from "lucide-react";
import { RefObject, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { attachPhoneNumber, getFiles, getPhoneNumbers } from "~/lib/app/assistants/thunk";
import { createFile } from "~/lib/app/files/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import { PhoneInput } from "react-international-phone";
// @ts-ignore
import { PhoneNumberUtil } from "google-libphonenumber";
import { searchIntegrations, searchVoices } from "~/lib/app/voice-library/thunk";
import { AsyncSelect, Select, chakraComponents } from "chakra-react-select";
import _ from "lodash";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

interface ConfigProps {
    onFinalSubmit: (payload: any, errors?: any) => void;
    data: any;
    values?: any;
    api_key?: string;
    bindSubmitForm?: (fn: any) => void
}


function Audio({
    url,
    onInteraction,
  }: {
    url: string;
    onInteraction: (
      state: "playing" | "paused",
      ref?: RefObject<HTMLAudioElement> | null
    ) => void;
  }) {
    const [duration, setDuration] = useState(0);
    const [track, setTrack] = useState(0);
    const [isPaused, setPaused] = useState(true);
    const ref = useRef<HTMLAudioElement>(null);
  
    return (
      <Flex
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        alignItems={"center"}
        gap={3}
      >
        <audio
          onEnded={() => {
            onInteraction("paused", ref);
            setTrack(0);
            setPaused(true);
          }}
          onTimeUpdate={(e) => {
            setTrack(Math.ceil(e.currentTarget.currentTime));
          }}
          hidden
          ref={ref}
          src={url}
          onLoadedMetadata={(e) => {
            setDuration(Math.ceil(ref.current?.duration || 0));
          }}
        />
        <CircularProgress
          max={duration}
          value={track}
          color="var(--chakra-colors-primary-500)"
          size={8}
        >
          <CircularProgressLabel mx={"auto"}>
            {isPaused ? (
              <PlayIcon
                size={12}
                style={{ marginLeft: "auto", marginRight: "auto" }}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (ref.current?.paused) {
                    ref.current?.play();
                    setPaused(false);
                  } else {
                    ref.current?.pause();
                    setPaused(true);
                  }
                }}
              />
            ) : (
              <PauseIcon
                color="var(--chakra-colors-primary-500)"
                size={16}
                style={{ marginLeft: "auto", marginRight: "auto" }}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (ref.current?.paused) {
                    ref.current?.play();
                    setPaused(false);
                  } else {
                    ref.current?.pause();
                    setPaused(true);
                  }
                }}
              />
            )}
          </CircularProgressLabel>
        </CircularProgress>
        {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                  <SliderTrack>
                      <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
              </Slider> */}
      </Flex>
    );
  }

const dropdownStyles: any = {
    dropdownIndicator: (b: any) => ({
      ...b,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: 0,
    }),
    control: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
    menuList: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
  };
  
  const dropdownComponents = {
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronsUpDownIcon size={12} />
      </chakraComponents.DropdownIndicator>
    ),
  };

export default function SettingsConfig(props: ConfigProps) {
    const [isDirty, setIsDirty] = useState(false);
    const [phoneState, setPhoneState] = useState("loading");
    const [fileState, setFileState] = useState("loading");
    const [phoneOptions, setPhoneOptions] = useState([]);
    const [voiceOptions, setVoiceOptions] = useState([]);
    const [integrationsOptions, setIntegrationsOptions] = useState([]);
    const [fileOptions, setFileOptions] = useState([]);
    const navigate = useNavigate();
    const create = useAppSelector((state) => state.app.files.create);
    const successEvaluationOptions = [
      {
        rubricType: "AutomaticRubric",
        description:
          "Automatically evaluates submissions based on predefined criteria and metrics.",
      },
      {
        rubricType: "NumericScale",
        description:
          "Evaluates submissions using a numerical scale, typically from 1 to 10 or 1 to 5.",
      },
      {
        rubricType: "PercentageScale",
        description: "Evaluates submissions by assigning a percentage score.",
      },
      {
        rubricType: "PassFail",
        description: "Evaluates submissions with a simple pass or fail outcome.",
      },
      {
        rubricType: "Checklist",
        description:
          "Evaluates submissions based on a checklist of required criteria.",
      },
      {
        rubricType: "DescriptiveScale",
        description:
          "Provides detailed, qualitative feedback and evaluation based on descriptive criteria.",
      },
      {
        rubricType: "LikertScale",
        description:
          "Evaluates submissions using traditional letter grades Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree",
      },
      {
        rubricType: "Matrix",
        description:
          "Evaluates submissions using a matrix that combines multiple criteria and levels of achievement.",
      },
    ];
    const { organization } = useParams();
    const dispatch = useAppDispatch();
    const {
      isOpen: isDrawerOpen,
      onOpen: onDrawerOpen,
      onClose: onDrawerClose,
    } = useDisclosure();
    const toast = useToast();
  
    useEffect(() => {
      (async () => {
        if (props.api_key) {
          const numbers = await getPhoneNumbers(props.api_key!);
          setPhoneOptions(numbers);
          setPhoneState("success");
        } else {
          setPhoneOptions([]);
          setPhoneState("success");
        }
      })();
    }, [props.api_key]);
  
    useEffect(() => {
      (async () => {
        const voices = await searchVoices();
        setVoiceOptions(voices);
      })();
    }, []);

    useEffect(() => {
      if (organization) {
        (async () => {
          const integrations = await searchIntegrations(organization!);
          setIntegrationsOptions(integrations);
        })();
      }
    }, [organization]);
  
    useEffect(() => {
      (async () => {
        if (props.api_key) {
          const files = await getFiles(props.api_key!);
          setFileOptions(files);
          setFileState("success");
        } else {
          setFileState("success");
        }
      })();
    }, [props.api_key]);
  
    return (
      <>
        {" "}
        <Drawer size={"md"} isOpen={isDrawerOpen} onClose={() => {
          onDrawerClose()
        }}>
          <DrawerOverlay
            style={{
              backdropFilter: "blur(4px)",
            }}
          />
          <DrawerContent boxShadow={0} background={"transparent"}>
            <Formik
              initialValues={{
                file: null,
              }}
              onSubmit={(values) => {
                dispatch(
                  createFile(organization!, values.file, async () => {
                    onDrawerClose();
                    toast({
                      title: "File has been uploaded successfully",
                      status: "success"
                    });
                    const files = await getFiles(props.api_key!);
                    setFileOptions(files);
                  })
                );
              }}
            >
              {({ errors, touched, values, setFieldValue }) => {
                return (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Stack
                      h={"calc(100vh - 24px)"}
                      rounded={10}
                      m={3}
                      justifyContent={"space-between"}
                      background={"white"}
                    >
                      <Flex
                        p={4}
                        pb={0}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Heading size={"md"}>Add your knowledge base</Heading>
                        <Flex gap={2}>
                        <IconButton
                          size={"sm"}
                          variant={"none"}
                          icon={<XIcon size={20} />}
                          aria-label=""
                          onClick={() => {
                            onDrawerClose();
                          }}
                        />
                        </Flex>
                      </Flex>
                      <Divider my={2} />
                      <Stack px={4} flex={1}>
                        <FormControl>
                          <FormLabel>Choose your file</FormLabel>
                          <Dropzone
                            maxFiles={1}
                            accept={{
                              "application/*": [".pdf"],
                            }}
                            useFsAccessApi={false}
                            onDrop={(acceptedFiles) => {
                              setFieldValue("file", acceptedFiles[0]);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                {/* @ts-ignore */}
                                <div {...getRootProps({ style: baseStyle })}>
                                  <input
                                    accept="application/pdf"
                                    {...getInputProps()}
                                  />
                                  <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </FormControl>
                        <Text>Files</Text>
                        <List>
                          {values.file ? (
                            /* @ts-ignore */
                            <Text>{values.file.name}</Text>
                          ) : (
                            <Text>No files selected</Text>
                          )}
                        </List>
                      </Stack>
                      <Flex m={2} justify={"flex-end"}>
                        <Button
                          isDisabled={!values.file}
                          boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
                          _hover={{
                            boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
                          }}
                          isLoading={create.state === "loading"}
                          type="submit"
                          fontSize={"sm"}
                          variant={"solid"}
                          colorScheme="primary"
                        >
                          Click to upload
                        </Button>
                      </Flex>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </DrawerContent>
        </Drawer>
        <Formik
          initialValues={{
            provider: props.data.voice_config?.provider || "ELEVENLABS",
            voice: props.data.voice_config?.voice_id,
            voice_name: props.data.voice_config?.voice_name,
            name: props.data.name,
            model: props.data.voice_config?.model,
            background_noise: props.data.advanced_config?.backgroundSound || null,
            phone_numbers: props.data.phone_numbers || [],
            voicemail_message: props.data.advanced_config?.voicemailMessage,
            endcall_message: props.data.advanced_config?.endCallMessage,
            forwarding_number: props.data.advanced_config?.forwardingPhoneNumber,
            phone_number_id: props.data.advanced_config?.phone_number_id,
            phone_number: props.data.advanced_config?.phone_number,
            file: props.data.advanced_config?.file,
            files: props.data.ai_model_config?.files || [],
            personality: props.data.ai_model_config?.personality,
            file_id: props.data.advanced_config?.file_id,
            hipaa_enabled: props.data.advanced_config?.hipaaEnabled,
            success_evaluation_rubrik:
              props.data.advanced_config?.analysisPlan?.successEvaluationRubric,
            recording_enabled: props.data.advanced_config?.recordingEnabled,
            transcriber_provider: props.data.transcriber_config?.provider,
            language: props.data.transcriber_config?.language,
            transcriber_model: props.data.transcriber_config?.model,
            greeting_message: props.data.advanced_config?.firstMessage,
            about: props.data.about,
            webhooks: (props.data?.meta?.webhooks || []).map((v: any) => ({
              "url": v.url,
              "headers": Object.keys(v.headers || {}).map((e: string) => ({
                "name": e,
                "value": v.headers[e]
              }))
            })),
            instructions: (props.data?.meta?.instructions || []),
            logo: props.data?.meta?.logo,
            title: props.data?.meta?.title,
            subtitle: props.data?.meta?.subtitle,
            description: props.data?.meta?.description,
            enable_end_call_function:
              props.data.advanced_config?.endCallFunctionEnabled,
              integrations: props.data.integrations
          }}
          onSubmit={(values) => {
            const instance = PhoneNumberUtil.getInstance();
            let forwardingNumber;
            try {
              if (
                instance.isValidNumber(
                  instance.parseAndKeepRawInput(values.forwarding_number)
                )
              ) {
                forwardingNumber = values.forwarding_number;
              }
            } catch (err) {
              
            }
            if (isDirty) {
              props.onFinalSubmit({
                voice_config: {
                  ...(props.data.voice_config || {}),
                  provider: values.provider || "ELEVENLABS",
                  voice_name: values.voice_name,
                  voice_id: values.voice,
                  model: values.model,
                },
                ai_model_config: {
                  ...(props.data.ai_model_config || {}),
                  ...(values.files?.length
                    ? {
                        knowledge_base: {
                          provider: "canonical",
                          topK: 5,
                        },
                      }
                    : {}),
                  personality: values.personality,
                  files: values.files || [],
                },
                system_prompt: props.data.system_prompt || "You are a smart assistant",
                name: values.name,
                about: values.about,
                phone_numbers: values.phone_numbers || [],
                advanced_config: {
                  ...(props.data.advanced_config || {}),
                  file: values?.file,
                  file_id: values?.file_id,
                  hipaaEnabled: values.hipaa_enabled,
                  recordingEnabled: values.recording_enabled,
                  voicemailMessage: values.voicemail_message,
                  endCallMessage: values.endcall_message,
                  forwardingPhoneNumber: forwardingNumber,
                  firstMessage: values.greeting_message,
                  success_evaluation_rubrik:
                    values.success_evaluation_rubrik,
                  phone_number: values.phone_number,
                  phone_number_id: values.phone_number_id,
                  enable_end_call_function: values.enable_end_call_function,
                  backgroundSound:
                    values.background_noise === null
                      ? "off"
                      : values.background_noise,
                  endCallFunctionEnabled: values.enable_end_call_function,
                  analysisPlan: {
                    ...(props.data.advanced_config?.analysisPlan || {}),
                    successEvaluationRubric:
                      values.success_evaluation_rubrik,
                    structuredDataSchema: props.data.advanced_config?.analysisPlan?.structuredDataSchema,
                    structuredDataPrompt:
                      props.data?.advanced_config?.analysisPlan
                        ?.structuredDataPrompt ||
                      "From the given transcript, you need to extract most top questions as an array of string, top words as array of string, conversation score out of 10",
                  },
                },
                meta: {
                  ...(props.data.meta || {}),
                  webhooks: values.webhooks.map((h: any) => {
                    const headers: any = {}
                    for (const header of (h.headers || [])) {
                      headers[header.name] = header.value
                    }
                    return {
                      "url": h.url,
                      "headers": headers
                    }
                  }),
                  title: values.title,
                  subtitle: values.subtitle,
                  logo: values.logo,
                  description: values.description,
                  instructions: values.instructions || []
                },
                transcriber_config: {
                  ...(props.data.transcriber_config || {}),
                  provider: values.transcriber_provider,
                  model: values.transcriber_model,
                  language: values.language,
                },
                integrations: values.integrations
              });
            }
          }}
        >
          {({ errors, values, setFieldValue, submitForm }) => {
            props.bindSubmitForm?.(submitForm)
            return (
              <Form
                onChange={(v) => {}}
              >
                <Tabs h={"calc(100vh - 48px)"} colorScheme="primary" orientation="vertical">
                  <TabList borderLeftColor={"transparent"} borderRight={"1px solid"} borderRightColor={"gray.200"}>
                    <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Agent Details</Tab>
                    {props.data.use_case === "RESEARCH" ? <></> : <>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Voice Configuration</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Phone Configuration</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Knowledge Base</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Privacy & Forwarding</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Integrations</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Webhooks</Tab>
                      <Tab textAlign={"start"} whiteSpace={"nowrap"} alignItems={"start"} pl={2} fontSize={"sm"} minW={"fit-content"} w={"fit-content"} className="font-semibold">Microsite Template Data</Tab>
                      </>
                    }
                  </TabList>
                  <TabIndicator />
                  <TabPanels h={"calc(100vh - 48px)"} overflow={"scroll"}>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        gap={1}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Agent Details
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            Edit your agent details
                          </Text>
                        </Box>
                        <Divider />
                        <Stack>
                          <Grid gap={6} gridTemplateColumns={"repeat(3, 1fr)"}>
                            <FormControl isInvalid={!!errors.name} py={4}>
                              <FormLabel>
                                <Stack gap={0}>
                                  <Text fontSize={14}>Name</Text>
                                </Stack>
                              </FormLabel>
                              <Field
                                validate={(v: string) => {
                                  let error;
                                  if (!v) {
                                    error = "Agent name required";
                                  }
                                  return error;
                                }}
                                size="sm"
                                fontSize={14}
                                rounded={6}
                                as={Input}
                                placeholder="Enter agent name"
                                name="name"
                                value={values.name}
                                onChange={(v: any) => {
                                  setFieldValue("name", v.target.value);
                                  setIsDirty(true);
                                }}
                              />
                            </FormControl>
                            <FormControl isInvalid={!!errors.personality} py={4}>
                              <FormLabel>
                                <Stack gap={0}>
                                  <Text fontSize={14}>Personality</Text>
                                </Stack>
                              </FormLabel>
  
                              <Select
                                value={{
                                  label: _.capitalize(values.personality),
                                  value: values.personality,
                                }}
                                placeholder={"Select personality"}
                                selectedOptionColorScheme="primary"
                                size={"sm"}
                                onChange={(v: any) => {
                                  setFieldValue?.("personality", v.value);
                                  setIsDirty(true);
                                }}
                                variant={"outline"}
                                chakraStyles={dropdownStyles}
                                components={dropdownComponents}
                                options={[
                                  {
                                    label: "Friendly",
                                    value: "FRIENDLY",
                                  },
                                  {
                                    label: "Firm",
                                    value: "FIRM",
                                  },
                                  {
                                    label: "Warm",
                                    value: "WARM",
                                  },
                                  {
                                    label: "Empathetic",
                                    value: "EMPATHETIC",
                                  },
                                  {
                                    label: "Auto",
                                    value: "AUTO",
                                  },
                                ]}
                              />
                            </FormControl>
                          </Grid>
                          <Divider hidden />
                          <FormControl py={1}>
                            <FormLabel>
                              <Stack gap={0}>
                                <Text fontSize={14}>Greeting Message</Text>
                                <Text color={"gray"} fontSize={"small"}>
                                  The first message that the agent will say. This
                                  can also be a URL to a containerized audio file
                                </Text>
                              </Stack>
                            </FormLabel>
                            <Field
                              size="sm"
                              fontSize={14}
                              rounded={6}
                              as={Input}
                              placeholder="Enter greeting message"
                              name="greeting_message"
                              value={values.greeting_message}
                              onChange={(v: any) => {
                                setFieldValue("greeting_message", v.target.value);
                                setIsDirty(true);
                              }}
                            />
                          </FormControl>
                          <FormControl isInvalid={!!errors.about} py={4}>
                            <FormLabel>
                              <Stack gap={0}>
                                <Text fontSize={14}>
                                  What do you need your agent to do?
                                </Text>
                              </Stack>
                            </FormLabel>
                            <Field
                              validate={(v: string) => {
                                let error;
                                if (!v) {
                                  error = "Agent details required";
                                }
                                return error;
                              }}
                              size="sm"
                              fontSize={14}
                              rounded={6}
                              as={Textarea}
                              rows={4}
                              placeholder="Tell us about your agent in detail"
                              name="about"
                              value={values.about}
                              onChange={(v: any) => {
                                setFieldValue("about", v.target.value);
                                setIsDirty(true);
                              }}
                            />
                          </FormControl>
                          <FormErrorMessage>
                            {errors.about as string}
                          </FormErrorMessage>
                          <Divider />
                        </Stack>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={6}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={3}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Voice Configuration
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            Choose from the list of voices, or sync your{" "}
                            <Link
                              onClick={() => {
                                navigate(`/app/${organization}/voice-library`);
                              }}
                              color={"var(--chakra-colors-primary-500)"}
                            >
                              voice library
                            </Link>{" "}
                            if you aren't able to find your voice in the dropdown.
                            If you are still facing any error, you can enable
                            custom voice and add a voice ID manually.
                          </Text>
                        </Box>
                        <Divider />
                        <Grid gap={4} gridTemplateColumns={"repeat(2, 1fr)"}>
                          <FormControl hidden>
                            <FormLabel fontWeight={600}>Voice</FormLabel>
                            <AsyncSelect
                              value={{
                                label: values.voice_name,
                                value: values.voice,
                              }}
                              loadOptions={(input: string) => {
                                return searchVoices(input);
                              }}
                              onChange={(v: any) => {
                                setFieldValue("voice_name", v?.label);
                                setFieldValue("voice", v?.value);
                                setIsDirty(true);
                              }}
                              defaultOptions={voiceOptions}
                              placeholder={"Select voice id"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                            <Flex mt={2} gap={2} alignItems={"center"}>
                              <Audio
                                onInteraction={(s) => {}}
                                url="https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3"
                              />
                              <Text fontSize={"sm"} fontWeight={600}>
                                Click to play Preview
                              </Text>
                            </Flex>
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              display={"flex"}
                              gap={1}
                              alignItems={"center"}
                              fontWeight={600}
                            >
                              Background Sound{" "}
                              <Tooltip label="This is the background sound in the call. Default for phone calls is 'office' and default for web calls is 'off'.">
                                <InfoIcon size={12} />
                              </Tooltip>
                            </FormLabel>
                            <Select
                              value={{
                                label: _.capitalize(values.background_noise),
                                value: values.background_noise,
                              }}
                              placeholder={"Select background sound"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              onChange={(v: any) => {
                                setFieldValue?.("background_noise", v.value);
                                setIsDirty(true);
                              }}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                              options={[
                                {
                                  label: "Office",
                                  value: "office",
                                },
                                {
                                  label: "Off",
                                  value: "off",
                                },
                              ]}
                            />
                          </FormControl>
                        </Grid>
                        <Divider />
                        <Box mt={4}>
                          <Heading fontWeight={600} size={"md"}>
                            Choose agent voice
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            Choose agent voice from the below recommended voices
                          </Text>
                        </Box>
                        <Grid gap={4} gridTemplateColumns={"repeat(3, 1fr)"}>
                          {(props.data.recommended_voices || []).map(
                            (voice: any) => {
                              return (
                                <Flex
                                  cursor={"pointer"}
                                  onClick={(e) => {
                                    setFieldValue("voice_name", voice?.name);
                                    setFieldValue("voice", voice?.voice_id);
                                    setIsDirty(true);
                                    const instance =
                                      PhoneNumberUtil.getInstance();
                                    let forwardingNumber;
                                    try {
                                      if (
                                        instance.isValidNumber(
                                          instance.parseAndKeepRawInput(
                                            values.forwarding_number
                                          )
                                        )
                                      ) {
                                        forwardingNumber =
                                          values.forwarding_number;
                                      }
                                    } catch (err) {}
                                    props.onFinalSubmit({
                                      voice_config: {
                                        ...(props.data.voice_config || {}),
                                        provider: values.provider || "ELEVENLABS",
                                        voice_name: voice.name,
                                        voice_id: voice.voice_id,
                                        model: values.model,
                                      },
                                      ai_model_config: {
                                        ...(props.data.ai_model_config || {}),
                                        ...(values.files?.length
                                          ? {
                                              knowledge_base: {
                                                provider: "canonical",
                                                topK: 5,
                                              },
                                            }
                                          : {}),
                                        personality: values.personality,
                                        files: values.files || [],
                                      },
                                      name: values.name,
                                      about: values.about,
                                      phone_numbers: values.phone_numbers || [],
                                      advanced_config: {
                                        ...(props.data.advanced_config || {}),
                                        file: values?.file,
                                        file_id: values?.file_id,
                                        hipaaEnabled: values.hipaa_enabled,
                                        recordingEnabled:
                                          values.recording_enabled,
                                        voicemailMessage:
                                          values.voicemail_message,
                                        endCallMessage: values.endcall_message,
                                        forwardingPhoneNumber: forwardingNumber,
                                        firstMessage: values.greeting_message,
                                        success_evaluation_rubrik:
                                          values.success_evaluation_rubrik,
                                        phone_number: values.phone_number,
                                        phone_number_id: values.phone_number_id,
                                        enable_end_call_function:
                                          values.enable_end_call_function,
                                        backgroundSound:
                                          values.background_noise === null
                                            ? "off"
                                            : values.background_noise,
                                        endCallFunctionEnabled:
                                          values.enable_end_call_function,
                                        analysisPlan: {
                                          ...(props.data.advanced_config
                                            ?.analysisPlan || {}),
                                          successEvaluationRubric:
                                            values.success_evaluation_rubrik,
                                          structuredDataSchema: {
                                            type: "object",
                                            properties: {
                                              words: {
                                                type: "array",
                                                description: "Top words",
                                                items: {
                                                  type: "string",
                                                },
                                              },
                                              questions: {
                                                type: "array",
                                                description: "Top questions",
                                                items: {
                                                  type: "string",
                                                },
                                              },
                                              score: {
                                                type: "number",
                                                description:
                                                  "Conversation Score from 0 to 10",
                                              },
                                            },
                                          },
                                          structuredDataPrompt:
                                            props.data?.advanced_config
                                              ?.analysisPlan
                                              ?.structured_data_prompt ||
                                            "From the given transcript, you need to extract most top questions as an array of string, top words as array of string, conversation score out of 10",
                                        },
                                      },
                                      transcriber_config: {
                                        ...(props.data.transcriber_config || {}),
                                        provider: values.transcriber_provider,
                                        model: values.transcriber_model,
                                        language: values.language,
                                      },
                                      integrations: values.integrations
                                    });
                                  }}
                                  justifyContent={"space-between"}
                                  borderRadius={8}
                                  p={4}
                                  border={
                                    "1px solid var(--chakra-colors-gray-200)"
                                  }
                                  w={"full"}
                                >
                                  <Box>
                                    <Flex mb={voice.id !== voice ? 2 : 1}>
                                      <Text fontWeight={600}>{voice.name}</Text>
                                      {voice.voice_id === values.voice ? (
                                        <Tag
                                          size={"sm"}
                                          colorScheme="green"
                                          ml={2}
                                        >
                                          <TagLeftIcon as={VerifiedIcon} />
                                          <TagLabel>selected</TagLabel>
                                        </Tag>
                                      ) : (
                                        <></>
                                      )}
                                    </Flex>
                                    <Text color={"gray"} fontSize={"small"}>
                                      {voice.voice_id}
                                    </Text>
                                    <Text
                                      color={"gray"}
                                      fontSize={"small"}
                                    >{`${_.capitalize(
                                      voice.gender
                                    )} | ${_.capitalize(voice.accent)}`}</Text>
                                  </Box>
                                  <Audio
                                    onInteraction={(state, ref) => {
                                      if (state === "playing") {
                                        // currentRef. = ref?.current
                                      }
                                    }}
                                    url={voice.sample_url}
                                  />
                                </Flex>
                              );
                            }
                          )}
                        </Grid>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={6}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={3}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Phone Configuration
                          </Heading>
                          <Text color={"gray"} fontSize={12}>
                            Choose phone number associated with the agent
                          </Text>
                        </Box>
                        <Divider />
                        {props.data?.provider === "VAPI" && phoneState === "success" && !phoneOptions.length ? (
                          <Stack py={4} gap={0} alignItems={"center"}>
                            <Text fontSize={"lg"} fontWeight={600}>
                              Looks like you don't have any phone numbers
                            </Text>
                            <Text fontSize={"sm"} fontWeight={500}>
                              Click below to request phone numbers
                            </Text>
                            <Button
                              onClick={() => {
                                toast({
                                  title: "Request recieved",
                                  description:
                                    "Your request for phone numbers has been recieved. We will notify you as soon as we add new numbers to your account",
                                });
                              }}
                              mt={2}
                              size={"sm"}
                              colorScheme="primary"
                              variant={"outline"}
                            >
                              Request Numbers
                            </Button>
                          </Stack>
                        ) : (
                          <FormControl>
                            <FormLabel fontWeight={600}>
                              Choose Phone Number
                            </FormLabel>
                            <Select
                              value={values.phone_numbers?.length ? {
                                label: values.phone_numbers[0],
                                value: values.phone_numbers[0],
                              } : {
                                label: values.phone_number || "",
                                value: values.phone_number_id || "",
                              }}
                              options={props.data?.provider === "VAPI" ? phoneOptions : [
                                {
                                  label: values.phone_numbers[0],
                                  value: values.phone_numbers[0],
                                }
                              ]}
                              onChange={async (v: any) => {
                                if (props.data?.provider === "VAPI") {
                                  await attachPhoneNumber(
                                    props.api_key!,
                                    props.data.provider_id,
                                    v.value
                                  );
                                  setFieldValue("phone_number", v.label);
                                  setFieldValue("phone_number_id", v.value);
                                  setIsDirty(true);
                                }

                              }}
                              placeholder={"Select phone number"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                          </FormControl>
                        )}
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={6}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={3}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Heading fontWeight={600} size={"md"}>
                              Set Knowledge Base
                            </Heading>
                            <Text color={"gray"} fontSize={12}>
                              Choose a file below to set your agents knowledge
                              base
                            </Text>
                          </Box>
                          <Button
                            display={
                              fileState === "success" && !fileOptions.length
                                ? "none"
                                : "flex"
                            }
                            isLoading={create.state === "loading"}
                            onClick={() => {
                              onDrawerOpen();
                            }}
                            colorScheme="primary"
                            variant={"outline"}
                            size={"sm"}
                            leftIcon={<PlusIcon size={16} />}
                          >
                            Add file
                          </Button>
                        </Flex>
                        <Divider />
  
                        {fileState === "success" && !fileOptions.length ? (
                          <Stack py={4} gap={0} alignItems={"center"}>
                            <Text fontSize={"lg"} fontWeight={600}>
                              Looks like you don't have any files
                            </Text>
                            <Text fontSize={"sm"} fontWeight={500}>
                              Click below to add files
                            </Text>
                            <Button
                              mt={2}
                              size={"sm"}
                              onClick={() => {
                                onDrawerOpen();
                              }}
                              colorScheme="primary"
                              leftIcon={<PlusIcon size={16} />}
                              variant={"outline"}
                            >
                              Add file
                            </Button>
                          </Stack>
                        ) : (
                          <FormControl>
                            <FormLabel fontWeight={600}>Choose file</FormLabel>
                            <Select
                              isMulti
                              value={(values.files || []).map((f: any) => {
                                return {
                                  label: f.name,
                                  value: f.id,
                                };
                              })}
                              options={fileOptions}
                              onChange={(v: any) => {
                                setFieldValue(
                                  "files",
                                  v.map((a: any) => ({
                                    name: a.label,
                                    id: a.value,
                                  }))
                                );
                                // setFieldValue("file_id", v.value);
                                setIsDirty(true);
                              }}
                              placeholder={"Select files"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                          </FormControl>
                        )}
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={4}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={1}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Privacy & Forwarding
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            This section allows you to configure the privacy
                            settings for the assistant.
                          </Text>
                        </Box>
                        <Divider />
                        <Flex hidden py={1} alignItems={"center"} gap={4}>
                          <ShieldIcon />
                          <Box flex={1}>
                            <Text
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              fontWeight={600}
                            >
                              HIPAA Compliance{" "}
                              <Tooltip
                                hasArrow
                                rounded={8}
                                p={2}
                                py={1}
                                bg={"#343434"}
                                label={
                                  "HIPAA Compliance is enforced at the organization level and overrides individual settings."
                                }
                              >
                                <InfoIcon cursor={"pointer"} size={12} />
                              </Tooltip>
                            </Text>
                            <Text fontSize={12}>
                              When this is enabled, no logs, recordings, or
                              transcriptions will be stored.
                            </Text>
                          </Box>
                          <Switch
                            isChecked={values.hipaa_enabled}
                            onChange={(v) => {
                              setFieldValue("hipaa_enabled", v.target.checked);
                              setIsDirty(true);
                            }}
                          />
                        </Flex>
                        <Flex py={1} alignItems={"center"} gap={4}>
                          <PhoneMissedIcon />
                          <Box flex={1}>
                            <Text
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              fontWeight={600}
                            >
                              Enable End Call Function{" "}
                            </Text>
                            <Text fontSize={12}>
                              This will allow the agent to end the call from its
                              side. (Best for gpt-4 and bigger models.)
                            </Text>
                          </Box>
                          <Switch
                            isChecked={values.enable_end_call_function}
                            onChange={(v) => {
                              setFieldValue(
                                "enable_end_call_function",
                                v.target.checked
                              );
                              setIsDirty(true);
                            }}
                          />
                        </Flex>
                        <Flex py={1} alignItems={"center"} gap={4}>
                          <MicIcon />
                          <Box flex={1}>
                            <Text fontWeight={600}>Recording Enabled </Text>
                            <Text fontSize={12}>
                              Record the conversation with the assistant.
                            </Text>
                          </Box>
                          <Switch
                            isChecked={values.recording_enabled}
                            onChange={(v) => {
                              setFieldValue(
                                "recording_enabled",
                                v.target.checked
                              );
                              setIsDirty(true);
                            }}
                          />
                        </Flex>
                        <FormControl py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Voicemail Message</Text>
                              <Text color={"gray"} fontSize={"small"}>
                                This is the message that the agent will say if the
                                call is forwarded to voicemail.
                              </Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Input}
                            placeholder="Enter voicemail message"
                            name="voicemail_message"
                            value={values.voicemail_message}
                            onChange={(v: any) => {
                              setFieldValue("voicemail_message", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        <FormControl hidden py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Success Evaluation Rubrik</Text>
                              <Text color={"gray"} fontSize={"small"}>
                                This enforces the rubric of the evaluation upon
                                the Success Evaluation.
                              </Text>
                            </Stack>
                          </FormLabel>
                          <Select
                            value={{
                              label: values.success_evaluation_rubrik,
                              value: values.success_evaluation_rubrik,
                            }}
                            placeholder={"Select success evaluation rubrik"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            onChange={(v: any) => {
                              setFieldValue(
                                "success_evaluation_rubrik",
                                v?.value
                              );
                              setIsDirty(true);
                            }}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={{
                              DropdownIndicator:
                                dropdownComponents.DropdownIndicator,
                              Option: ({ innerProps, isDisabled, data }) => {
                                return !isDisabled ? (
                                  <Box
                                    borderBottom={"1px solid #e4e4e4"}
                                    p={2}
                                    px={4}
                                    _hover={{ backgroundColor: "#e4e4e4" }}
                                    cursor={"pointer"}
                                    {...innerProps}
                                  >
                                    <Text fontSize={"sm"}>{data.label}</Text>
                                    {/* // @ts-ignore */}
                                    <Text fontSize={"xs"}>
                                      {(data as any).description}
                                    </Text>
                                  </Box>
                                ) : null;
                              },
                            }}
                            options={successEvaluationOptions.map((s) => {
                              return {
                                label: s.rubricType,
                                value: s.rubricType,
                                description: s.description,
                              };
                            })}
                          />
                        </FormControl>
                        <Divider />
                        <FormControl py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>End Conversation Message</Text>
                              <Text color={"gray"} fontSize={"small"}>
                                This is the message that the agent will say if it
                                ends the conversation.
                              </Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Input}
                            placeholder="Enter end call message"
                            name="endcall_message"
                            value={values.endcall_message}
                            onChange={(v: any) => {
                              setFieldValue("endcall_message", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        <Divider />
                        <FormControl
                          isInvalid={!!errors.forwarding_number}
                          py={4}
                        >
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Forwarding Phone Number</Text>
                              <Text
                                fontStyle={"italic"}
                                color={"gray"}
                                fontSize={"small"}
                              >
                                This Phone number will be used to transfer call
                                from the assistant. (Only applicable with Phone
                                calls not on web calls)
                                {/* <Text
                            as={Link}
                            href="#"
                            color={"var(--chakra-colors-primary-500)"}
                          >
                            Read More
                          </Text> */}
                              </Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={PhoneInput}
                            placeholder="Enter forwarding number"
                            name="forwarding_number"
                            validate={(v: string) => {
                              let error;
                              const instance = PhoneNumberUtil.getInstance();
                              return error;
                            }}
                            value={values.forwarding_number || ""}
                            onChange={(v: any) => {
                              setFieldValue("forwarding_number", v);
                              setIsDirty(true);
                            }}
                          />
                          <FormErrorMessage>
                            {errors.forwarding_number as string}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={4}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={1}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Integrations
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            This section allows you to configure the integrations for the assistant.
                          </Text>
                        </Box>
                        <Divider />
                        <Box mt={3}>
                        <AsyncSelect
                          isMulti
                              value={values.integrations}
                              loadOptions={(input: string) => {
                                return searchIntegrations(organization!, input);
                              }}
                              onChange={(v: any) => {
                                setFieldValue("integrations", v);
                                setIsDirty(true);
                              }}
                              defaultOptions={integrationsOptions}
                              placeholder={"Select integrations"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                        </Box>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={4}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={1}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Webhooks
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            This section allows you to configure the post interaction webhooks for the assistant.
                          </Text>
                        </Box>
                        <Divider />
                        <Box mt={3}>
                        <FieldArray name="webhooks" render={({ insert, remove, push }) => (
                              <Stack minH={240} gap={4}>
                                {values.webhooks.map((v: any, i: number) => {
                                  return (
                                    <>
                                    <Flex gap={2}>
                                      <FormControl isInvalid={!!((errors.webhooks || []) as any[])[i]?.url as boolean}>
                                        <Field size={"sm"} rounded={"md"} validate={(v: string) => {
                                          let error;
                                          if (v && !v.startsWith("https")) {
                                            error = "Invalid url"
                                          }
                                          return error
                                        }} as={Input} value={v?.url} name={`webhooks.${i}.url`} placeholder="Enter request url" />
                                      </FormControl>
                                      <IconButton size={"sm"} variant={"outline"} onClick={() => {
                                        remove(i)
                                      }} colorScheme="red" icon={<Trash2Icon size={16} />} aria-label="" />
                                    </Flex>
                                      {/* <FormErrorMessage>{errors.webhooks?.[i] as string}</FormErrorMessage> */}
                                      <FieldArray name={`webhooks.${i}.headers`} render={({ insert, remove, push }) => (
                                        <Stack borderLeft={"1px solid #d4d4d4"} mt={2} marginLeft={6} pl={4} gap={4}>
                                            <Text fontSize={"sm"} fontWeight={"semibold"}>Headers</Text>
                                            {(v.headers || []).map((v: any, j: number) => {
                                              return (
                                                <>
                                                <Grid gap={4} gridTemplateColumns={`repeat(2, 1fr)`}>
                                                <FormControl isInvalid={!!((errors.webhooks || []) as any[])[i]?.headers?.[j]?.name as boolean}>
                                                  <Field size={"sm"} rounded={"md"} validate={(v: string) => {
                                                    let error;
                                                    if (!v) {
                                                      error = "Invalid header name"
                                                    }
                                                    return error
                                                  }} as={Input} value={v?.name} name={`webhooks.${i}.headers.${j}.name`} placeholder="Enter header name" />
                                                  </FormControl>
                                                  <Flex gap={3} alignItems={"center"}>
                                                    <FormControl isInvalid={!!((errors.webhooks || []) as any[])[i]?.headers?.[j]?.value as boolean}>
                                                    <Field size={"sm"} rounded={"md"} validate={(v: string) => {
                                                      let error;
                                                      if (!v) {
                                                        error = "Invalid header value"
                                                      }
                                                      return error
                                                    }} as={Input} value={v?.value} name={`webhooks.${i}.headers.${j}.value`} placeholder="Enter header value" />
                                                    </FormControl>
                                                    <IconButton size={"sm"} variant={"outline"} onClick={() => {
                                                      remove(j)
                                                    }} colorScheme="red" icon={<Trash2Icon size={16} />} aria-label="" />
                                                  </Flex>
                                                </Grid>
                                                </>
                                                )})}
                                            <Button leftIcon={<PlusIcon size={16} />} variant={"outline"} w={"fit-content"} size={"sm"} type="button" onClick={() => push({
                                              "name": "",
                                              "value": ""
                                            })}>Add headers</Button>
                                        </Stack>)} />
                                    </>
                                  )
                                })}
                                <Button leftIcon={<PlusIcon size={16} />} variant={"blackAlpha"} background={"black"} w={"fit-content"} size={"sm"} color={"white"} type="button" onClick={() => push({
                                  "url": ""
                                })}>
                                Add url
                              </Button>
                              </Stack>
                            )} />
                        </Box>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack
                        borderRadius={8}
                        py={4}
                        px={4}
                        border={"1px solid #e4e4e4"}
                        gap={1}
                      >
                        <Box>
                          <Heading fontWeight={600} size={"md"}>
                            Microsite Template Data
                          </Heading>
                          <Text mt={1} color={"gray"} fontSize={12}>
                            This section allows you to configure the template data mapping for the assistant.
                          </Text>
                        </Box>
                        <Divider />
                        <Box mt={3}><FormControl isInvalid={!!errors.logo} py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Logo</Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            validate={(v: string) => {
                              let error;
                              if (v && !v.startsWith("https://")) {
                                error = 'Invalid image url'
                              }
                              console.log(error)
                              return error;
                            }}
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Input}
                            placeholder="Enter template logo"
                            name="logo"
                            onChange={(v: any) => {
                              setFieldValue("logo", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        <FormControl isInvalid={!!errors.title} py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Title</Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            validate={(v: string) => {
                              let error;
                              return error;
                            }}
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Input}
                            placeholder="Enter template title"
                            name="title"
                            onChange={(v: any) => {
                              setFieldValue("title", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        <FormControl isInvalid={!!errors.subtitle} py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Subtitle</Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            validate={(v: string) => {
                              let error;
                              return error;
                            }}
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Input}
                            placeholder="Enter template subtitle"
                            name="subtitle"
                            onChange={(v: any) => {
                              setFieldValue("subtitle", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        
                        <FormControl isInvalid={!!errors.description} py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Description</Text>
                            </Stack>
                          </FormLabel>
                          <Field
                            validate={(v: string) => {
                              let error;
                              return error;
                            }}
                            size="sm"
                            fontSize={14}
                            rounded={6}
                            as={Textarea}
                            placeholder="Enter template description"
                            name="description"
                            onChange={(v: any) => {
                              setFieldValue("description", v.target.value);
                              setIsDirty(true);
                            }}
                          />
                        </FormControl>
                        <FormControl py={4}>
                          <FormLabel>
                            <Stack gap={0}>
                              <Text fontSize={14}>Instructions</Text>
                            </Stack>
                          </FormLabel>

                        <FieldArray name="instructions" render={({ insert, remove, push }) => (
                              <Stack minH={240} gap={4}>
                                
                                {values.instructions.map((v: any, i: number) => {
                                  return (
                                    <Flex gap={2}>
                                      <FormControl isInvalid={!!((errors.instructions || []) as any[])?.[i] as boolean}>
                                        <Field size={"sm"} rounded={"md"} validate={(v: string) => {
                                          let error;
                                          if (!v) {
                                            error = "Instruction should not be empty"
                                          }
                                          return error
                                        }} as={Textarea} value={v} name={`instructions.${i}`} placeholder="Enter instruction" />
                                      <FormErrorMessage>
                                        {(errors.instructions as any[])?.[i] as string}
                                      </FormErrorMessage>
                                      </FormControl>
                                      <IconButton size={"sm"} variant={"outline"} onClick={() => {
                                        remove(i)
                                      }} colorScheme="red" icon={<Trash2Icon size={16} />} aria-label="" />
                                    </Flex>
                                  )
                                })}
                                <Button leftIcon={<PlusIcon size={16} />} variant={"blackAlpha"} background={"black"} w={"fit-content"} size={"sm"} color={"white"} type="button" onClick={() => push('')}>
                                Add instruction
                              </Button>
                              </Stack>
                            )} />
                          </FormControl>
                        </Box>
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }