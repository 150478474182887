import { AppDispatch } from "~/lib/store";
import { axios } from "~/utils/axios.loader";
import { setCreateIntegrationsLoading, setCreateIntegrationsSuccess, setDeleteIntegrationsLoading, setDeleteIntegrationsSuccess, setListIntegrationsLoading, setListIntegrationsSuccess, setUpdateIntegrationsLoading, setUpdateIntegrationsSuccess } from "./actions";

export const listIntegrations = (organization_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setListIntegrationsLoading())
    try {
        const request = await axios.get(`/organization/${organization_id}/integrations/?page_size=100`)
        dispatch(setListIntegrationsSuccess(request.data.integrations))
    } catch (err) {
        
    }
}

export const createIntegration = (organization_id: string, {
    config
}: { config: any }, callback?: () => void, errorCallback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateIntegrationsLoading())
    try {
        const request = await axios.post(`/organization/${organization_id}/integrations/`, {
            ...config
        })
        dispatch(setCreateIntegrationsSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}

export const updateIntegration = (organization_id: string, integration_id: string, {
    config
}: { config: any }, callback?: () => void, errorCallback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setUpdateIntegrationsLoading())
    try {
        const request = await axios.patch(`/organization/${organization_id}/integrations/${integration_id}`, {
            ...config
        })
        dispatch(setUpdateIntegrationsSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}


export const deleteIntegration = (organization_id: string, integration_id: string, callback?: () => void, errorCallback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setDeleteIntegrationsLoading())
    try {
        const request = await axios.delete(`/organization/${organization_id}/integrations/${integration_id}`)
        dispatch(setDeleteIntegrationsSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}