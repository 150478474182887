import {
  Box,
  Flex,
  Heading,
  IconButton,
  Img,
  Skeleton,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
} from "@chakra-ui/react";
import {
  CloudDownloadIcon,
  HistoryIcon,
  MoreVerticalIcon,
  PlusIcon,
} from "lucide-react";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "~/@components/Page";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { getAllLogs } from "~/lib/app/logs/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";

export default function Billing() {
    const dispatch = useAppDispatch()
    const { organization } = useParams()
    const metadata = useAppSelector(state => state.app.logs.list.metadata)
    const _organization = useAppSelector(state => state.app.organizations.get)

    useEffect(() => {
        if (organization) {
            dispatch(getAllLogs(organization!))
        }
    }, [organization])

  return (
    <Page
      actions={[
        
      ]}
      subtitle="Manage your billing"
      title="Billing"
    >
      <StatGroup gap={4} p={4}>
        <Stat rounded={12} p={4} border={"1px solid #cacaca"}>
          <StatLabel>Total Calls</StatLabel>
          <StatNumber>{metadata?.total_count || "-"}</StatNumber>
          <StatHelpText>
            
            Total number of calls used
          </StatHelpText>
        </Stat>
        <Stat rounded={12} p={4} border={"1px solid #cacaca"}>
          <StatLabel>Credits Usage</StatLabel>
          <StatNumber>{metadata?.total_count || "-"} / 10,468</StatNumber>
          <StatHelpText>
            
            No of credits used / Total number of credits
          </StatHelpText>
        </Stat>
      </StatGroup>
      <Stack gap={4} p={4}>
        <Flex gap={2} alignItems={"center"}>
          <HistoryIcon size={20} />
          <Heading fontSize={"lg"}>Previous Credit History</Heading>
        </Flex>
        <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
          <TableHeader className="!bg-slate-50">
            <TableRow>
              <TableHead className="w-[300px] font-semibold">Date</TableHead>
              <TableHead colSpan={2} className="font-semibold">
                No of Credits
              </TableHead>
              <TableHead className="font-semibold">Credit Type</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium border-b">04 Dec 2024</TableCell>
                <TableCell colSpan={2} className="border-b">8,468</TableCell>
                <TableCell className="border-b">{<Badge variant={"green-subtle"}>Complimentary Credits</Badge>}</TableCell>
                
            </TableRow>
            <TableRow>
              <TableCell className="font-medium border-b">{moment.parseZone(_organization.data?.created_at).format("DD MMM, YYYY") }</TableCell>
                <TableCell colSpan={2} className="border-b">2,000</TableCell>
                <TableCell className="border-b">{<Badge variant={"green-subtle"}>Complimentary Credits</Badge>}</TableCell>
                
            </TableRow>
            {/* <TableRow>
              <TableCell className="w-[300px]">
                <Skeleton h={4} rounded={8} />
              </TableCell>
              <TableCell colSpan={2}>
                <Skeleton h={4} rounded={8} />
              </TableCell>
              <TableCell>
                <Skeleton h={4} rounded={8} />
              </TableCell>
              <TableCell>
                <Skeleton h={4} rounded={8} />
              </TableCell>
              <TableCell>
                <Skeleton h={4} rounded={8} />
              </TableCell>
              <TableCell>
                <Skeleton h={4} rounded={8} />
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </Stack>
    </Page>
  );
}
