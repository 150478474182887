import {
  Avatar,
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Button as CoreButton,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Img,
  Input,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useSteps,
  useToast,
  TabIndicator,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  loadSpecificAgent,
  testAgentViaCallV2,
  updateV2Agent,
  uploadAgentImage,
} from "../../lib/app/assistants/thunk";
import { Field, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftIcon,
  Laptop2Icon,
  MicIcon,
  MicOffIcon,
  PencilIcon,
  PhoneOffIcon,
  PhoneOutgoingIcon,
  RedoDotIcon,
  RocketIcon,
  RotateCcwIcon,
} from "lucide-react";
import _ from "lodash";
import {
  resetAgentUpdate,
  setAgentGetReset,
  setAgentUpdateSuccess,
} from "../../lib/app/assistants/actions";
import AssistantLogs from "./AssistantLogs";
import Feedback from "./Feedback";
import Vapi from "@vapi-ai/web";
import { PhoneInput } from "react-international-phone";
// @ts-ignore
import { PhoneNumberUtil } from "google-libphonenumber";
import "../../assets/css/phone-input.scss";
import Dropzone from "react-dropzone";
import AIAnimation from "../../assets/animation/ai.json";
import Lottie from "lottie-react";
import SettingsConfig from "./Assistants/SettingsConfig";
import {
  TabsContent,
  TabsList,
  TabsTrigger,
  Tabs as TabsV2,
} from "~/components/ui/tabs";
import Insights from "./Assistants/Insights";
import { Badge } from "~/components/ui/badge";
import Tasks from "./Assistants/Tasks";
import { Button } from "~/components/ui/button";
import { createTask, listTasks } from "~/lib/app/tasks/thunk";
import {
  listTaskRunsByAssistant,
  triggerTaskRun,
} from "~/lib/app/task_runs/thunk";
import { ChatIcon } from "@chakra-ui/icons";
import Chat from "./Chat";
import Millis from "@millisai/web-sdk";

interface TabItemProps extends TabProps {
  icon: ReactNode;
}

export function TabItem(props: TabItemProps) {
  return (
    <Flex align={"center"} h={8} gap={2}>
      {props.icon}
      <Text fontWeight={"semibold"}>{props.title}</Text>
    </Flex>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

interface ConfigProps {
  onFinalSubmit: (payload: any, errors?: any) => void;
  data: any;
  values?: any;
  api_key?: string;
}

function AnalysisConfig(props: ConfigProps) {
  return <AssistantLogs id={props.data.id} />;
}

export default function SpecificAssistant() {
  const { assistant: id } = useParams();
  const organization = useAppSelector((state) => state.app.organizations.get);
  const assistant = useAppSelector((state) => state.app.assistants.get);
  const create = useAppSelector((state) => state.app.tasks.create);
  const create_run = useAppSelector((state) => state.app.task_runs.create);
  const updatedAssistant = useAppSelector(
    (state) => state.app.assistants.update
  );
  const dispatch = useAppDispatch();
  const [hasCallStarted, setHasCallStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isTestLoading, setTestLoading] = useState(false);
  const vapi = useRef<Vapi | undefined>();
  const millis = useRef<any>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const onFinalSubmit = _.debounce((payload, callback?: () => void) => {
    if (callback) {
      setIsDirty(true);
    }
    console.group("Updating agent");
    const clone = _.cloneDeep(assistant.data);
    const merged: any = {
      ...clone,
      ...payload,
      system_prompt_variables: clone.system_prompt_variables || [],
      recommended_voices: clone.recommended_voices || [],
    };
    dispatch(setAgentUpdateSuccess(merged));
    dispatch(updateV2Agent(organization.data.id, id!, merged, callback || (() => {
      toast({
        title: "Agent updated",
        status: "success"
      })
    }), () => {
      toast({
        title: "Failed to update agent",
        status: "error"
      })
    }));
    onUploadClose();
    console.groupEnd();
  }, 100);
  const navigate = useNavigate();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isTopicOpen,
    onOpen: onTopicOpen,
    onClose: onTopicClose,
  } = useDisclosure();
  const [isImageUploading, setImageUploading] = useState(false);
  const [fn, setFn] = useState()
  const imageOptions = [
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/man-mid.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/indian-girl.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/australian.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/genz.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/lady-u.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/african-american.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/woman-mature.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abhishekranjan_an_icon_for_a_female_personal_assistant_in_pop_25490071-4179-4ae9-ab8f-2943339aa058_2.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abhishekranjan_round_icon_of_a_young_indian_woman_with_indian_t_ece500da-629b-4063-93f3-da4e1c2d0881.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abeba.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/amy.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/anjani.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/candice.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/oya.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/shatru.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/stacy.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/vedant.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/zendaya.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/neo.png?alt=media",
  ];
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(loadSpecificAgent(id!));
    }
  }, [id]);

  useEffect(() => {
    setSelectedImage(assistant.data?.profile_pic || null);
  }, [assistant]);

  useEffect(() => {
    if (assistant.data.provider === "VAPI") {
    if (!vapi.current) {
      if (organization.data?.providers?.vapi?.public_api_key) {
        vapi.current = new Vapi(
          organization.data?.providers?.vapi?.public_api_key
        );
        vapi.current.on("call-end", () => {
          if (timer.current !== null) {
            clearInterval(timer.current);
          }
          setHasCallStarted(false);
          setTime(0);
        });
      }
    }
    } else if (assistant.data.provider === "MILLIS") {
      if (!millis.current) {
        if (organization.data?.providers?.millis?.public_api_key) {
          millis.current = Millis.createClient(
            {
              publicKey: organization.data?.providers?.millis?.public_api_key
            }
          );
          millis.current.on("onclose", () => {
            if (timer.current !== null) {
              clearInterval(timer.current);
            }
            setHasCallStarted(false);
            setTime(0);
          });
        }
      }
    }
  }, [organization, assistant]);

  useEffect(() => {
    return () => {
      if (vapi.current) {
        vapi.current.stop();
      }
      if (millis.current) {
        millis.current.stop()
      }
      dispatch(setAgentGetReset());
      onFinalSubmit.cancel();
    };
  }, []);

  useEffect(() => {
    if (updatedAssistant.state === "success") {
      setTimeout(() => {
        dispatch(resetAgentUpdate());
      }, 3000);
    }
  }, [updatedAssistant]);

  const [countingStatus, setCountingStatus] = useState("pause");

  const statusHandler = (item: any) => {
    if (item.label === "short break") {
    }
  };
  const [time, setTime] = useState(0);
  const timer = useRef<NodeJS.Timeout | number | undefined>();
  const { activeStep } = useSteps({
    index: 0,
    count: 1,
  });
  const [callbackProps, setCallbackProps] = useState<{
    onChatOpen?: any,
    setReadOnly?: any,
    setMessages?: any,
    setLoading?: any
  }>()
  const [handler, setHandler] = useState<any>()
  const steps = [
    { title: "Topic Details", description: "Enter task details" },
    // { title: 'Set your schedule', description: 'Enter your schedule' }
  ];

  return (
    <Stack overflow={"hidden"} height={"calc(100vh)"} pos={"relative"} gap={0}>
      {assistant.data.id ? <Chat onSetCallbackProps={(props: any) => {
        setCallbackProps(props)
      }} id={assistant.data.id} onSetFeedback={(fn: any) => {
          setFn(() => fn)
        }} /> : <></>}
      <CoreButton
        pos={"absolute"}
        bottom={10}
        right={10}
        zIndex={10}
        // boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
        // _hover={{
        //   boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
        // }}
        isLoading={create.state === "loading"}
        onClick={() => {
          // @ts-ignore
          fn?.()
        }}
        rounded={"full"}
        leftIcon={<ChatIcon />}
        variant={"solid"}
        colorScheme="primary"
      >
        Give Feedback
      </CoreButton>
      <Drawer
        size={"lg"}
        isOpen={isTopicOpen}
        onClose={() => {
          onTopicClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent py={4}>
          <DrawerHeader
            py={0}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Heading size={"lg"}>Create task</Heading>
            <DrawerCloseButton top={0} right={0} pos={"relative"} />
          </DrawerHeader>
          <DrawerBody>
            <Stack>
              {/* <Stepper w={"60%"} size='sm' colorScheme='black' index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
      
                <Box flexShrink='0' fontSize={"sm"}>
                  <StepTitle >{step.title}</StepTitle>
                   <StepDescription>{step.description}</StepDescription> 
            </Box>
      
                <StepSeparator />
              </Step>
              ))}
            </Stepper> */}
              {activeStep === 0 ? (
                <Formik
                  initialValues={{
                    topic: "",
                    guidelines: "",
                  }}
                  onSubmit={(values) => {
                    dispatch(
                      createTask(
                        organization.data.id,
                        assistant.data.id,
                        values.topic!,
                        {
                          name: "research-flow/create-research-flow-v1",
                          topic: values.topic,
                          sections: [],
                          guidelines: (
                            values.guidelines.split("\n") || []
                          ).filter((v) => v.length),
                        },
                        (data) => {
                          dispatch(
                            triggerTaskRun(
                              organization.data.id,
                              data.id,
                              () => {
                                dispatch(
                                  listTasks(
                                    organization.data.id,
                                    assistant.data.id
                                  )
                                );
                                dispatch(
                                  listTaskRunsByAssistant(
                                    organization.data.id,
                                    assistant.data.id,
                                    {}
                                  )
                                );
                              }
                            )
                          );
                        }
                      )
                    );
                  }}
                >
                  {({ errors, touched, values, setFieldValue }) => {
                    return (
                      <Form>
                        <Stack gap={4} maxW={"100%"} mt={4}>
                          <FormControl
                            isInvalid={!!values.topic && !touched.topic}
                          >
                            <FormLabel fontSize={"sm"}>
                              What topic do you want me to research ?
                            </FormLabel>
                            <Field
                              value={values.topic}
                              as={Input}
                              size={"sm"}
                              validate={(value: any) => {
                                let error;
                                if (!value) {
                                  error = "Topic is required";
                                }
                                return error;
                              }}
                              name="topic"
                              resize={"none"}
                              borderRadius={8}
                              placeholder="Enter your topic"
                            />
                          </FormControl>
                          <FormControl
                            isInvalid={
                              !!values.guidelines && !touched.guidelines
                            }
                          >
                            <FormLabel fontSize={"sm"}>
                              Enter your guidelines
                            </FormLabel>
                            <Field
                              size={"sm"}
                              value={values.guidelines}
                              as={Textarea}
                              rows={4}
                              validate={(value: any) => {
                                let error;
                                if (!value) {
                                  error = "Guidelines are required";
                                }
                                return error;
                              }}
                              name="guidelines"
                              resize={"none"}
                              borderRadius={8}
                              placeholder="Set guidelines for your report"
                            />
                          </FormControl>
                          <DrawerFooter>
                            <Button
                              type="submit"
                              isLoading={
                                create.state === "loading" ||
                                create_run.state === "loading"
                              }
                              variant={"dark"}
                            >
                              Next
                            </Button>
                          </DrawerFooter>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
              ) : activeStep === 1 ? (
                <Formik
                  initialValues={{
                    topic: null,
                  }}
                  onSubmit={(values) => {}}
                >
                  {({ errors, touched, values, setFieldValue }) => {
                    return (
                      <Form>
                        <Stack maxW={"70%"} mt={4}>
                          <FormControl
                            isInvalid={!!values.topic && !touched.topic}
                          >
                            <FormLabel>
                              What topic do you want me to research ?
                            </FormLabel>
                          </FormControl>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <></>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isUploadOpen}
        onClose={() => {
          onUploadClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Heading size={"sm"}>Update your agent's picture</Heading>
            <Tabs colorScheme="primary" variant={"enclosed"} mt={4} isLazy>
              <TabList>
                <Tab fontSize={"small"} fontWeight={600}>
                  Choose Image
                </Tab>
                <Tab fontSize={"small"} fontWeight={600}>
                  Upload Image
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Grid gap={4} gridTemplateColumns={"repeat(5, 1fr)"}>
                    {imageOptions.map((img) => (
                      <Stack
                        cursor={"pointer"}
                        onClick={
                          selectedImage !== img
                            ? () => {
                                setSelectedImage(img);
                                onFinalSubmit({
                                  profile_pic: img,
                                });
                                toast({
                                  title: "Image updated successfully",
                                  status: "success",
                                });
                              }
                            : undefined
                        }
                        pos={"relative"}
                      >
                        {selectedImage === img ? (
                          <Tag
                            size={"sm"}
                            fontWeight={600}
                            colorScheme={"green"}
                            pos={"absolute"}
                            right={12}
                            bottom={1}
                          >
                            Selected
                          </Tag>
                        ) : (
                          <></>
                        )}
                        <Img rounded={4} w={"full"} src={img} />
                      </Stack>
                    ))}
                  </Grid>
                  {/* <Flex mt={4} justifyContent={"end"}>
                    <Button
                      size={"sm"}
                      colorScheme="primary"
                      isLoading={updatedAssistant.state === "loading"}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Flex> */}
                </TabPanel>
                <TabPanel>
                  <Box>
                    <Formik
                      initialValues={{
                        file: null,
                      }}
                      onSubmit={(values) => {
                        setImageUploading(true);
                        dispatch(
                          uploadAgentImage(
                            organization.data.id,
                            assistant.data.id,
                            values.file,
                            async (_data) => {
                              if (_data.status === "failed") {
                                toast({
                                  title:
                                    "Image upload failed. Please try again",
                                  status: "error",
                                });
                              } else {
                                toast({
                                  title: "Image uploaded successfully",
                                  status: "success",
                                });
                                dispatch(setAgentUpdateSuccess(_data));
                              }
                              setImageUploading(false);
                              onUploadClose();
                            }
                          )
                        );
                      }}
                    >
                      {({ errors, touched, values, setFieldValue }) => {
                        return (
                          <Form
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              rounded={10}
                              justifyContent={"space-between"}
                              background={"white"}
                            >
                              <Flex
                                pb={0}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Heading size={"md"}>Upload</Heading>
                                {/* <IconButton
                          size={"sm"}
                          variant={"none"}
                          icon={<XIcon size={20} />}
                          aria-label=""
                          onClick={() => {
                            onUploadClose();
                          }}
                        /> */}
                              </Flex>
                              <Divider my={2} />
                              <Stack flex={1}>
                                <FormControl>
                                  <FormLabel>Choose your image</FormLabel>
                                  <Dropzone
                                    maxFiles={1}
                                    accept={{
                                      "application/*": [
                                        ".png",
                                        ".jpg",
                                        ".jpeg",
                                        ".webp",
                                        ".svg",
                                      ],
                                    }}
                                    useFsAccessApi={false}
                                    onDrop={(acceptedFiles) => {
                                      setFieldValue("file", acceptedFiles[0]);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section>
                                        <div
                                          {...getRootProps({
                                            /* @ts-ignore */
                                            style: baseStyle,
                                          })}
                                        >
                                          <input
                                            accept=".png,.svg,.jpeg,.jpg,.webp"
                                            {...getInputProps()}
                                          />
                                          <p>
                                            Drag 'n' drop some files here, or
                                            click to select image
                                          </p>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </FormControl>
                                <Text>Files</Text>
                                <List>
                                  {values.file ? (
                                    /* @ts-ignore */
                                    <Text>{values.file.name}</Text>
                                  ) : (
                                    <Text>No image selected</Text>
                                  )}
                                </List>
                              </Stack>
                              <Flex gap={2} m={2} justify={"flex-end"}>
                                <Button
                                  onClick={() => {
                                    onUploadClose();
                                  }}
                                  className="text-sm"
                                  disabled={isImageUploading}
                                  variant={"outline"}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={!values.file}
                                  isLoading={isImageUploading}
                                  type="submit"
                                  className="text-sm"
                                  variant={"dark"}
                                >
                                  Click to upload
                                </Button>
                              </Flex>
                            </Stack>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Heading size={"sm"}>Enter details to test your agent</Heading>
            <Box mt={4}>
              <Formik
                initialValues={{
                  name: "",
                  phone_number: "",
                }}
                onSubmit={(values) => {
                  if (
                    values.name &&
                    values.phone_number &&
                    assistant.data.advanced_config?.phone_number_id
                  ) {
                    setTestLoading(true);
                    dispatch(
                      testAgentViaCallV2(
                        organization.data.id,
                        assistant.data.provider_id,
                        assistant.data.advanced_config?.phone_number_id,
                        values.phone_number,
                        values.name,
                        () => {
                          setTestLoading(false);
                          toast({
                            status: "success",
                            title: "Call initiated successfully",
                            description: `You should recieve a call from ${assistant.data.name} within a few seconds`,
                          });
                          onClose();
                        }
                      )
                    );
                  }
                }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form>
                      <Stack gap={4}>
                        <FormControl isInvalid={!!errors.name && touched.name}>
                          <FormLabel fontSize={"small"}>Name</FormLabel>
                          <Field
                            value={values.name}
                            as={Input}
                            validate={(value: any) => {
                              let error;
                              if (!value) {
                                error = "Name is required";
                              }
                              return error;
                            }}
                            name="name"
                            resize={"none"}
                            borderRadius={8}
                            rows={22}
                            size={"sm"}
                            placeholder="Enter your name"
                          />
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.phone_number && touched.phone_number
                          }
                        >
                          <FormLabel fontSize={"small"}>Phone number</FormLabel>
                          <Field
                            value={values.phone_number}
                            as={PhoneInput}
                            name="phone_number"
                            defaultCountry="in"
                            resize={"none"}
                            disableDialCodePrefill={true}
                            onChange={(phone: any) =>
                              setFieldValue("phone_number", phone)
                            }
                            validate={(value: any) => {
                              let error;
                              if (!value) {
                                error = "Phone number is required";
                              }
                              if (value) {
                                const instance = PhoneNumberUtil.getInstance();
                                try {
                                  instance.isValidNumber(
                                    instance.parseAndKeepRawInput(value)
                                  );
                                } catch (err) {
                                  error = "Invalid phone number";
                                }
                              }
                              return error;
                            }}
                            borderRadius={8}
                            rows={22}
                            size={"sm"}
                            placeholder="Enter your phone number"
                          />
                          <FormErrorMessage>
                            {errors.phone_number}
                          </FormErrorMessage>
                        </FormControl>
                        {/* <FormControl
                          isInvalid={
                            !!errors.agent_phone_number
                          }
                        >
                          <FormLabel fontSize={"small"}>
                            Choose Agent Number
                          </FormLabel>
                          <Select
                            onChange={(v) => {
                              setFieldValue("agent_phone_number", v?.value);
                            }}
                            value={{
                              label: values.agent_phone_number,
                              value: values.agent_phone_number,
                            }}
                            placeholder={"Select agent phone number"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={dropdownComponents}
                            options={(assistant.data.phone_numbers || []).map(
                              (v: string) => ({
                                label: v,
                                value: v,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {errors.agent_phone_number as string}
                          </FormErrorMessage>
                        </FormControl> */}
                      </Stack>
                      <Flex mt={4} justifyContent={"end"}>
                        <Button
                          variant="dark"
                          isLoading={isTestLoading}
                          size={"sm"}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer
        size={"xl"}
        isOpen={isEditOpen}
        onClose={() => {
          onEditClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerHeader display={"flex"} justifyContent={"space-between"} alignItems={"center"} py={2}>
            <Text>Edit agent</Text>
            <Flex gap={3}>
            <CoreButton
              onClick={() => {
                if (assistant.data.provider_id) {
                  handler?.()
                }
              }}
              isLoading={updatedAssistant.state === "loading"}
              type="submit"
              size={"sm"}
              variant={"solid"}
              colorScheme="black"
              bg={"black"}
              leftIcon={<RotateCcwIcon size={20} />}
            >Publish Changes</CoreButton>
            <DrawerCloseButton pos={"relative"} top={0} right={0} />
          </Flex>
          </DrawerHeader>
          
          <Divider />
          <DrawerBody p={0}>
            <SettingsConfig
              bindSubmitForm={(fn: any) => {
                if (!handler) {
                  setHandler(() => fn)
                }
              }}
              onFinalSubmit={onFinalSubmit}
              data={assistant.data}
              api_key={organization.data?.id}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box
        zIndex={40}
        backdropFilter={"blur(5px)"}
        background={"transparent"}
        display={hasCallStarted ? "block" : "none"}
        pos={"absolute"}
        w={"100vw"}
        h={"100vh"}
      >
        <Center h={"80vh"} w={"75vw"}>
          <Stack
            background={"white"}
            px={12}
            py={4}
            w={480}
            rounded={"lg"}
            cursor={"pointer"}
            ml={8}
            alignItems={"center"}
            className="border"
            _before={{
              pos: "absolute",
              background: `linear-gradient(to left, #DD71B8 10%, #FDDE3C')`,
              filter: "blur(10px)",
              left: 0,
              borderRadius: "full",
              top: 0,
              content: '" "',
              h: "full",
              w: "full",
            }}
            pos={"relative"}
            height={12}
          >
            <Box height={150} width={150}>
              <Lottie animationData={AIAnimation} />
            </Box>
            <Flex
              alignItems={"center"}
              zIndex={4}
              gap={6}
              rounded={36}
              background={"white"}
              display={hasCallStarted ? "flex" : "none"}
              bottom={12}
              py={2}
              justifyContent={"space-between"}
              px={2}
              border={"1px solid #cacaca"}
            >
              <IconButton
                onClick={() => {
                  if (isMuted) {
                    vapi?.current?.setMuted(false);
                    setIsMuted(false);
                  } else {
                    vapi?.current?.setMuted(true);
                    setIsMuted(true);
                  }
                }}
                variant={"outline"}
                border={"1px solid #cacaca"}
                colorScheme={isMuted ? "primary" : "none"}
                rounded={"full"}
                icon={
                  isMuted ? <MicOffIcon size={20} /> : <MicIcon size={20} />
                }
                aria-label=""
              />
              <Text textAlign={"center"} flex={1} fontWeight={700}>{`${String(
                Math.floor((time % 3600) / 60)
              ).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
              <IconButton
                colorScheme="red"
                onClick={() => {
                  vapi.current?.stop();
                  millis.current?.stop();
                  if (timer.current !== null) {
                    clearInterval(timer.current);
                  }
                  setHasCallStarted(false);
                }}
                rounded={"full"}
                icon={<PhoneOffIcon size={20} />}
                aria-label=""
              />
            </Flex>
          </Stack>
        </Center>
      </Box>

      <Flex
        gap={2}
        direction={["column", "row"]}
        justifyContent={["start", "space-between"]}
        alignItems={["start", "center"]}
      >
        <Stack gap={2}>
          <Text fontSize={"small"} color={"gray"}></Text>
        </Stack>
      </Flex>
      <Flex className="border-b" justifyContent={"space-between"} px={4} py={3}>
        <Stack mt={1}>
          <Box>
            <Flex gap={2}>
              <IconButton
                size={"sm"}
                aria-label=""
                icon={<ArrowLeftIcon size={16} />}
                variant={"outline"}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Heading size={"lg"}>{assistant.data.name}</Heading>
            </Flex>
            <Text className="text-muted-foreground">
              {assistant.data.about}
            </Text>
          </Box>
          <Flex gap={3} alignItems={"center"}>
            <Flex alignItems={"center"} gap={1}>
              <Box borderRadius={3} className={`w-2.5 h-2.5 bg-green-500`} />
              <Text
                fontSize={"sm"}
                fontWeight={"semibold"}
                className="text-muted-foreground"
              >
                {_.capitalize(assistant.data.use_case)}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={1}>
              <Box borderRadius={3} className={`w-2.5 h-2.5 bg-blue-500`} />
              <Text
                fontSize={"sm"}
                fontWeight={"semibold"}
                className="text-muted-foreground"
              >
                {_.capitalize(assistant.data.ai_model_config?.personality || "Auto")}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={1}>
              <Box borderRadius={3} className={`w-2.5 h-2.5 bg-yellow-500`} />
              <Text
                fontSize={"sm"}
                fontWeight={"semibold"}
                className="text-muted-foreground"
              >
                {_.capitalize(assistant.data.interaction_mode)}
              </Text>
            </Flex>
          </Flex>
          <Flex mt={2} gap={4} alignItems={"center"}>
            <IconButton
              isLoading={isDirty && updatedAssistant.state === "loading"}
              onClick={() => {
                onEditOpen();
              }}
              size={"sm"}
              variant={"outline"}
              aria-label=""
              rounded={"full"}
              icon={<PencilIcon size={16} />}
            />
            {!["RESEARCH"].includes(assistant.data.use_case) ? (
              <>
                <CoreButton
                  onClick={async () => {
                    await window.navigator.clipboard.writeText(
                      `${window.location.origin}/start?agent=${assistant.data.provider_id}&assistantId=${assistant.data.id}&key=${assistant.data.provider === "MILLIS" ? organization.data?.providers?.millis?.public_api_key : organization.data?.providers?.vapi?.public_api_key}&call_type=web`
                    );
                    toast({
                      title: "Agent demo link copied",
                      status: "success",
                    });
                  }}
                  size={"sm"}
                  variant={"outline"}
                  rounded={"full"}
                  leftIcon={<RedoDotIcon size={20} />}
                >
                  Share demo
                </CoreButton>
                {/* <Box
            borderRadius={3}
              className={`w-1 h-1 bg-gray-500`}
            /> */}
              </>
            ) : (
              <></>
            )}
            {assistant.data.use_case === "RESEARCH" ? (
              <Button
                onClick={() => {
                  onTopicOpen();
                }}
                size={"sm"}
                aria-label="Options"
                variant={"dark"}
              >
                {assistant.data.use_case === "RESEARCH" ? (
                  <RocketIcon size={16} />
                ) : (
                  <PhoneOutgoingIcon size={16} />
                )}{" "}
                {assistant.data.use_case === "RESEARCH"
                  ? "Start researching"
                  : "Talk with agent"}
              </Button>
            ) : (
              <Menu>
                <MenuButton
                  isLoading={isTestLoading}
                  as={CoreButton}
                  rounded={"full"}
                  size={"sm"}
                  aria-label="Options"
                  variant={"solid"}
                  backgroundColor={"black"}
                  color={"white"}
                  _hover={{
                    background: "black",
                  }}
                  rightIcon={
                    assistant.data.use_case === "RESEARCH" ? (
                      <RocketIcon size={16} />
                    ) : (
                      <PhoneOutgoingIcon size={16} />
                    )
                  }
                >
                  {" "}
                  {assistant.data.use_case === "RESEARCH"
                    ? "Start researching"
                    : "Talk with agent"}
                </MenuButton>
                <MenuList>
                  <Tooltip
                    hasArrow
                    label={
                      !organization.data?.providers?.[assistant.data.provider?.toLowerCase()]?.public_api_key
                        ? "No public key present in organization for web call"
                        : ""
                    }
                  >
                    <MenuItem
                      isDisabled={
                        !organization.data?.providers?.[assistant.data.provider?.toLowerCase()]?.public_api_key
                      }
                      onClick={async () => {
                        if (assistant.data?.provider_id) {
                          setTestLoading(true);
                          if (assistant.data.provider === "VAPI") {
                            await vapi.current?.start(
                              assistant.data?.provider_id
                            );
                          } else if (assistant.data.provider === "MILLIS") {
                            await millis.current?.start(
                              assistant.data?.provider_id
                            )
                          }
                          setTestLoading(false);
                          setHasCallStarted(true);
                          timer.current = setInterval(() => {
                            setTime((v) => v + 1);
                          }, 1000);
                          toast({
                            title: `Call started. You can now speak with ${
                              assistant.data?.name || ""
                            }`,
                          });
                        }
                      }}
                      fontWeight={600}
                      fontSize={"sm"}
                      icon={<Laptop2Icon size={20} />}
                    >
                      Web Call
                    </MenuItem>
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label={
                      !assistant.data?.advanced_config?.phone_number_id
                        ? "Please attach the phone number below to test the agent"
                        : ""
                    }
                  >
                    <MenuItem
                      fontWeight={600}
                      fontSize={"sm"}
                      isDisabled={
                        !assistant.data?.advanced_config?.phone_number_id
                      }
                      onClick={() => {
                        onOpen();
                        // dispatch(testAgentViaCall())
                      }}
                      icon={<PhoneOutgoingIcon size={20} />}
                    >
                      Phone Call
                    </MenuItem>
                  </Tooltip>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Stack>
        <Box cursor={"pointer"} position={"relative"} role="group">
          <Avatar
            size={"xl"}
            src={assistant.data.profile_pic}
            icon={
              <Avatar
                src={`https://avatar.vercel.sh/${assistant.data.id}`}
                name={assistant.data.name}
              />
            }
            name={assistant.data.name}
          />
          <Center
            onClick={() => {
              onUploadOpen();
            }}
            h={"6rem"}
            display={"none"}
            left={0}
            top={0}
            bg={"gray.100"}
            opacity={0.7}
            borderRadius={"50%"}
            w={"6rem"}
            pos={"absolute"}
            alignItems={"center"}
            justifyItems={"center"}
            _groupHover={{
              display: "flex",
            }}
          >
            <Text fontSize={"sm"} fontWeight={600}>
              Click to edit
            </Text>
          </Center>
        </Box>
      </Flex>
      <Flex direction={"row-reverse"}>
        <Box className="border-l" h={"100vh"} w={420}>
          <Tabs isLazy variant={"unstyled"} colorScheme={"primary"}>
            <TabList>
              <Tab fontSize={"sm"} fontWeight={"semibold"}>
                Conversations
              </Tab>
              <Tab fontSize={"sm"} fontWeight={"semibold"}>
                Feedback
              </Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="4px"
              bg={`hsl(var(--primary))`}
              borderRadius="4px"
            />
            {assistant.data.id ? (
              <TabPanels h={"75vh"} pb={100} overflow={"scroll"}>
                <TabPanel>
                  <AnalysisConfig
                    data={assistant.data}
                    onFinalSubmit={() => {}}
                  />
                </TabPanel>
                <TabPanel>
                  <Feedback onChatOpen={callbackProps?.onChatOpen} setLoading={callbackProps?.setLoading} setMessages={callbackProps?.setMessages} setReadOnly={callbackProps?.setReadOnly} onSetFeedback={(fn: any) => {
                    setFn(() => fn)
                  }} id={assistant.data.id} />
                </TabPanel>
              </TabPanels>
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <Stack p={4} flex={1} h={"80vh"} overflow={"scroll"}>
          <TabsV2 defaultValue="insights">
            <TabsList>
              <TabsTrigger value="insights">
                {assistant.data.use_case === "RESEARCH" ? "Insights" : "Insights"}
              </TabsTrigger>
              {/* {assistant.data.use_case === "RESEARCH" ? (
                <TabsTrigger value="conversations">Insights</TabsTrigger>
              ) : (
                <>
                  <TabsTrigger value="conversations">Conversations</TabsTrigger>
                  <TabsTrigger value="feedback">Feedback</TabsTrigger>
                </>
              )} */}
            </TabsList>
          </TabsV2>
          {assistant.data.use_case !== "RESEARCH" ? (
            <Box>
              <Insights
              id={assistant.data.provider_id}
              agentId={assistant.data.id}
            />
            </Box>
          ) : (
            <Tasks />
          )}
        </Stack>
      </Flex>
    </Stack>
  );
}
