import { Box } from "@chakra-ui/react"

export default function Jiggle ({ ranges = [], reverse = false }: { ranges: number[], reverse: boolean }) {
    
    return (
        <Box className={`jiggle ${reverse ? '' : 'jiggle-xl'}`}>
            {ranges.map((e) => {
                let height;
                if (reverse) {
                    let computedHeight = e * 100 * 1.6
                    if (computedHeight > 40) {
                        height = 40
                    } else if (computedHeight < 14) {
                        height = 14
                    } else {
                        height = computedHeight
                    }
                } else {
                    let computedHeight = e * 100 * 1.8
                    if (computedHeight > 100) {
                        height = 100
                    } else if (computedHeight < 30) {
                        height = 30
                    } else {
                        height = computedHeight
                    }
                }
                return <div className="jiggle-bar" style={{
                    height
                }}></div>
            })}
        </Box>
    )
}