import { Flex, FormControl, FormErrorMessage, FormLabel, Stack, Text, useToast } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { CheckIcon, PlugZapIcon, TrashIcon, Unlink2Icon } from "lucide-react";
import { useParams } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { createIntegration, deleteIntegration, listIntegrations, updateIntegration } from "~/lib/app/integrations/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";

export default function ApnaForm ({ config, onClose }: { config: any, onClose?: any }) {
    const { organization } = useParams()
    const dispatch = useAppDispatch()
    const create = useAppSelector((state) => state.app.integrations.create);
    const update = useAppSelector((state) => state.app.integrations.update);
    const _delete = useAppSelector((state) => state.app.integrations.delete);
    const toast = useToast()

    return (
        <Formik onSubmit={(values) => {
            if (config?.id) {
                dispatch(updateIntegration(organization!, config.id, {
                    config: {
                        "name": values.name,
                        "config": {
                            "host_url": values.hostUrl,
                            "api_key": values.apiKey,
                            "api_secret": values.apiSecret
                        },
                        "category": "ATS",
                        "organization_id": organization,
                        "provider": "APNA"
                    }
                }, () => {
                    dispatch(listIntegrations(organization!))
                    toast({
                        title: "Integration updated successfully",
                        status: "success"
                    })
                    onClose?.()
                }))
            } else {
                dispatch(createIntegration(organization!, {
                    config: {
                        "name": values.name,
                        "config": {
                            "host_url": values.hostUrl,
                            "api_key": values.apiKey,
                            "api_secret": values.apiSecret
                        },
                        "category": "ATS",
                        "organization_id": organization,
                        "provider": "APNA"
                    }
                }, () => {
                    dispatch(listIntegrations(organization!))
                    toast({
                        title: "Integration created successfully",
                        status: "success"
                    })
                    onClose?.()
                }))
            }
        }} initialValues={{
            name: config?.name,
            hostUrl: config?.config?.host_url,
            apiKey: config?.config?.api_key,
            apiSecret: config?.config?.api_secret
        }}>
            {({errors, touched, setFieldValue}) => {
                return (<Form className="h-full">
                    <Stack h={"full"} justifyContent={"space-between"}>
                    <Stack flex={1} gap={4}>
                    <FormControl isInvalid={!!errors.name}>
                            <FormLabel fontSize={"sm"}>Name for your integration</FormLabel>
                            <Field name={"name"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "name is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your integration name" />
                            <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.hostUrl}>
                            <FormLabel fontSize={"sm"}>Host URL</FormLabel>
                            <Field name={"hostUrl"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "Host is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your apna host url" />
                            <FormErrorMessage>{errors.hostUrl as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.apiKey}>
                            <FormLabel fontSize={"sm"}>API Key</FormLabel>
                            <Field name={"apiKey"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "API key is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your apna api key" />
                            <FormErrorMessage>{errors.apiKey as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.apiSecret}>
                            <FormLabel fontSize={"sm"}>API Secret</FormLabel>
                            <Field name={"apiSecret"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "API Secret is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your apna api secret" />
                            <FormErrorMessage>{errors.apiSecret as string}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    <Flex justifyContent={"end"} gap={2} alignItems={"center"} h={12}>
                        <Button onClick={() => {
                            dispatch(deleteIntegration(organization!, config.id, () => {
                                dispatch(listIntegrations(organization!))
                                toast({
                                    title: "Integration deleted successfully",
                                    status: "info"
                                })
                                onClose?.()
                            }))
                        }} type="button" hidden={!config?.id} variant={"destructive"} isLoading={_delete.state === "loading"}>
                            <Unlink2Icon size={20} />
                            <Text>Disconnect</Text>
                        </Button>
                        <Button isLoading={update.state === "loading" || create.state === "loading"}>
                            <PlugZapIcon size={20} />
                            <Text>{config?.id ? "Update connection" : "Connect account"}</Text>
                        </Button>
                    </Flex>
                    </Stack>
                </Form>)
            }}
        </Formik>
    )
}