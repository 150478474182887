/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Box, Flex, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Img, InputGroup, InputRightElement, Link, Stack, Text, useBoolean } from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EyeIcon, EyeOffIcon, MailIcon } from "lucide-react";
import { initiateResetPassword } from "../../lib/auth/login/thunk";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { resetState, setLoginError } from "../../lib/auth/login/actions";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";

export default function ForgotPassword () {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.auth.login.reset.state)
    const error = useAppSelector(state => state.auth.login.reset.error)
    const [isVisible, { toggle: toggleVisibility }] = useBoolean(false)
    const navigate = useNavigate()
    const [params] = useSearchParams()
    
    useEffect(() => {
        if (state === "success") {
           setTimeout(() => {
            navigate("/auth/login")
           }, 2000)
        }
    }, [ state ])

    return (
        <Flex direction={"row-reverse"} justifyContent={"space-between"}>
            <Stack py={6} alignItems={"center"} justifyContent={"space-between"} height={"100vh"} flex={[1]}>
                <Box></Box>
                <Stack justifyContent={"center"} flex={1} alignItems={"center"} gap={4} borderRadius={8} py={8} px={[2, 12]} w={['90vw', '40vw']}>
                    <Stack w={"full"}>
                        <Heading w={"full"}  fontWeight={"black"} variant={"h1"}>Change your password</Heading>
                        <Box h={2.5} background={"hsl(var(--primary))"} w={16}></Box>
                        <Text>Enter your new password below</Text>
                    </Stack>
                    {/* @ts-ignore */}
                    {!["loading", "pending"].includes(state) ? <Alert py={2} mb={3} fontWeight={"medium"} borderRadius={6} variant={"solid"} status={state === "success" ? "success" : "error"}>
                       <AlertTitle>{state !== "success" ? "Failed to update password" : "Password updated"}</AlertTitle>
                    </Alert> : <></> }
                    <Formik onSubmit={(values) => {
                        dispatch(initiateResetPassword(params.get("email")!, params.get("state")!, values.password))
                    }} initialValues={{
                        password: '',
                        cpassword: ''
                    }}>
                        {({ errors, touched, values }) => (
                            <Form style={{
                                width: "100%"
                            }} onChange={() => {
                                dispatch(resetState())
                            }}>
                                <Stack gap={4} w={"full"}>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <FormLabel fontSize={"sm"}>Password</FormLabel>
                                        <InputGroup>
                                            <Field fontSize="sm" validate={(value: any) => {
                                                let error;
                                                if (!value) {
                                                    error = "Password is required"
                                                }
                                                return error
                                            }} as={Input} name="password" rounded={6} placeholder="Enter your new password" type={isVisible ? 'text' : 'password'}  />
                                            <InputRightElement>
                                                <IconButton variant={"none"} size={"sm"} aria-label="" icon={isVisible ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />} onClick={toggleVisibility} />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize={"small"}>{errors.password}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.cpassword && touched.cpassword}>
                                        <FormLabel fontSize={"sm"}>Confirm Password</FormLabel>
                                        <InputGroup>
                                            <Field fontSize="sm" validate={(value: any) => {
                                                let error;
                                                if (value !== values.password) {
                                                    error = "Passwords do not match"
                                                }
                                                return error
                                            }} as={Input} name="cpassword" rounded={6} placeholder="Confirm your password" type={isVisible ? 'text' : 'password'}  />
                                            <InputRightElement>
                                                <IconButton variant={"none"} size={"sm"} aria-label="" icon={isVisible ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />} onClick={toggleVisibility} />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize={"small"}>{errors.cpassword}</FormErrorMessage>
                                    </FormControl>
                                    <Button className="w-full font-semibold" disabled={(state === "loading" || state === "success")} isLoading={(state === "loading" || state === "success")} type="submit" variant={"dark"}> <MailIcon size={16} /> Update password</Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Text mt={4} fontSize={"sm"}>Don't have an account ? <Link href="/auth/signup" fontWeight={"semibold"}  textDecor={"underline"} color="hsl(var(--primary))">Create one</Link></Text>
                </Stack>
            </Stack>
        </Flex>
    )
}