import { ContactsTableProvider } from "./table-provider";
import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DataTableSkeleton } from "~/components/data-table/data-table-skeleton";
import { ContactsTable } from "./table";
import Page from "~/@components/Page";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import moment from "moment-timezone";
import { getSpecificLog } from "~/lib/app/logs/thunk";
import { loadAgents } from "~/lib/app/platform/thunk";
import { useNavigate, useParams } from "react-router-dom";


export default function Contacts () {
    const dispatch = useAppDispatch();
    const organization = useAppSelector(state => state.app.organizations.get)
    const assistants = useAppSelector((state) => state.app.platform.agents.list)
    const navigate = useNavigate()
    const { team } = useParams()

    useEffect(() => {
      if (!assistants.data.length && organization.data.id) {
        dispatch(loadAgents(organization.data.id!, 1, 100, '', {}))
      }
    }, [organization])
    
    return (
      <Page subtitle="Find all your contacts here" title="Contacts">

      <Box mt={6}>
      <ContactsTableProvider onCellClick={(contact: any) => {
        navigate(`/app/${organization.data.id}/team/${team}/contacts/${contact.id}`)
      }}>
        {/**
         * The `DateRangePicker` component is used to render the date range picker UI.
         * It is used to filter the tasks based on the selected date range it was created at.
         * The business logic for filtering the tasks based on the selected date range is handled inside the component.
         */}
        <React.Suspense
          fallback={
            <DataTableSkeleton
              columnCount={5}
              searchableColumnCount={1}
              filterableColumnCount={2}
              cellWidths={["10rem", "40rem", "12rem", "12rem", "8rem"]}
              shrinkZero
            />
          }
        >
          {/**
           * Passing promises and consuming them using React.use for triggering the suspense fallback.
           * @see https://react.dev/reference/react/use
           */}
          <ContactsTable />
        </React.Suspense>
      </ContactsTableProvider>
      </Box>
      </Page>
    )
}