import {
    SET_CREATE_CONTACTS_LOADING,
    SET_CREATE_CONTACTS_SUCCESS,
    SET_CREATE_CONTACTS_ERROR,
    SET_UPDATE_CONTACTS_LOADING,
    SET_UPDATE_CONTACTS_SUCCESS,
    SET_UPDATE_CONTACTS_ERROR,
    SET_DELETE_CONTACTS_LOADING,
    SET_DELETE_CONTACTS_SUCCESS,
    SET_DELETE_CONTACTS_ERROR,
    SET_GET_CONTACTS_LOADING,
    SET_GET_CONTACTS_SUCCESS,
    SET_GET_CONTACTS_ERROR,
    SET_LIST_CONTACTS_LOADING,
    SET_LIST_CONTACTS_SUCCESS,
    SET_LIST_CONTACTS_ERROR,
    SET_CONTACT_INTERACTIONS_LOADING,
    SET_CONTACT_INTERACTIONS_SUCCESS,
    SET_CONTACT_INTERACTIONS_ERROR
} from './constants';
import { BaseArrayState, BaseObjectState } from "../../types"

interface ContactsState {
    create: BaseObjectState;
    update: BaseObjectState;
    delete: BaseObjectState;
    get: BaseObjectState;
    list: BaseArrayState;
    interactions: BaseArrayState
}

const INITIAL_STATE: ContactsState = {
    create: { state: 'pending', data: {}, error: {} },
    update: { state: 'pending', data: {}, error: {} },
    delete: { state: 'pending', data: {}, error: {} },
    get: { state: 'pending', data: {}, error: {} },
    list: { state: 'pending', data: [], error: {}, metadata: {} },
    interactions: { state: 'pending', data: [], error: {}, metadata: {} },
};

const ContactsReducer = (state = INITIAL_STATE, action: any): ContactsState => {
    switch (action.type) {
        case SET_CREATE_CONTACTS_LOADING:
            return { ...state, create: { ...state.create, state: 'loading', error: {} } };
        case SET_CREATE_CONTACTS_SUCCESS:
            return { ...state, create: { ...state.create, state: 'success', data: action.payload, error: {} } };
        case SET_CREATE_CONTACTS_ERROR:
            return { ...state, create: { ...state.create, state: 'error', error: action.payload } };

        case SET_UPDATE_CONTACTS_LOADING:
            return { ...state, update: { ...state.update, state: 'loading', error: {} } };
        case SET_UPDATE_CONTACTS_SUCCESS:
            return { ...state, update: { ...state.update, state: 'success', data: action.payload, error: {} } };
        case SET_UPDATE_CONTACTS_ERROR:
            return { ...state, update: { ...state.update, state: 'error', error: action.payload } };

        case SET_DELETE_CONTACTS_LOADING:
            return { ...state, delete: { ...state.delete, state: 'loading', error: {} } };
        case SET_DELETE_CONTACTS_SUCCESS:
            return { ...state, delete: { ...state.delete, state: 'success', data: action.payload, error: {} } };
        case SET_DELETE_CONTACTS_ERROR:
            return { ...state, delete: { ...state.delete, state: 'error', error: action.payload } };

        case SET_GET_CONTACTS_LOADING:
            return { ...state, get: { ...state.get, state: 'loading', error: {} } };
        case SET_GET_CONTACTS_SUCCESS:
            return { ...state, get: { ...state.get, state: 'success', data: action.payload, error: {} } };
        case SET_GET_CONTACTS_ERROR:
            return { ...state, get: { ...state.get, state: 'error', error: action.payload } };

        case SET_LIST_CONTACTS_LOADING:
            return { ...state, list: { ...state.list, state: 'loading', error: {} } };
        case SET_LIST_CONTACTS_SUCCESS:
            return { ...state, list: { ...state.list, state: 'success', data: action.payload, metadata: action.metadata || {}, error: {} } };
        case SET_LIST_CONTACTS_ERROR:
            return { ...state, list: { ...state.list, state: 'error', error: action.payload } };
        case SET_CONTACT_INTERACTIONS_LOADING:
            return {
                ...state,
                interactions: {
                    state: 'loading', 
                    error: {},
                    data: [], 
                    metadata: action.metadata || {}
                }
            }
        case SET_CONTACT_INTERACTIONS_SUCCESS:
            return {
                ...state,
                interactions: {
                    state: 'success', 
                    error: {},
                    data: action.payload, 
                    metadata: action.metadata || {}
                }
            }
        case SET_CONTACT_INTERACTIONS_ERROR:
            return {
                ...state,
                interactions: {
                    state: 'error', 
                    error: action.error,
                    data: [], 
                    metadata: {}
                }
            }
        default:
            return state;
    }
};

export default ContactsReducer;
