import { DateRangePicker } from "~/components/date-range-picker";
import { InteractionsTableProvider } from "./table-provider";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, HStack, IconButton, Skeleton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { DownloadIcon, LineChartIcon, PauseIcon, PlayIcon, SubtitlesIcon } from "lucide-react";
import { DataTableSkeleton } from "~/components/data-table/data-table-skeleton";
import { InteractionsTable } from "./table";
import Page from "~/@components/Page";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import moment from "moment-timezone";
import WaveSurfer from "wavesurfer.js";
import { useParams } from "react-router-dom";
import WavesurferPlayer from "@wavesurfer/react";
// @ts-ignore
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
// @ts-ignore
import Regions from 'wavesurfer.js/dist/plugins/regions.esm.js'
import Markdown from "react-markdown";
import { githubDarkTheme } from "@uiw/react-json-view/githubDark";
import JsonView from "@uiw/react-json-view";
import { generateCanonicalNames } from "~/utils/helper.utils";
import _ from "lodash"
import { getSpecificLog } from "~/lib/app/logs/thunk";
import { loadAgents } from "~/lib/app/platform/thunk";


export default function Interactions () {
    const logs = useAppSelector((state) => state.app.logs.list);
    const log = useAppSelector((state) => state.app.logs.get);
    const metadata = useAppSelector((state) => state.app.voices.list.metadata);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [assistantId, setAssistantId] = useState<string | undefined>(undefined)
    const [selectedAgent, setSelectedAgent] = useState<{
      label?: string,
      value?: string
    }>({})
    const [selectedDates, setSelectedDates] = useState([
      moment().startOf("month").toDate(),
      moment().endOf("year").toDate(),
    ]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const organization = useAppSelector(state => state.app.organizations.get)
    const [range, setRange] = useState<moment.unitOfTime.StartOf>("day")
    const assistants = useAppSelector((state) => state.app.platform.agents.list)
    const wavesurfer = useRef<WaveSurfer | null>(null)
    const isPlaying = useRef<boolean>(false)
    const [playing, setPlaying] = useState(false)
    const timer = useRef<NodeJS.Timeout | number | null>(null)
    const { log: logId } = useParams()

    useEffect(() => {
      if (!assistants.data.length && organization.data.id) {
        dispatch(loadAgents(organization.data.id!, 1, 100, '', {}))
      }
    }, [organization])
    
    return (
      <Page subtitle="Find all your assistant communications here" title="Conversations">
      <Drawer
        size={"xl"}
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p={2}>
          <Flex display={logs.state === "success" && !logs.data.length ? "none" : "flex"} overflow={"scroll"} h={'calc(100vh)'} flex={0.7}>
          <Stack p={4} flex={0.65} borderRight={"1px solid #e4e4e4"}>
            <Box mb={3}>
              <Text fontWeight={600}>Recording</Text>
              {log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl  ? <Box>
                <WavesurferPlayer
                waveColor={"violet"}
                progressColor={"purple"}
                cursorWidth={1}
                // @ts-ignore
                // media={document.querySelector(`audio[src="${log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl}"]`)}
                url={log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl}
                cursorColor={"#e4e4e4"}
                height={50}
                normalize={true}
                autoScroll={true}
                //@ts-ignore
                onReady={(w) => {
                  wavesurfer.current = w
                }}
                onPlay={() => {
                  isPlaying.current = true
                }}
                onPause={() => {
                  isPlaying.current = false
                }}
                backend={"WebAudio"}
                splitChannels={[{
                    progressColor: "#fde047",
                    waveColor: "#854d0e"
                }, {
                    progressColor: "#67e8f9",
                    waveColor: "#115e59"
                }]} plugins={[
                  Timeline.create(),
                  Regions.create(),
                ]} />
                <Flex align={"center"} mt={4} justify={"space-between"}>
                  <IconButton onClick={() => {
                    wavesurfer.current?.playPause()
                  }} variant={"outline"} size={"sm"} aria-label="" icon={wavesurfer.current?.isPlaying() ? <PauseIcon size={20} /> : <PlayIcon size={20} />} />
                  <IconButton onClick={() => {
                        window.open(log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl, "_blank")
                  }} variant={"outline"} size={"sm"} aria-label="" icon={<DownloadIcon size={20} />} />
                </Flex>
              </Box> : <Text fontSize={"sm"} color={"gray"}>No Recording available</Text>}
            </Box>
            <Tabs size={"sm"} variant={"enclosed"} colorScheme="primary" isLazy>
              <TabList gap={log.state === "loading" ? 2 : 0} borderColor={log.state === "loading" ? "transparent" : "inherit"}>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <SubtitlesIcon size={16} />
                    Transcript
                  </Tab>
                </Skeleton>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <LineChartIcon size={16} />
                    Analysis
                  </Tab>
                </Skeleton>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <Skeleton rounded={8} isLoaded={log.state === "success"}>
                    <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                      <Text fontWeight={"bold"} fontSize={"sm"}>Transcript</Text>
                      {(log.data?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).filter((v: any) => v.role !== "system").map((message: any) => (
                        <Text fontSize={"sm"}>
                          <Text fontWeight={600} as={"span"}>{["bot", "assistant", "ai"].includes(message.role) ? "AI" : "User"}: </Text>
                          <Text as={"span"}>{message.message || message.content}</Text>
                        </Text>
                      ))}
                      {(log.data?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).length === 0 ? <Text fontSize={"sm"}>No Transcript</Text> : <></>}
                    </Stack>
                  </Skeleton>
                </TabPanel>
                <TabPanel px={0}>
                  <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Summary</Text>
                        <Markdown>{log.data?.analysis?.summary || 'No summary'}</Markdown>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Evaluation</Text>
                        <Text>{log.data?.analysis?.evaluation?.summary || 'None'}</Text>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Data</Text>
                        <Text fontSize={"xs"} color={"gray"}>This is the data extracted based on the interaction.</Text>
                        <JsonView value={log.data?.analysis?.extraction || {}} style={githubDarkTheme} />
                      </Stack>
                    </Skeleton>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Stack  flex={0.35} p={4} py={4}>
           <Skeleton hidden rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>ID</Text>
                <Text>{log.data.id}</Text>
              </Stack>
           </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
              <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>Ended Reason</Text>
                <Text>{generateCanonicalNames(log.data?.state || "Ended")}</Text>
              </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Agent Name</Text>
              <Text>{log.data?.agent?.name}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>User Phone Number</Text>
              <Text>{log.data.meta?.customer?.number || "Unknown number"}</Text>
            </Stack>
            </Skeleton>
            {/* <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Phone Number</Text>
              <Text>{log.data.phoneNumber || "Unknown"}</Text>
            </Stack>
            </Skeleton> */}
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Conversation Channel</Text>
              <Text>{_.capitalize(log.data.meta?.type || '')}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Started At</Text>
              <Text> {moment(typeof log.data.meta?.startedAt === "number" 
    ? new Date(log.data.meta.startedAt * 1000) 
    : new Date(log.data.meta?.startedAt))
    .format("DD MMM, hh:mm a")}</Text>
            </Stack></Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Ended At</Text>
              <Text>{moment.tz(log.data.meta?.endedAt || (moment(new Date(log.data.meta?.startedAt * 1000)).add(log.data.meta?.duration, "seconds").toLocaleString())).format("DD MMM, YYYY, hh:mm A")}</Text>
            </Stack>
            </Skeleton>
          </Stack>
        </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box mt={6}>
      <InteractionsTableProvider onCellClick={(call: any) => {
        dispatch(getSpecificLog(organization.data.id, call.id, call.agent_id))
        onOpen()
      }}>
        {/**
         * The `DateRangePicker` component is used to render the date range picker UI.
         * It is used to filter the tasks based on the selected date range it was created at.
         * The business logic for filtering the tasks based on the selected date range is handled inside the component.
         */}
        <React.Suspense
          fallback={
            <DataTableSkeleton
              columnCount={5}
              searchableColumnCount={1}
              filterableColumnCount={2}
              cellWidths={["10rem", "40rem", "12rem", "12rem", "8rem"]}
              shrinkZero
            />
          }
        >
          {/**
           * Passing promises and consuming them using React.use for triggering the suspense fallback.
           * @see https://react.dev/reference/react/use
           */}
          <InteractionsTable />
        </React.Suspense>
      </InteractionsTableProvider>
      </Box>
      </Page>
    )
}