import { RESET_STATE, SET_FORGOT_PASSWORD_ERROR, SET_FORGOT_PASSWORD_LOADING, SET_FORGOT_PASSWORD_SUCCESS, SET_LOGIN_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS, SET_RESET_PASSWORD_ERROR, SET_RESET_PASSWORD_LOADING, SET_RESET_PASSWORD_SUCCESS } from "./constants";

export function setLoginLoading () {
    return {
        type: SET_LOGIN_LOADING
    }
}

export function setLoginSuccess (payload: any) {
    return {
        type: SET_LOGIN_SUCCESS,
        payload
    }
}

export function setLoginError (error: string, details?: any) {
    return {
        type: SET_LOGIN_ERROR,
        error,
        details
    }
}

export function setForgotPasswordLoading () {
    return {
        type: SET_FORGOT_PASSWORD_LOADING
    }
}

export function setForgotPasswordSuccess (payload: any) {
    return {
        type: SET_FORGOT_PASSWORD_SUCCESS,
        payload
    }
}

export function setForgotPasswordError (error: string, details?: any) {
    return {
        type: SET_FORGOT_PASSWORD_ERROR,
        error,
        details
    }
}

export function setResetPasswordLoading () {
    return {
        type: SET_RESET_PASSWORD_LOADING
    }
}

export function setResetPasswordSuccess (payload: any) {
    return {
        type: SET_RESET_PASSWORD_SUCCESS,
        payload
    }
}

export function setResetPasswordError (error: string, details?: any) {
    return {
        type: SET_RESET_PASSWORD_ERROR,
        error,
        details
    }
}

export function resetState () {
    return {
        type: RESET_STATE
    }
}