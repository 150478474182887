"use client"

import * as React from "react"
import { type DataTableFilterField } from "~/types"

import { useDataTable } from "~/hooks/use-data-table"
import { DataTableAdvancedToolbar } from "~/components/data-table/advanced/data-table-advanced-toolbar"
import { DataTable } from "~/components/data-table/data-table"
import { DataTableToolbar } from "~/components/data-table/data-table-toolbar"

import { getColumns } from "./table-columns"
import { TasksTableFloatingBar } from "./table-floating-bar"
import { useInteractionsTable } from "./table-provider"
import { useAppDispatch, useAppSelector } from "~/lib/hooks"
import { getAllLogs } from "~/lib/app/logs/thunk"


export function InteractionsTable() {
  // Feature flags for showcasing some additional features. Feel free to remove them.
  const { featureFlags, onCellClick } = useInteractionsTable()
  const dispatch = useAppDispatch()
  const organization = useAppSelector(state => state.app.organizations.get)
  const assistants = useAppSelector((state) => state.app.platform.agents.list)

  const { data, metadata } = useAppSelector(state => state.app.logs.list)
  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });

  // Memoize the columns so they don't re-render on every render
  const columns = React.useMemo(() => getColumns(), [])

 
  const filterFields: DataTableFilterField<any>[] = [
    {
      label: "ID",
      value: "serial",
      placeholder: "Filter by customer id...",
    },
    {
      label: "Type",
      value: "type",
      options: [
        {
          "label": "Web Call",
          "value": "webCall"
        },
        {
          "label": "Outbound Call",
          "value": "outboundPhoneCall"
        },
        {
          "label": "Inbound Call",
          "value": "inboundPhoneCall"
        }
      ].map((status) => ({
        label: status.label,
        value: status.value,
        withCount: true,
      })),
    },
    {
      label: "Sentiment",
      value: "sentiment",
      options: [
        {
          "label": "Positive",
          "value": "positive"
        },
        {
          "label": "Neutral",
          "value": "neutral"
        },
        {
          "label": "Poor",
          "value": "poor"
        },
        {
          "label": "Unknown",
          "value": "unknown"
        }
      ].map((sentiment) => ({
        label: sentiment.label,
        value: sentiment.value,
        withCount: true,
      })),
    },
    {
      label: "Result",
      value: "result",
      options: [
        {
          "label": "Good Fit",
          "value": "Good Fit"
        },
        {
          "label": "Fit",
          "value": "Fit"
        },
        {
          "label": "Must Call",
          "value": "Must Call"
        },
        {
          "label": "Not Fit",
          "value": "Not Fit"
        }
      ].map((sentiment) => ({
        label: sentiment.label,
        value: sentiment.value,
        withCount: true,
      })),
    },
    {
      label: "Agent",
      value: "agent",
      options: assistants.data.map((agent: any) => ({
        label: agent.name,
        value: agent.id,
        withCount: true,
      })),
    }
  ]

  React.useEffect(() => {
    // if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id
      dispatch(getAllLogs(key))
    // }
  }, [organization])

  const { table, debouncedSetFilterParams } = useDataTable({
    data,
    columns,
    pageCount: metadata?.total_count || 0,
    filterFields,
    initialState: {
      sorting: [{ id: "createdAt", desc: true }],
      columnPinning: { right: ["actions"] },
      columnVisibility: {
        agent: false,
        serial: false
      }
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    shallow: false,
    clearOnDefault: true,
  })

  const Toolbar = DataTableToolbar

  return (
    <DataTable
      onCellClick={onCellClick}
      table={table}
      
    >
      <Toolbar setFilterParams={debouncedSetFilterParams} table={table} filterFields={filterFields}>
        {/* <TasksTableToolbarActions table={table} /> */}
      </Toolbar>
    </DataTable>
  )
}
