import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Input,
  Skeleton,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import Page from "../../../@components/Page";
import Chart from "react-apexcharts";
import _ from "lodash";
import { generateCanonicalNames } from "../../../utils/helper.utils";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { loadAnalytics } from "../../../lib/app/home/thunk";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import {
  BanIcon,
  CalendarIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsUpDownIcon,
  FrownIcon,
  MehIcon,
  MessagesSquareIcon,
  PhoneCallIcon,
  PlusIcon,
  SmileIcon,
  TrendingUp,
} from "lucide-react";
import ReactWordcloud from "react-wordcloud";
import { AsyncSelect, Select, chakraComponents } from "chakra-react-select";
import { loadAgents, searchAgents } from "../../../lib/app/platform/thunk";
import { useNavigate } from "react-router-dom";
import { getAgentAnalysis } from "../../../lib/app/assistants/thunk";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartStyle,
  ChartTooltip,
  ChartTooltipContent,
} from "~/components/ui/chart";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Pie,
  PieChart,
  Sector,
  XAxis,
} from "recharts";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
// @ts-ignore
import { Text as VisxText } from '@visx/text';
import { Wordcloud } from "@visx/wordcloud";
import { scaleLog } from '@visx/scale';
import { getAllLogs } from "~/lib/app/logs/thunk";

export interface WordData {
  text: string;
  value: number;
}

const fixedValueGenerator = () => 0.5;

const colors = ['#143059', '#2F6B9A', '#82a6c2']

function getRotationDegree () {
  const rand = Math.random();
  const degree = rand > 0.5 ? 60 : -60;
  return 0;
}

export default function Insights({
  id,
  agentId,
}: {
  id: string;
  agentId?: string;
}) {
  const analytics = useAppSelector((state) => state.app.home.analytics);
  const analysis = useAppSelector((state) => state.app.assistants.analysis);
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.app.organizations.get);
  const [range, setRange] = useState<moment.DurationInputArg2>("month");
  const [data, setData] = useState<
    {
      totalCalls?: number;
      connectedCalls?: number;
      successfulCalls?: number;
    }[]
  >([]);
  const [assistantId, setAssistantId] = useState(id);
  const [selectedAssistant, setSelectedAssistant] = useState<{
    label?: string;
    value?: string;
  }>();
  const logs = useAppSelector(state => state.app.logs.list)
  const [assistantOptions, setAssistantOptions] = useState<
    { label?: string; value?: string }[]
  >([]);
  const [assistantState, setAssistantState] = useState<string>("loading");
  const [reasonEnded, setReasonEnded] = useState<any[]>([]);
  const [callsData, setCallsData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [history, setHistory] = useState<any[]>([])
  const chartConfig = {
    name: {
      label: "Reason Ended",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig;
  const words = useMemo(() => 
    Object.keys(analysis.data.words || {}).slice(0, 100).map(v => ({
      text: v,
      value: analysis.data.words[v],
    })), [analysis.data.words]
  );

    const fontScale = useCallback(() => scaleLog()
  .domain([Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))])
  .range([10, 100]), [words]);
  const fontSizeSetter = useCallback((datum: WordData) => fontScale()(datum.value), [fontScale]);

  useEffect(() => {
    if (analytics.data?.length && id) {
      const _history = analytics.data[1]?.result?.filter(
        (v: any) => v.assistantId === id
      ) as any[]
      setHistory(_history)
      const _data = analytics.data[0]?.result?.filter(
        (v: any) => v.assistantId === id
      );
      const totalCalls = (_data || []).reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        },
        0
      );
      const callData = (_data || []).filter(
        (a: any) =>
          ![
            "customer-busy",
            "customer-did-not-answer",
            "customer-did-not-give-microphone-permission",
          ].includes(a.endedReason)
      );
      const connectedCalls = callData.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        },
        0
      );
      const successfulCalls = (_data || [])
        .filter((a: any) =>
          ["customer-ended-call", "assistant-ended-call"].includes(
            a.endedReason
          )
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        }, 0);
      setData([
        {
          totalCalls: totalCalls,
          successfulCalls: successfulCalls,
          connectedCalls: connectedCalls,
        },
      ]);
      const data = _.groupBy(_data, (v: any) => {
        return generateCanonicalNames(v.endedReason);
      });
      const _reson = Object.keys(data).map((v: any) => ({
        name: v,
        calls: data[v].reduce((accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        }, 0),
      }));
      setReasonEnded(_reson);
    }
  }, [analytics, id]);

  useEffect(() => {
    if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id;
      const start = moment().subtract(3, range).toDate().toISOString();
      const end = moment().toDate().toISOString();
      dispatch(
        loadAnalytics(key, [
          {
            name: "Number of Calls",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["assistantId", "endedReason"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Call History",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["assistantId"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
        ])
      );
    }
  }, [organization, range]);

  useEffect(() => {
    if (organization.data?.id && agentId) {
      dispatch(getAgentAnalysis(agentId));
      dispatch(getAllLogs(organization.data.id, agentId));
    }
  }, [organization, agentId]);

  useEffect(() => {
  })

  return (
    <Stack title="Analytics">
      {!assistantOptions.length && assistantState === "success" ? (
        <Center height={"calc(90vh - 84px)"}>
          <Stack align={"center"}>
            <MessagesSquareIcon size={30} />
            <Text fontWeight={600} fontSize={"lg"}>
              No agents found
            </Text>
            <Button
              boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
              _hover={{
                boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
              }}
              onClick={() => {
                navigate(`/app/${organization.data.id}/assistants?open=true`);
              }}
              size={"sm"}
              colorScheme="primary"
              leftIcon={<PlusIcon size={16} />}
            >
              Create your first assistant
            </Button>
          </Stack>
        </Center>
      ) : (
        <Box>
          <Flex mx={3}></Flex>
          <Stack>
            <Grid gap={4} gridTemplateColumns={"repeat(1, 1fr)"}>
              <Stack
                px={2}
                py={2}
                border={"1px solid #e4e4e4"}
                rounded={8}
                gap={0}
                mt={4}
                w={"-webkit-fill-available"}
              >
                <Flex
                  gap={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Text fontWeight={"semibold"}>Call Statistics</Text>
                    <Text className="text-muted-foreground" fontSize={"xs"}>
                      Quick overview of how your calls are going within your
                      agent.
                    </Text>
                  </Box>
                  <Button
                    leftIcon={<CalendarIcon size={16} />}
                    variant={"outline"}
                    size={"sm"}
                  >
                    Last 90 days
                  </Button>
                </Flex>
                {/* <Divider /> */}
                <Flex alignItems={"start"} gap={2}>
                  <Text fontWeight={"semibold"} fontSize={54}>
                    {logs.metadata?.total_count}{" "}
                    <Text as={"span"} fontSize={"xl"}>
                      Calls
                    </Text>
                  </Text>
                </Flex>
               {analysis.state === "success" && analysis.data.history?.length ? <ChartContainer
                className="min-h-[250px] max-w-[70vw]"
                  config={
                    {
                      calls: {
                        label: "No of Calls",
                        color: "hsl(var(--chart-1))",
                      }
                    } satisfies ChartConfig
                  }
                >
                  <BarChart
                    accessibilityLayer
                    data={(analysis.data.history || []).toSorted((a: any, b:any) => new Date(a.key_name).getTime() - new Date(b.key_name).getTime()).map((h: any) => (
                      {
                        date: moment.parseZone(h.key_name).format("DD MMM"),
                        calls: Number.parseInt(h.docs_count, 10)
                      }
                    ))}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                      tickFormatter={(value) => value}
                    />
                    <ChartTooltip content={<ChartTooltipContent hideLabel />} />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar
                      dataKey="calls"
                      fill="var(--color-calls)"
                      radius={[0, 0, 4, 4]}
                    />
                  </BarChart>
                </ChartContainer> : analysis.state === "success" && !analysis.data.history?.length ? <Center h={360}>
                    <Box textAlign={"center"}>
                    <Text>No data found</Text>
                    <Text fontSize={'sm'} className="text-muted-foreground">Interact with your agent and visit back for metrics</Text>
                    </Box>
                </Center> : <Center h={360}>
                    <Skeleton h={240} w={"full"} />
                </Center>}
                {/* <CardFooter className="flex-col items-start gap-2 text-sm">
                  <div className="flex gap-2 font-medium leading-none">
                    Trending up by 5.2% this month{" "}
                    <TrendingUp className="size-4" />
                  </div>
                  <div className="text-muted-foreground leading-none">
                    Showing total visitors for the last 6 months
                  </div>
                </CardFooter> */}
              </Stack>
            </Grid>
            <Grid gap={4} gridTemplateColumns={"repeat(2, 1fr)"}>
              <Stack
                px={2}
                py={2}
                border={"1px solid #e4e4e4"}
                rounded={8}
                gap={0}
                mt={4}
                w={"-webkit-fill-available"}
              >
                <Flex
                  gap={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Text fontWeight={"semibold"}>Sentiment Analysis</Text>
                    <Text className="text-muted-foreground" fontSize={"xs"}>
                      Quick overview of how are your call sentiments
                    </Text>
                  </Box>
                  <Button
                    leftIcon={<CalendarIcon size={16} />}
                    variant={"outline"}
                    size={"sm"}
                  >
                    Last 90 days
                  </Button>
                </Flex>
                {/* <Divider /> */}
                <Grid
                  mt={3}
                  gap={4}
                  justifyContent={"space-between"}
                  gridTemplateColumns={"repeat(2, 1fr)"}
                >
                  <Flex gap={2} alignItems={"start"}>
                    <Center
                      boxSize={8}
                      className="bg-slate-100"
                      rounded={8}
                      pos={"relative"}
                    >
                      <SmileIcon size={16} />
                    </Center>
                    <Box>
                      <Text whiteSpace={"nowrap"} color={"gray"} fontSize={"xs"}>
                        Positive calls
                      </Text>
                      <Text mt={-1} fontWeight={"bold"} fontSize={"lg"}>
                        {analysis.data.sentiment?.positive || 0}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex gap={2} alignItems={"start"}>
                    <Center
                      boxSize={8}
                      className="bg-slate-100"
                      rounded={8}
                      pos={"relative"}
                    >
                      <MehIcon size={16} />
                    </Center>
                    <Box>
                      <Text whiteSpace={"nowrap"} color={"gray"} fontSize={"xs"}>
                        Neutral calls
                      </Text>
                      <Text mt={-1} fontWeight={"bold"} fontSize={"lg"}>
                        {analysis.data.sentiment?.neutral || 0}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex gap={2} alignItems={"start"}>
                    <Center
                      boxSize={8}
                      className="bg-slate-100"
                      rounded={8}
                      pos={"relative"}
                    >
                      <FrownIcon size={16} />
                    </Center>
                    <Box>
                      <Text whiteSpace={"nowrap"} color={"gray"} fontSize={"xs"}>
                        Negative calls
                      </Text>
                      <Text mt={-1} fontWeight={"bold"} fontSize={"lg"}>
                        {analysis.data.sentiment?.negative || 0}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex gap={2} alignItems={"start"}>
                    <Center
                      boxSize={8}
                      className="bg-slate-100"
                      rounded={8}
                      pos={"relative"}
                    >
                      <FrownIcon size={16} />
                    </Center>
                    <Box>
                      <Text whiteSpace={"nowrap"} color={"gray"} fontSize={"xs"}>
                        Unknown calls
                      </Text>
                      <Text mt={-1} fontWeight={"bold"} fontSize={"lg"}>
                        {analysis.data.sentiment?.unknown || 0}
                      </Text>
                    </Box>
                  </Flex>
                </Grid>
                <Divider my={3} />
                <Stack>
                <ChartContainer
                style={{
                  minHeight: 360
                }}
               config={{
                positive: {
                  label: 'Positive',
                  color: 'hsl(var(--chart-1))'
               },
               neutral: {
                  label: 'Neutral',
                  color: 'hsl(var(--chart-2))',
               },
               negative: {
                  label: 'Negative',
                  color: 'hsl(var(--chart-3))',
               },
               unknown: {
                  label: 'Unknown',
                  color: 'hsl(var(--chart-4))',
               },
               }}
               className="mx-auto aspect-square max-h-[250px]"
            >
               <PieChart>
                  <ChartTooltip
                     cursor={false}
                     content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                     data={[
                        {
                          "sentiment": "positive",
                          "calls": analysis.data.sentiment?.positive,
                          fill: 'hsl(var(--chart-10))'
                        },
                        {
                          "sentiment": "neutral",
                          "calls": analysis.data.sentiment?.neutral,
                          fill: 'hsl(var(--chart-6))'
                        },
                        {
                          "sentiment": "negative",
                          "calls": analysis.data.sentiment?.negative,
                          fill: 'hsl(var(--chart-7))'
                        },
                        {
                          "sentiment": "unknown",
                          "calls": analysis.data.sentiment?.unknown,
                          fill: 'hsl(var(--chart-9))'
                        }
                      ]}
                     dataKey="calls"
                     nameKey="sentiment"
                     innerRadius={60}
                     strokeWidth={5}
                  >
                     <Label
                        content={({ viewBox }) => {
                           if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                              return (
                                 <text
                                    x={viewBox.cx}
                                    y={viewBox.cy}
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                 >
                                    <tspan
                                       x={viewBox.cx}
                                       y={viewBox.cy}
                                       className="fill-foreground text-3xl font-bold"
                                    >
                                       {(
                          (((analysis.data.sentiment?.neutral || 0) +
                            (analysis.data.sentiment?.positive || 0)) /
                            (analysis.data.sentiment?.total || 1)) *
                          100
                        ).toFixed(0)}{" "} % 
                                    </tspan>
                                    <tspan
                                       x={viewBox.cx}
                                       y={(viewBox.cy || 0) + 24}
                                       className="fill-muted-foreground"
                                    >
                                       success rate
                                    </tspan>
                                 </text>
                              )
                           }
                        }}
                     />
                  </Pie>
               </PieChart>
            </ChartContainer>
                </Stack>
              </Stack>
              <Stack p={2} rounded={8} border={"1px solid #e4e4e4"} mt={4}>
                <Box mb={3}>
                  <Text fontWeight={700}>Top questions</Text>
                  <Text color={"gray"} fontWeight={600} fontSize={12}>
                    Most frequent questions asked by user
                  </Text>
                </Box>
                <Table className="rounded-sm border-separate border-spacing-0">
                  <TableHeader className="!bg-slate-50">
                    <TableRow>
                      <TableHead className="font-semibold">Question</TableHead>
                      <TableHead className="font-semibold">Count</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {Object.keys(analysis.data.questions || {}).map((q) => (
                      <TableRow>
                        <TableCell className="font-medium border-b">
                          {_.capitalize(q)}
                        </TableCell>
                        <TableCell className="border-b">
                          {analysis.data.questions[q]}
                        </TableCell>
                      </TableRow>
                    ))}
                    {analysis.state === "success" && !Object.keys(analysis.data.questions || []).length ? <TableRow>
                        <TableCell colSpan={2} className="font-medium text-center" style={{
                          height: 120
                        }}>
                          No data yet
                        </TableCell>
                      </TableRow> : <></>}
                  </TableBody>
                </Table>
              </Stack>
              <Stack></Stack>
            </Grid>
            {Object.keys(analysis.data.words || {}).length ? ( <Stack
              flex={0.4}
              p={2}
              rounded={8}
              border={"1px solid #e4e4e4"}
              mt={4}
            >
              <Box mb={3}>
                <Text fontWeight={700}>Top keywords</Text>
              </Box>
              <Divider />
              

<Box  w={600} h={400} mx={"auto"}>
<Wordcloud
        words={words}
        width={600}
        rotate={(datum) => 0}
        height={400}
        font={'Circular'}
        // @ts-ignore
        fontSize={fontSizeSetter}
        padding={2}
        spiral={"archimedean"}
        random={fixedValueGenerator}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <VisxText
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size! - 10}
              fontFamily={w.font}
            >
              {w.text}
            </VisxText>
          ))
        }
      </Wordcloud>
                  </Box>              
            </Stack>) : (
                  <></>
            )}
          </Stack>
        </Box>
      )}
    </Stack>
  );
}
