import {
    SET_CREATE_CONTACTS_LOADING,
    SET_CREATE_CONTACTS_SUCCESS,
    SET_CREATE_CONTACTS_ERROR,
    SET_UPDATE_CONTACTS_LOADING,
    SET_UPDATE_CONTACTS_SUCCESS,
    SET_UPDATE_CONTACTS_ERROR,
    SET_DELETE_CONTACTS_LOADING,
    SET_DELETE_CONTACTS_SUCCESS,
    SET_DELETE_CONTACTS_ERROR,
    SET_GET_CONTACTS_LOADING,
    SET_GET_CONTACTS_SUCCESS,
    SET_GET_CONTACTS_ERROR,
    SET_LIST_CONTACTS_LOADING,
    SET_LIST_CONTACTS_SUCCESS,
    SET_LIST_CONTACTS_ERROR,
    SET_CONTACT_INTERACTIONS_LOADING,
    SET_CONTACT_INTERACTIONS_SUCCESS,
    SET_CONTACT_INTERACTIONS_ERROR
} from './constants';

// Action Creators for contacts
export const setCreateContactsLoading = () => ({
    type: SET_CREATE_CONTACTS_LOADING,
});

export const setCreateContactsSuccess = (contact: any) => ({
    type: SET_CREATE_CONTACTS_SUCCESS,
    payload: contact,
});

export const setCreateContactsError = (error: string) => ({
    type: SET_CREATE_CONTACTS_ERROR,
    payload: error,
});

export const setUpdateContactsLoading = () => ({
    type: SET_UPDATE_CONTACTS_LOADING,
});

export const setUpdateContactsSuccess = (contact: any) => ({
    type: SET_UPDATE_CONTACTS_SUCCESS,
    payload: contact,
});

export const setUpdateContactsError = (error: string) => ({
    type: SET_UPDATE_CONTACTS_ERROR,
    payload: error,
});

export const setDeleteContactsLoading = () => ({
    type: SET_DELETE_CONTACTS_LOADING,
});

export const setDeleteContactsSuccess = (contactId: string) => ({
    type: SET_DELETE_CONTACTS_SUCCESS,
    payload: contactId,
});

export const setDeleteContactsError = (error: string) => ({
    type: SET_DELETE_CONTACTS_ERROR,
    payload: error,
});

export const setGetContactsLoading = () => ({
    type: SET_GET_CONTACTS_LOADING,
});

export const setGetContactsSuccess = (contact: any) => ({
    type: SET_GET_CONTACTS_SUCCESS,
    payload: contact,
});

export const setGetContactsError = (error: string) => ({
    type: SET_GET_CONTACTS_ERROR,
    payload: error,
});

export const setListContactsLoading = () => ({
    type: SET_LIST_CONTACTS_LOADING,
});

export const setListContactsSuccess = (contacts: any[], metadata: any) => ({
    type: SET_LIST_CONTACTS_SUCCESS,
    payload: contacts,
    metadata
});

export const setListContactsError = (error: string) => ({
    type: SET_LIST_CONTACTS_ERROR,
    payload: error,
});

export const setContactInteractionsLoading = () => ({
    type: SET_CONTACT_INTERACTIONS_LOADING
})

export const setContactInteractionsSuccess = (payload: any[], metadata: any) => ({
    type: SET_CONTACT_INTERACTIONS_SUCCESS,
    payload,
    metadata
})

export const setContactInteractionsError = (error: string) => ({
    type: SET_CONTACT_INTERACTIONS_ERROR,
    error
})
