"use client"

import * as React from "react"
import type { DataTableFilterField } from "~/types"
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnFiltersState,
  type PaginationState,
  type RowSelectionState,
  type SortingState,
  type TableOptions,
  type TableState,
  type Updater,
  type VisibilityState,
} from "@tanstack/react-table"

import { useDebouncedCallback } from "~/hooks/use-debounced-callback"
import { useAppDispatch, useAppSelector } from "~/lib/hooks"
import { getAllLogs } from "~/lib/app/logs/thunk"

interface UseDataTableProps<TData>
  extends Omit<
      TableOptions<TData>,
      | "state"
      | "pageCount"
      | "getCoreRowModel"
      | "manualFiltering"
      | "manualPagination"
      | "manualSorting"
    >,
    Required<Pick<TableOptions<TData>, "pageCount">> {
  /**
   * Defines filter fields for the table. Supports both dynamic faceted filters and search filters.
   * - Faceted filters are rendered when `options` are provided for a filter field.
   * - Otherwise, search filters are rendered.
   *
   * The indie filter field `value` represents the corresponding column name in the database table.
   * @default []
   * @type { label: string, value: keyof TData, placeholder?: string, options?: { label: string, value: string, icon?: React.ComponentType<{ className?: string }> }[] }[]
   * @example
   * ```ts
   * // Render a search filter
   * const filterFields = [
   *   { label: "Title", value: "title", placeholder: "Search titles" }
   * ];
   * // Render a faceted filter
   * const filterFields = [
   *   {
   *     label: "Status",
   *     value: "status",
   *     options: [
   *       { label: "Todo", value: "todo" },
   *       { label: "In Progress", value: "in-progress" },
   *     ]
   *   }
   * ];
   * ```
   */
  filterFields?: DataTableFilterField<TData>[]

  /**
   * Determines how query updates affect history.
   * `push` creates a new history entry; `replace` (default) updates the current entry.
   * @default "replace"
   */
  history?: "push" | "replace"

  /**
   * Indicates whether the page should scroll to the top when the URL changes.
   * @default false
   */
  scroll?: boolean

  /**
   * Shallow mode keeps query states client-side, avoiding server calls.
   * Setting to `false` triggers a network request with the updated querystring.
   * @default true
   */
  shallow?: boolean

  /**
   * Maximum time (ms) to wait between URL query string updates.
   * Helps with browser rate-limiting. Minimum effective value is 50ms.
   * @default 50
   */
  throttleMs?: number

  /**
   * Debounce time (ms) for filter updates to enhance performance during rapid input.
   * @default 300
   */
  debounceMs?: number

  /**
   * Observe Server Component loading states for non-shallow updates.
   * Pass `startTransition` from `React.useTransition()`.
   * Sets `shallow` to `false` automatically.
   * So shallow: true` and `startTransition` cannot be used at the same time.
   * @see https://react.dev/reference/react/useTransition
   */
  startTransition?: React.TransitionStartFunction

  /**
   * Clear URL query key-value pair when state is set to default.
   * Keep URL meaning consistent when defaults change.
   * @default false
   */
  clearOnDefault?: boolean

  /**
   * Enable notion like column filters.
   * Advanced filters and column filters cannot be used at the same time.
   * @default false
   * @type boolean
   */
  enableAdvancedFilter?: boolean

  initialState?: Omit<Partial<TableState>, "sorting"> & {
    // Extend to make the sorting id typesafe
    sorting?: {
      id: Extract<keyof TData, string>
      desc: boolean
    }[]
  }
}

export function useDataTable<TData>({
  pageCount = -1,
  filterFields = [],
  enableAdvancedFilter = false,
  history = "replace",
  scroll = false,
  shallow = true,
  throttleMs = 50,
  debounceMs = 300,
  clearOnDefault = false,
  startTransition,
  initialState,
  ...props
}: UseDataTableProps<TData>) {
  // const queryStateOptions = React.useMemo<
  //   Omit<UseQueryStateOptions<string>, "parse">
  // >(() => {
  //   return {
  //     history,
  //     scroll,
  //     shallow,
  //     throttleMs,
  //     debounceMs,
  //     clearOnDefault,
  //     startTransition,
  //   }
  // }, [
  //   history,
  //   scroll,
  //   shallow,
  //   throttleMs,
  //   debounceMs,
  //   clearOnDefault,
  //   startTransition,
  // ])

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    initialState?.rowSelection ?? {}
  )
  const dispatch = useAppDispatch()
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(initialState?.columnVisibility ?? {})
  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize: 25, //default page size
  });

  // const [page, setPage] = useQueryState(
  //   "page",
  //   parseAsInteger.withOptions(queryStateOptions).withDefault(1)
  // )
  // const [perPage, setPerPage] = useQueryState(
  //   "per_page",
  //   parseAsInteger
  //     .withOptions(queryStateOptions)
  //     .withDefault(initialState?.pagination?.pageSize ?? 10)
  // )
  // const [sort, setSort] = useQueryState(
  //   "sort",
  //   parseAsString
  //     .withOptions(queryStateOptions)
  //     .withDefault(
  //       `${initialState?.sorting?.[0]?.id}.${initialState?.sorting?.[0]?.desc ? "desc" : "asc"}`
  //     )
  // )
  // const [column, order] = sort?.split(".") ?? []

  // // Create parsers for each filter field
  // const filterParsers = React.useMemo(() => {
  //   return filterFields.reduce<
  //     Record<string, Parser<string> | Parser<string[]>>
  //   >((acc, field) => {
  //     if (field.options) {
  //       // Faceted filter
  //       acc[field.value as string] = parseAsArrayOf(
  //         parseAsString,
  //         "."
  //       ).withOptions(queryStateOptions)
  //     } else {
  //       // Search filter
  //       acc[field.value as string] =
  //         parseAsString.withOptions(queryStateOptions)
  //     }
  //     return acc
  //   }, {})
  // }, [filterFields, queryStateOptions])

  const [filterValues, setFilterValues] = React.useState<any>({})
  const organization = useAppSelector(state => state.app.organizations.get)

  const debouncedSetFilterValues = useDebouncedCallback(
    (values: any) => {
      setFilterValues(values)
    },
    debounceMs
  )

  const debouncedSetFilterParams = React.useCallback(
    (values: any) => {
      setFilterValues({
        ...filterValues,
        ...values
      })
    },
    [filterValues]
  )
  
  React.useEffect(() => {
    const pagination = table.getState().pagination
    const filters: {[key: string]: string} = {}
    let fuzzy;
    for (const key of Object.keys(filterValues)) {
      if (key === "sentiment") {
        filters["insights.extraction->call_sentiment[eq]"] = filterValues[key]
      }
      if (key === "type") {
        filters["meta->type[eq]"] = filterValues[key]
      }
      if (key === "agent") {
        filters["agent_id[eq]"] = filterValues[key]
      }
      if (key === "result") {
        filters["insights.extraction->result[eq]"] = filterValues[key]
      }
      if (key === "serial") {
        fuzzy = `meta->customer->number=${filterValues[key]}`
      }
      if (key.includes("created_at") && filterValues[key]) {
        filters[key] = filterValues[key]
      }
    }
    dispatch(getAllLogs(organization.data.id, undefined, pagination.pageIndex + 1, pagination.pageSize, filters, fuzzy))

  }, [filterValues, pagination])

  // Paginate
  // const pagination: PaginationState = {
  //   pageIndex: page - 1, // zero-based index -> one-based index
  //   pageSize: perPage,
  // }

  // function onPaginationChange(updaterOrValue: Updater<PaginationState>) {
  //   if (typeof updaterOrValue === "function") {
  //     const newPagination = updaterOrValue(pagination)
  //     void setPage(newPagination.pageIndex + 1)
  //     void setPerPage(newPagination.pageSize)
  //   } else {
  //     void setPage(updaterOrValue.pageIndex + 1)
  //     void setPerPage(updaterOrValue.pageSize)
  //   }
  // }

  // Sort
  // const sorting: SortingState = [{ id: column ?? "", desc: order === "desc" }]

  // function onSortingChange(updaterOrValue: Updater<SortingState>) {
  //   if (typeof updaterOrValue === "function") {
  //     const newSorting = updaterOrValue(sorting)
  //     void setSort(
  //       `${newSorting[0]?.id}.${newSorting[0]?.desc ? "desc" : "asc"}`
  //     )
  //   }
  // }

  // Filter
  const initialColumnFilters: ColumnFiltersState = React.useMemo(() => {
    return enableAdvancedFilter
      ? []
      : Object.entries(filterValues).reduce<ColumnFiltersState>(
          (filters, [key, value]) => {
            if (value !== null) {
              filters.push({
                id: key,
                value: Array.isArray(value) ? value : [value],
              })
            }
            return filters
          },
          []
        )
  }, [filterValues, enableAdvancedFilter])

  const [columnFilters, setColumnFilters] =
    React.useState<ColumnFiltersState>(initialColumnFilters)

  // Memoize computation of searchableColumns and filterableColumns
  const { searchableColumns, filterableColumns } = React.useMemo(() => {
    return enableAdvancedFilter
      ? { searchableColumns: [], filterableColumns: [] }
      : {
          searchableColumns: filterFields.filter((field) => !field.options),
          filterableColumns: filterFields.filter((field) => field.options),
        }
  }, [filterFields, enableAdvancedFilter])

  const onColumnFiltersChange = React.useCallback(
    (updateOrValue: Updater<ColumnFiltersState>) => {
      if (enableAdvancedFilter) return // Don't process filters if advanced filtering is enabled

      setColumnFilters((prev) => {
        const next =
          typeof updateOrValue === "function"
            ? updateOrValue(prev)
            : updateOrValue

        const filterUpdates = next.reduce<
          Record<string, string | string[] | null>
        >((acc, filter) => {
          if (searchableColumns.find((col) => col.value === filter.id)) {
            // For search filters, use the value directly
            acc[filter.id] = filter.value as string
          } else if (filterableColumns.find((col) => col.value === filter.id)) {
            // For faceted filters, use the array of values
            acc[filter.id] = filter.value as string[]
          }
          return acc
        }, {})

        // Handle filter removal
        prev.forEach((prevFilter) => {
          if (!next.some((filter) => filter.id === prevFilter.id)) {
            filterUpdates[prevFilter.id] = null
          }
        })
        debouncedSetFilterValues(filterUpdates)
        return next
      })
    },
    [
      debouncedSetFilterValues,
      filterableColumns,
      searchableColumns,
      enableAdvancedFilter,
    ]
  )

  const table = useReactTable({
    ...props,
    initialState,
    pageCount,
    state: {
      columnVisibility,
      rowSelection,
      columnFilters: enableAdvancedFilter ? [] : columnFilters,
      pagination
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    // onSortingChange,
    onColumnFiltersChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: enableAdvancedFilter
      ? undefined
      : getFilteredRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: enableAdvancedFilter ? undefined : getFacetedRowModel(),
    getFacetedUniqueValues: enableAdvancedFilter
      ? undefined
      : getFacetedUniqueValues(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  })

  return { table, debouncedSetFilterParams }
}
