import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Input,
  Skeleton,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import Chart from "react-apexcharts";
import {
  ArrowDownRightIcon,
  ArrowUpDownIcon,
  ArrowUpRightIcon,
  Building2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import moment from "moment-timezone";
import { loadAnalytics, loadMetrics } from "../../lib/app/home/thunk";
import _ from "lodash";
import { generateCanonicalNames } from "../../utils/helper.utils";

export default function Home() {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector((state) => state.app.home.metrics);
  const analytics = useAppSelector((state) => state.app.home.analytics);
  const organization = useAppSelector((state) => state.app.organizations.get);
  const [range, setRange] = useState<moment.DurationInputArg2>("month");
  const [connectedCalls, setConnectedCalls] = useState(0)

  useEffect(() => {
    if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id;
      const start = moment().subtract(3, range).toDate().toISOString();
      const end = moment().toDate().toISOString();
      dispatch(
        loadAnalytics(key, [
          {
            name: "LLM, STT, TTS, VAPI Costs",
            table: "call",
            operations: [
              {
                operation: "sum",
                column: "costBreakdown.llm",
              },
              {
                operation: "sum",
                column: "costBreakdown.stt",
              },
              {
                operation: "sum",
                column: "costBreakdown.tts",
              },
              {
                operation: "sum",
                column: "costBreakdown.vapi",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Total Conversation Duration",
            table: "call",
            operations: [
              {
                operation: "sum",
                column: "duration",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Average Conversation Cost",
            table: "call",
            operations: [
              {
                operation: "avg",
                column: "cost",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Number of Conversations by Type",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["type"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Number of Failed Conversations",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["endedReason"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Number of Conversations by Assistant",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["assistantId"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Average Conversation Duration",
            table: "call",
            operations: [
              {
                operation: "avg",
                column: "duration",
              },
            ],
            groupBy: ["assistantId"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Total Minutes",
            table: "call",
            operations: [
              {
                operation: "sum",
                column: "duration",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Total Spent",
            table: "call",
            operations: [
              {
                operation: "sum",
                column: "cost",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Total Conversations",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
          {
            name: "Number of Calls",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["assistantId", "endedReason"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          },
        ])
      );
    }
  }, [organization, range]);

  useEffect(() => {
    if (analytics.data[10]) {
      const _data = analytics.data[10]?.result
      const connectedCalls = (_data || []).filter((a: any) =>
        [
          "customer-ended-call",
          "assistant-ended-call",
          "assistant-forwarded-call",
        ].includes(a.endedReason)
      )
      .reduce((accumulator: any, currentValue: any) => {
        return accumulator + Number.parseInt(currentValue.countId, 10);
      }, 0);
      setConnectedCalls(connectedCalls)
    };
    
  }, [ analytics ])

  return (
    <Page title="Insights">
      {!organization.data.providers?.vapi?.api_key ? (
        <Center h={"80vh"}>
          <Stack align={"center"}>
            <Building2Icon size={30} />
            <Text fontWeight={600} fontSize={"lg"}>
              We are setting up your organisation
            </Text>
            <Text>Kindly re-visit after 24 hours</Text>
          </Stack>
        </Center>
      ) : (
        <>
          <Grid mt={6} mx={4} gap={4} gridTemplateColumns={`repeat(5, 1fr)`}>
            <Stack
              h={180}
              overflow={"hidden"}
              cursor={"pointer"}
              rounded={8}
              gap={0}
              border={"1px solid #cacaca"}
            >
              <Skeleton isLoaded={metrics.state === "success"}>
                <Stack px={2} py={2}>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>Dialed Calls</Text>
                  <Text mt={-2} fontSize={48} fontWeight={"bold"}>
                    {metrics.data?.callCount || "0"}
                  </Text>
                </Stack>
                <Box mt={-8}>
                  <Chart
                    height={100}
                    type="area"
                    options={{
                      chart: {
                        height: 50,
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: 2,
                        curve: "straight",
                      },
                      xaxis: {
                        type: "datetime",
                        categories: (analytics.data?.[9]?.result || []).map(
                          (m: any) => m.date
                        ),
                      },
                      tooltip: {
                        x: {
                          format: "dd/MM/yy hh:mm",
                        },
                      },
                    }}
                    series={[
                      {
                        name: analytics.data?.[9]?.name,
                        data: (analytics.data?.[9]?.result || []).map(
                          (m: any) => m.countId
                        ),
                      },
                    ]}
                  />
                </Box>
              </Skeleton>
            </Stack>
            <Stack
              hidden
              h={180}
              cursor={"pointer"}
              rounded={8}
              gap={0}
              border={"1px solid #cacaca"}
              overflow={"hidden"}
            >
              <Skeleton isLoaded={metrics.state === "success"}>
                <Stack pos={"relative"} zIndex={2} px={2} py={2}>
                  <Text fontWeight={600} fontSize={"sm"}>Total Spent</Text>
                  <Text mt={-2} fontSize={48} fontWeight={"bold"}>
                    ${Number(metrics.data?.bill || 0).toFixed(4)}
                  </Text>
                  {/* <Flex color={"#38A169"} alignItems={"center"}>
              <ArrowUpRightIcon size={16} />
              6.10%
            </Flex> */}
                </Stack>
                <Box pos={"relative"} zIndex={-1} mt={-8}>
                  <Chart
                    height={100}
                    options={{
                      chart: {
                        height: 50,
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: 2,
                        curve: "straight",
                      },
                      xaxis: {
                        type: "datetime",
                        categories: (analytics.data?.[8]?.result || []).map(
                          (m: any) => m.date
                        ),
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: (analytics.data?.[8]?.result || []).map(
                          (m: any) => m.date
                        ),
                        data: (analytics.data?.[8]?.result || []).map(
                          (m: any) => m.sumCost
                        ),
                      },
                    ]}
                    type="area"
                  />
                </Box>
              </Skeleton>
            </Stack>
            <Stack
              cursor={"pointer"}
              rounded={8}
              gap={0}
              border={"1px solid #cacaca"}
              h={180}
              overflow={"hidden"}
            >
              <Skeleton isLoaded={metrics.state === "success"}>
                <Stack px={2} py={2}>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>Total conversation mins</Text>
                  <Text mt={-2} fontSize={48} fontWeight={"bold"}>
                    {Number(metrics.data?.callMinutes || 0).toFixed(2)}
                    <Text fontSize={"md"} as={"span"}>
                      mins
                    </Text>
                  </Text>
                  {/* <Flex color={"#E53E3E"} alignItems={"center"}>
              <ArrowDownRightIcon size={16} />
              10.9%
            </Flex> */}
                </Stack>
                <Box mt={-8}>
                  <Chart
                    height={100}
                    options={{
                      chart: {
                        height: 50,
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: 2,
                        curve: "straight",
                      },
                      xaxis: {
                        type: "datetime",
                        categories: (analytics.data?.[7]?.result || []).map(
                          (m: any) => m.date
                        ),
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: analytics.data?.[7]?.name,
                        data: (analytics.data?.[7]?.result || []).map(
                          (m: any) => m.sumDuration
                        ),
                      },
                    ]}
                    type="area"
                  />
                </Box>
              </Skeleton>
            </Stack>
            <Stack
              cursor={"pointer"}
              rounded={8}
              gap={0}
              border={"1px solid #cacaca"}
              h={180}
              overflow={"hidden"}
            >
              <Skeleton isLoaded={analytics.state === "success"}>
                <Stack px={2} py={2}>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>Total connected calls</Text>
                  <Text mt={-2} fontSize={48} fontWeight={"bold"}>
                    {connectedCalls}
                  </Text>
                  {/* <Flex color={"#E53E3E"} alignItems={"center"}>
              <ArrowDownRightIcon size={16} />
              10.9%
            </Flex> */}
                </Stack>
                {/* <Box mt={-8}>
                  <Chart
                    height={100}
                    options={{
                      chart: {
                        height: 50,
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: 2,
                        curve: "straight",
                      },
                      xaxis: {
                        type: "datetime",
                        categories: (analytics.data?.[7]?.result || []).map(
                          (m: any) => m.date
                        ),
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: analytics.data?.[7]?.name,
                        data: (analytics.data?.[7]?.result || []).map(
                          (m: any) => m.sumDuration
                        ),
                      },
                    ]}
                    type="area"
                  />
                </Box> */}
              </Skeleton>
            </Stack>
            <Stack
              cursor={"pointer"}
              rounded={8}
              gap={0}
              hidden
              border={"1px solid #cacaca"}
              h={180}
              overflow={"hidden"}
            >
              <Skeleton isLoaded={metrics.state === "success"}>
                <Stack px={2} py={2}>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>Average cost per call</Text>
                  <Text mt={-2} fontSize={48} fontWeight={"bold"}>
                    $
                    {Number(
                      metrics.data?.bill / metrics.data?.callCount
                    ).toFixed(2)}
                  </Text>
                  {/* <Flex color={"#E53E3E"} alignItems={"center"}>
              <ArrowDownRightIcon size={16} />
              10.9%
            </Flex> */}
                </Stack>
                <Box mt={-8}>
                  <Chart
                    height={100}
                    options={{
                      chart: {
                        height: 50,
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: 2,
                        curve: "straight",
                      },
                      xaxis: {
                        type: "datetime",
                        categories: (analytics.data?.[2]?.result || []).map(
                          (m: any) => m.date
                        ),
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: analytics.data?.[2]?.name,
                        data: (analytics.data?.[2]?.result || []).map(
                          (m: any) => m.avgCost
                        ),
                      },
                    ]}
                    type="area"
                  />
                </Box>
              </Skeleton>
            </Stack>
          </Grid>
          <Flex gap={3} justifyContent={"space-between"} w={"97%"}>
            <Stack
              px={2}
              py={2}
              pb={8}
              border={"1px solid #e4e4e4"}
              rounded={8}
              mt={4}
              flex={0.6}
              ml={4}
              w={"-webkit-fill-available"}
            >
              <Box mb={3}>
                <Text fontWeight={700}>Conversation Analysis</Text>
                <Text color={"gray"}  fontWeight={600} fontSize={12}>
                  Here you can get a quick overview of how your conversationc are going
                  within your organization.
                </Text>
              </Box>
              <Divider />
              <Grid gap={4} gridTemplateColumns={`repeat(1, 1fr)`}>
                <Skeleton isLoaded={analytics.state === "success"}>
                  <Stack cursor={"pointer"} rounded={8} px={2} py={2}>
                    <Box hidden>
                      <Text fontWeight={"bold"}>Reason Call Ended</Text>
                      <Text color={"gray"} fontWeight={"semibold"} fontSize={12}>
                        Calls aggregated by reason of why the call ended or
                        completed.
                      </Text>
                    </Box>
                    {analytics.data?.[4]?.result?.length ? <Chart
                      height={375}
                      options={{
                        chart: {
                          type: "donut",
                          width: "200%",
                        },
                        colors: ["#8884d8aa", "#82ca9daa", "#ffc658aa", "#ff8042aa"],
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          lineCap: "round",
                        },
                        xaxis: {
                          type: "datetime",
                        },
                        legend: {
                          position: "bottom",
                        },
                        tooltip: {
                          enabled: true,
                        },
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "45%",
                            },
                          },
                        },
                        labels: Object.keys(
                          _.groupBy(
                            analytics.data?.[4]?.result || [],
                            (v: any) => {
                              return generateCanonicalNames(v.endedReason);
                            }
                          )
                        ) as unknown as string[],
                      }}
                      series={
                        Object.values(
                          _.groupBy(
                            analytics.data?.[4]?.result || [],
                            (v: any) => {
                              return generateCanonicalNames(v.endedReason);
                            }
                          )
                        ).map((e) => e.length) as unknown as number[]
                      }
                      type="donut"
                    /> : <Center h={375}>
                        <Stack alignItems={"center"}>
                          <ArrowUpDownIcon size={20} />
                          <Text fontWeight={600}>No data found</Text>
                        </Stack>
                    </Center>}
                  </Stack>
                </Skeleton>
                <Divider hidden />
                <Skeleton hidden isLoaded={analytics.state === "success"}>
                  <Stat cursor={"pointer"}>
                    <Box>
                      <Text fontWeight={"bold"}>Cost Breakdown</Text>
                      <Text fontWeight={"semibold"} color={"gray"} fontSize={12}>
                        Detailed cost breakdown by provider
                      </Text>
                    </Box>
                    <StatNumber>
                      <Chart
                        options={{
                          chart: {
                            height: 350,
                            type: "bar",
                            toolbar: {
                              show: false,
                            },
                            stacked: true,
                            fontFamily: "Urbanist"
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: "smooth",
                          },
                          xaxis: {
                            type: "datetime",
                            categories: (analytics.data?.[0]?.result || []).map(
                              (m: any) => {
                                return m.date;
                              }
                            ),
                          },
                          tooltip: {
                            x: {
                              format: "dd/MM/yy hh:mm",
                            },
                          },
                        }}
                        series={[
                          {
                            name: "LLM",
                            data: (analytics.data?.[0]?.result || []).map(
                              (m: any) => {
                                return Number(
                                  m.sumCostBreakdownLlm || 0
                                ).toFixed(2);
                              }
                            ),
                          },
                          {
                            name: "STT",
                            data: (analytics.data?.[0]?.result || []).map(
                              (m: any) => {
                                return Number(
                                  m.sumCostBreakdownStt || 0
                                ).toFixed(2);
                              }
                            ),
                          },
                          {
                            name: "TTS",
                            data: (analytics.data?.[0]?.result || []).map(
                              (m: any) => {
                                return Number(
                                  m.sumCostBreakdownTts || 0
                                ).toFixed(2);
                              }
                            ),
                          },
                          {
                            name: "ESL",
                            data: (analytics.data?.[0]?.result || []).map(
                              (m: any) => {
                                return Number(
                                  m.sumCostBreakdownVapi || 0
                                ).toFixed(2);
                              }
                            ),
                          },
                        ]}
                        type="bar"
                      />
                    </StatNumber>
                  </Stat>
                </Skeleton>
              </Grid>
            </Stack>
            <Stack 
              flex={0.4} p={2} rounded={8} border={"1px solid #e4e4e4"} mt={4}>
              <Box mb={3}>
                <Text fontWeight={700}>Agent Interaction Metrics</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  Total conversations and average conversation duration aggregated by agent.
                </Text>
              </Box>

              {<Skeleton isLoaded={analytics.state === "success"}><TableContainer h={"fit-content"}>
                <Table size={"sm"} variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        py={2}
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"xs"}
                      >
                        Agent Name
                      </Th>
                      <Th
                        py={2}
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"xs"}
                      >
                        Conversation Count
                      </Th>
                      <Th
                        py={2}
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"xs"}
                      >
                        Avg. Duration
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(
                      _.groupBy(
                        (analytics.data?.[5]?.result || []).filter(
                          (a: any) => a.assistantId
                        ),
                        (v: any) => v.assistantId
                      )
                    ).map((v) => {
                      const count = (analytics.data?.[5]?.result || [])
                        .filter((b: any) => b.assistantId === v)
                        .map((e: any) => Number(e.countId))
                        .reduce((a: any, b: any) => a + b, 0);
                      const totalAvgDuration = (analytics.data?.[6]?.result || [])
                          .filter((b: any) => b.assistantId === v)
                          .map((e: any) => Number(e.avgDuration))
                          .reduce((a: any, b: any) => a + b, 0);
                      const { assistants = [] } = analytics.metadata || {};
                      const assistant = assistants.filter((a: any) => a.id === v)[0] || {};
                      return (
                        <Tr py={2}>
                          <Td fontWeight={600}>{assistant.name}</Td>
                          <Td>{count}</Td>
                          <Td>{totalAvgDuration.toFixed(2)} min</Td>
                        </Tr>
                      );
                    })}
                    {!analytics.data?.[5]?.result?.length ? <Tr>
                      <Td></Td>
                      <Td>No Data Found</Td>
                      <Td></Td>
                    </Tr> : <></>}
                  </Tbody>
                </Table>
                <Flex py={2} gap={2}>
                  <Button
                    isDisabled
                    variant={"outline"}
                    fontSize={"xs"}
                    leftIcon={<ChevronLeftIcon size={16} />}
                    size={"sm"}
                  >
                    Previous
                  </Button>
                  <Input
                    size={"sm"}
                    w={10}
                    rounded={8}
                    value={1}
                    textAlign={"center"}
                  />
                  <Button
                    isDisabled
                    variant={"outline"}
                    fontSize={"xs"}
                    rightIcon={<ChevronRightIcon size={16} />}
                    size={"sm"}
                  >
                    Next
                  </Button>
                </Flex>
              </TableContainer>
              </Skeleton>}
            </Stack>
          </Flex>
        </>
      )}
    </Page>
  );
}
