import { AppDispatch } from "~/lib/store";
import { setContactInteractionsLoading, setContactInteractionsSuccess, setDeleteContactsLoading, setDeleteContactsSuccess, setGetContactsLoading, setGetContactsSuccess, setListContactsLoading, setListContactsSuccess } from "./actions";
import { axios } from "~/utils/axios.loader";

export const listContacts = (organization_id: string, page: number = 1, page_size: number = 25, filters: any = {}, keyword: string = '') => async (dispatch: AppDispatch) => {
    dispatch(setListContactsLoading())
    try {
        const params = new URLSearchParams()
        for (const key of Object.keys(filters)) {
            if (filters[key]) {
                params.append("filters", `${key}=${(typeof(filters[key]) === "string" ? [filters[key]] : filters[key]).join(",")}`)
            }
        }
        const request = await axios.get(`/contact/organizations/${organization_id}/?page=${page}&page_size=${page_size}${Array.from(params.keys()).length ? `&${params.toString()}` : ''}${keyword ? `&fuzzy=number,identifier,email,id=${keyword}` : ''}`)
        const { contacts, total_count } = request.data
        dispatch(setListContactsSuccess(contacts, {
            total_count
        }))
    } catch (err) {
        console.log(err)
    }
}

export const getContact = (organization_id: string, contact_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setGetContactsLoading())
    try {
        const request = await axios.get(`/contact/organizations/${organization_id}/${contact_id}`)
        const contact = request.data
        dispatch(setGetContactsSuccess(contact))
    } catch (err) {
        console.log(err)
    }
}

export const getContactInteractions = (organization_id: string, contact_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setContactInteractionsLoading())
    try {
        const request = await axios.get(`/interactions/organizations/${organization_id}?filters=contact_id[eq]=${contact_id}&sort_value=-created_at`)
        const { interactions, total_count } = request.data
        dispatch(setContactInteractionsSuccess(interactions, {
            total_count
        }))
    } catch (err) {
        console.log(err)
    }
}

export const deleteContact = (organization_id: string, contact_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setDeleteContactsLoading())
    try {
        const request = await axios.delete(`/contact/organizations/${organization_id}/${contact_id}`)
        const contact = request.data
        dispatch(setDeleteContactsSuccess(contact))
    } catch (err) {
        console.log(err)
    }
}