import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  AudioLinesIcon,
  BellIcon,
  BlocksIcon,
  Building2Icon,
  ChevronDownIcon,
  ChevronLeftCircleIcon,
  ChevronRightIcon,
  CommandIcon,
  CreditCardIcon,
  FolderOpenIcon,
  FunctionSquareIcon,
  GitPullRequestIcon,
  HelpCircleIcon,
  HomeIcon,
  LogOutIcon,
  PhoneOutgoingIcon,
  PlusIcon,
  RefreshCcwIcon,
  Settings2Icon,
  SettingsIcon,
  Users2Icon,
  UsersIcon,
  ZapIcon,
} from "lucide-react";
import _ from "lodash";
import Logo from "../assets/img/logo-full.png";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { UserProfile } from "../lib/app/platform/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import {
  getOrganization,
  listOrganizations,
} from "~/lib/app/organization/thunk";
import {
  getOrganizationTeams,
  getSpecificOrganizationTeam,
} from "~/lib/app/teams/thunk";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "~/components/ui/menubar";
import {
  AvatarFallback,
  AvatarImage,
  Avatar as NewAvatar,
} from "~/components/ui/avatar";
import { Checkbox } from "~/components/ui/checkbox";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "~/components/ui/command";

export default function Sidebar() {
  const profile = useAppSelector<UserProfile>(
    (state) => state.app.platform.profile.data
  );
  const teams = useAppSelector((state) => state.app.teams.list);
  const team = useAppSelector<any>((state) => state.app.teams.get);
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);
  const { organization, team: teamId } = useParams();
  const organizations = useAppSelector((state) => state.app.organizations.list);
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const { isOpen, onClose } = useDisclosure();
  const { isOpen: isActionsOpen, onToggle } = useDisclosure();
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onToggle();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    if (teamId && organization) {
      dispatch(getSpecificOrganizationTeam(organization!, teamId));
    }
  }, [teamId, organization]);

  return (
    <Stack
      gap={3}
      className="border-r"
      h={"100vh"}
      py={4}
      px={2}
      background={"rgb(58, 56, 77, 0.04)"}
      justifyContent={"space-between"}
    >
      <CommandDialog open={isActionsOpen} onOpenChange={onToggle}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No actions found.</CommandEmpty>
          <CommandGroup heading="Suggestions">
            <CommandItem
              onClick={() => {
                navigate(`/app/${organization}/home`);
              }}
            >
              <BlocksIcon className="mr-2 size-4" />
              <span>Home</span>
            </CommandItem>
            <CommandItem
              onClick={() => {
                navigate(`/app/${organization}/member-access`);
              }}
            >
              <Users2Icon className="mr-2 size-4" />
              <span>Team & Access</span>
            </CommandItem>
            <CommandItem
              onClick={() => {
                navigate(`/app/${organization}/settings`);
              }}
            >
              <Settings2Icon className="mr-2 size-4" />
              <span>Settings</span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
        </CommandList>
      </CommandDialog>
      <Modal
        size={"3xl"}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalBody>
            <Heading textAlign={"center"} mb={6} fontSize={"lg"}>
              Choose organisation to switch
            </Heading>
            <Grid gap={3} gridTemplateColumns={"repeat(3, 1fr)"}>
              {organizations.data.map((org: any) => (
                <Stack
                  pos={"relative"}
                  transition={"all 0.3s"}
                  cursor={"pointer"}
                  style={{
                    background: selectedOrg === org.id ? "#e6e5fb" : "white",
                    color: selectedOrg === org.id ? "black" : "black",
                  }}
                  onClick={() => {
                    setSelectedOrg(org.id);
                  }}
                  border={`1px solid ${
                    selectedOrg === org.id ? "#e6e5fb" : "#e4e4e4"
                  }`}
                  bg={"white"}
                  p={4}
                  borderRadius={12}
                >
                  <CheckCircleIcon
                    boxSize={5}
                    display={selectedOrg === org.id ? "block" : "none"}
                    pos={"absolute"}
                    top={4}
                    right={4}
                    color={"hsl(var(--primary))"}
                  />
                  <Box h={16} mb={2} mt={1}>
                    {org.image_url ? (
                      <Image
                        fallback={
                          <Avatar
                            size={"sm"}
                            icon={<Building2Icon color="black" />}
                            background={"#fdf9ee"}
                            objectFit={"contain"}
                            w={10}
                            h={10}
                            src={org.image_url}
                          />
                        }
                        h={16}
                        w={"100%"}
                        objectFit={"contain"}
                        src={org.image_url}
                      />
                    ) : (
                      <Avatar
                        size={"sm"}
                        icon={<Building2Icon color="black" />}
                        background={"#fdf9ee"}
                        objectFit={"contain"}
                        w={10}
                        h={4}
                        src={org.image_url}
                      />
                    )}
                  </Box>
                  <Stack gap={1}>
                    <Heading size={"sm"}>{org.name}</Heading>
                    <Text
                      noOfLines={2}
                      fontSize={"sm"}
                      color={selectedOrg === org.id ? "black" : "gray"}
                    >
                      {org.description}
                    </Text>
                    <Text noOfLines={1} color={"gray"} fontSize={"xs"}>
                      ID: {org.id}
                    </Text>
                  </Stack>
                </Stack>
              ))}
            </Grid>
            <Button
              onClick={() => {
                window.location.href = `/app/${selectedOrg}/home`;
              }}
              alignItems={"center"}
              display={"flex"}
              w={"fit-content"}
              mx={"auto"}
              mt={6}
              fontSize={"sm"}
              colorScheme="primary"
              rightIcon={<RefreshCcwIcon size={16} />}
            >
              Switch organization
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Image src={Logo} />
        {/* <Box cursor={"pointer"}  onClick={() => {
              onOpen()
          }} pos={"relative"}>
        <IconButton rounded={"full"} padding={0.5} right={-0.5} bottom={-0.5} zIndex={1} background={"white"} pos={"absolute"} mt={2}  variant={"outline"} size={"2xs"} aria-label='Switch Organization' icon={<RepeatIcon size={10} />} />
        <Avatar border={"0.5px solid #e4e4e4"} name={_organization.data.name} size={"sm"} src={_organization.data.image_url} />
        </Box> */}
      </Flex>
      <Menubar className="w-full h-auto bg-transparent p-0 m-0 border-0">
        <MenubarMenu>
          <MenubarTrigger className={"w-full cursor-pointer p-0 m-0"}>
            <Box px={3} py={2} className="border rounded-xl bg-white w-full">
              {_organization.data.image_url ? (
                <Image src={_organization.data.image_url} />
              ) : (
                <></>
              )}
              <Divider my={2} />
              <Flex flex={1} alignItems={"center"}>
                <Box textAlign={"start"} flex={1}>
                  <Text fontWeight={"semibold"}>{_organization.data.name}</Text>
                </Box>
                <ChevronRightIcon size={20} />
              </Flex>
              <Flex
              display={"none"}
                onClick={() => {
                  onToggle();
                }}
                gap={2}
                alignItems={"center"}
                p={2}
                px={3}
                cursor={"pointer"}
                className="border"
                mt={2}
                rounded={"full"}
              >
                <FunctionSquareIcon size={16} />
                <Text className="font-semibold" fontSize={"xs"} flex={1}>
                  Quick actions
                </Text>
                <Text
                  display={"flex"}
                  alignItems={"center"}
                  whiteSpace={"nowrap"}
                  className="text-sm font-semibold"
                >
                  <Text as={"span"} className="text-xs">
                    ⌘ K
                  </Text>
                </Text>
              </Flex>
            </Box>
          </MenubarTrigger>
          <MenubarContent style={{ width: 260 }} className={"ml-2"}>
            <Box maxH={"60vh"} overflow={"scroll"}>
              {organizations.data.map((w: any) => (
                <>
                  <MenubarItem
                    onClick={() => {
                      setSelectedOrg(w.id);
                      window.location.href = `/app/${w.id}/home`;
                    }}
                    className={"gap-2 cursor-pointer"}
                  >
                    {/* <NewAvatar
                        className={
                          "py-1 w-6 h-6 justify-center rounded-md border"
                        }
                      >
                        <AvatarImage
                          src={`https://s2.googleusercontent.com/s2/favicons?domain=${w.workspace.website}&sz=256`}
                        />
                        <AvatarFallback
                          style={{
                            backgroundColor: `${generateHSL(
                              w?.workspace?.name || ""
                            )}`,
                          }}
                          className={
                            "uppercase bg-transparent text-center rounded-md"
                          }
                        >
                          {(w?.workspace?.name || "")[0]}
                        </AvatarFallback>
                      </NewAvatar> */}
                    <Box>
                      <Text className="capitalize text-semibold text-20">
                        {w?.name}
                      </Text>
                      <Text className="text-muted-foreground text-sm font-semibold">
                        {w.teams} teams, {w.agents} agents
                      </Text>
                    </Box>
                    <MenubarShortcut>
                      {w?.id === selectedOrg ? (
                        <Checkbox
                          readonly
                          checked={true}
                          iconSize={10}
                          className={"rounded-lg"}
                        />
                      ) : (
                        <></>
                      )}
                    </MenubarShortcut>
                  </MenubarItem>

                  <MenubarSeparator />
                </>
              ))}
            </Box>
            <MenubarItem className={"gap-2"}>
              <PlusIcon size={16} /> Create new organization
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      <Stack mt={2} gap={1} flex={1}>
        <Button
          className="!underline text-muted-foreground"
          onClick={() => {
            navigate(`/app/${organization}/home`);
          }}
          justifyContent={"start"}
          variant={"none"}
          leftIcon={<ChevronLeftCircleIcon size={16} />}
          size={"sm"}
        >
          Back to teams
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/home`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          rounded={"full"}
          px={3}
          variant={"none"}
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/home`
            )
              ? "bg-primary/15"
              : "none"
          }
          size={"sm"}
          hidden
          justifyContent={"start"}
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/home`);
          }}
          fontSize={"sm"}
          colorScheme="primary"
          leftIcon={<HomeIcon size={20} />}
          aria-label=""
        >
          Home
        </Button>
        <Button
          justifyContent={"start"}
          size={"sm"}
          rounded={"full"}
          px={3}
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/assistants`);
          }}
          colorScheme="primary"
          fontSize={"sm"}
          variant={"none"}
          h={"36px"}
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/assistants`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/assistants`
            )
              ? "bg-primary/15"
              : "none"
          }
          leftIcon={<ZapIcon size={20} />}
          aria-label=""
        >
          Agents
        </Button>
        <Button
          h={"36px"}
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/analytics`);
          }}
          size={"sm"}
          rounded={"full"}
          px={3}
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/analytics`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/analytics`
            )
              ? "bg-primary/15"
              : "none"
          }
          fontSize={"sm"}
          justifyContent={"start"}
          aria-label=""
          leftIcon={<AudioLinesIcon size={20} />}
        >
          Analytics
        </Button>
        <Button
          h={"36px"}
          justifyContent={"start"}
          size={"sm"}
          rounded={"full"}
          px={3}
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/files`);
          }}
          colorScheme="primary"
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/files`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/files`
            )
              ? "bg-primary/15"
              : "none"
          }
          fontSize={"sm"}
          leftIcon={<FolderOpenIcon size={20} />}
          aria-label=""
        >
          Files
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/contacts`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/contacts`
            )
              ? "bg-primary/15"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/contacts`);
          }}
          rounded={"full"}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<UsersIcon size={20} />}
        >
          Contacts
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/logs`
            )
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(
              `/app/${organization}/team/${teamId}/logs`
            )
              ? "bg-primary/15"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/team/${teamId}/logs`);
          }}
          rounded={"full"}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<PhoneOutgoingIcon size={20} />}
        >
          Conversations
        </Button>
        <Divider w={220} />
        <Stack mt={3}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Text className="font-semibold text-sm">Your teams</Text>
            {/* <PlusIcon size={16} /> */}
          </Flex>
          <Stack p={2} gap={4}>
            {teams.data.map((team: any) => {
              const mapping: {
                [key: string]: {
                  background: string;
                  color: string;
                };
              } = {
                SALES: {
                  background: "#FFE9C1",
                  color: "#925E41",
                },
                MARKETING: {
                  background: "#C1D2FF",
                  color: "#414992",
                },
                ENGINEERING: {
                  background: "#FFCCC1",
                  color: "#925441",
                },
                RESEARCH: {
                  background: "#fae8ff",
                  color: "#d946ef",
                },
                SUPPORT: {
                  background: "#fef9c3",
                  color: "#6366f1",
                },
                RECRUITMENT: {
                  background: "#fef9c3",
                  color: "#eab308",
                },
              };
              const { background, color } = mapping[team.type] || {
                background: "#c7d2fe",
                color: "#925E41",
              };
              return (
                <Flex
                  cursor={"pointer"}
                  onClick={() => {
                    window.location.href = `/app/${organization}/team/${team.id}/assistants`;
                  }}
                  gap={2}
                  alignItems={"center"}
                >
                  <Text
                    background={background}
                    color={color}
                    px={2}
                    py={0.5}
                    fontWeight={"semibold"}
                    fontSize={"sm"}
                    rounded={"md"}
                  >
                    {team.name[0]}
                  </Text>
                  <Text
                    fontSize={"sm"}
                    color={"#6C6685"}
                    fontWeight={"semibold"}
                  >
                    {team.name}
                  </Text>
                </Flex>
              );
            })}

            {/* <Flex gap={2} alignItems={"center"}>
            <Text background={"#C1D2FF"} color={"#414992"} px={2} py={0.5} fontWeight={"semibold"} fontSize={"sm"} rounded={"md"}>M</Text>
            <Text fontSize={"sm"} color={"#6C6685"} fontWeight={"semibold"}>Marketing</Text>
          </Flex>
          <Flex gap={2} alignItems={"center"}>
            <Text background={"#FFCCC1"} color={"#925441"} px={2} py={0.5} fontWeight={"semibold"} fontSize={"sm"} rounded={"md"}>D</Text>
            <Text fontSize={"sm"} color={"#6C6685"} fontWeight={"semibold"}>Dev Team</Text>
          </Flex> */}
          </Stack>
          <Divider w={220} />
        </Stack>
      </Stack>
      <Stack>
        <Button
          variant={"none"}
          onClick={() => {
            window.open("mailto:hello@electricspeech.co", "_blank");
          }}
          px={0}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          size={"sm"}
          leftIcon={<HelpCircleIcon size={16} />}
        >
          Need Help ?
        </Button>
        <Divider />
        <Flex justifyContent={"space-between"} alignItems={"center"} gap={2}>
          <Avatar
            size={"sm"}
            src={profile.profile_pic}
            icon={
              <Avatar
                size={"sm"}
                src={`https://avatar.vercel.sh/${profile.username}`}
              />
            }
          />
          <Box flex={1}>
            <Text fontWeight={600}>
              {_.upperFirst(profile.name || profile.username)}
            </Text>
            <Tooltip hasArrow label={profile.email}>
              <Text noOfLines={1} w={120} fontSize={"small"}>
                {profile.email}
              </Text>
            </Tooltip>
          </Box>
          <IconButton
            size={"sm"}
            onClick={() => {
              removeCookie("accessToken", {
                path: "/",
              });
              removeCookie("refreshToken", {
                path: "/",
              });
              window.location.href = `/auth/login?logout=true`;
            }}
            aria-label=""
            icon={<LogOutIcon size={16} />}
          />
        </Flex>
      </Stack>
    </Stack>
  );
}

export function HomeSidebar() {
  const profile = useAppSelector<UserProfile>(
    (state) => state.app.platform.profile.data
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);
  const { organization } = useParams();
  const organizations = useAppSelector((state) => state.app.organizations.list);
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  return (
    <Stack
      gap={3}
      className="border-r"
      h={"100vh"}
      py={4}
      px={2}
      background={"rgb(58, 56, 77, 0.04)"}
      justifyContent={"space-between"}
    >
      <Modal
        size={"3xl"}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalBody>
            <Heading textAlign={"center"} mb={6} fontSize={"lg"}>
              Choose organisation to switch
            </Heading>
            <Grid gap={3} gridTemplateColumns={"repeat(3, 1fr)"}>
              {organizations.data.map((org: any) => (
                <Stack
                  pos={"relative"}
                  transition={"all 0.3s"}
                  cursor={"pointer"}
                  style={{
                    background: selectedOrg === org.id ? "#e6e5fb" : "white",
                    color: selectedOrg === org.id ? "black" : "black",
                  }}
                  onClick={() => {
                    setSelectedOrg(org.id);
                  }}
                  border={`1px solid ${
                    selectedOrg === org.id ? "#e6e5fb" : "#e4e4e4"
                  }`}
                  bg={"white"}
                  p={4}
                  borderRadius={12}
                >
                  <CheckCircleIcon
                    boxSize={5}
                    display={selectedOrg === org.id ? "block" : "none"}
                    pos={"absolute"}
                    top={4}
                    right={4}
                    color={"hsl(var(--primary))"}
                  />
                  <Box h={16} mb={2} mt={1}>
                    {org.image_url ? (
                      <Image
                        fallback={
                          <Avatar
                            size={"sm"}
                            icon={<Building2Icon color="black" />}
                            background={"#fdf9ee"}
                            objectFit={"contain"}
                            w={10}
                            h={10}
                            src={org.image_url}
                          />
                        }
                        h={16}
                        w={"100%"}
                        objectFit={"contain"}
                        src={org.image_url}
                      />
                    ) : (
                      <Avatar
                        size={"sm"}
                        icon={<Building2Icon color="black" />}
                        background={"#fdf9ee"}
                        objectFit={"contain"}
                        w={10}
                        h={4}
                        src={org.image_url}
                      />
                    )}
                  </Box>
                  <Stack gap={1}>
                    <Heading size={"sm"}>{org.name}</Heading>
                    <Text
                      noOfLines={2}
                      fontSize={"sm"}
                      color={selectedOrg === org.id ? "black" : "gray"}
                    >
                      {org.description}
                    </Text>
                    <Text noOfLines={1} color={"gray"} fontSize={"xs"}>
                      ID: {org.id}
                    </Text>
                  </Stack>
                </Stack>
              ))}
            </Grid>
            <Button
              onClick={() => {
                window.location.href = `/app/${selectedOrg}/home`;
              }}
              alignItems={"center"}
              display={"flex"}
              w={"fit-content"}
              mx={"auto"}
              mt={6}
              fontSize={"sm"}
              colorScheme="primary"
              rightIcon={<RefreshCcwIcon size={16} />}
            >
              Switch organization
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Image src={Logo} />
        {/* <Box cursor={"pointer"}  onClick={() => {
              onOpen()
          }} pos={"relative"}>
        <IconButton rounded={"full"} padding={0.5} right={-0.5} bottom={-0.5} zIndex={1} background={"white"} pos={"absolute"} mt={2}  variant={"outline"} size={"2xs"} aria-label='Switch Organization' icon={<RepeatIcon size={10} />} />
        <Avatar border={"0.5px solid #e4e4e4"} name={_organization.data.name} size={"sm"} src={_organization.data.image_url} />
        </Box> */}
      </Flex>
      <Menubar className="w-full h-auto bg-transparent p-0 m-0 border-0">
        <MenubarMenu>
          <MenubarTrigger className={"w-full cursor-pointer p-0 m-0"}>
            <Box px={3} py={2} className="border rounded-xl bg-white w-full">
              {_organization.data.image_url ? (
                <Image src={_organization.data.image_url} />
              ) : (
                <></>
              )}
              <Divider my={2} />
              <Flex flex={1} alignItems={"center"}>
                <Box textAlign={"start"} flex={1}>
                  <Text fontWeight={"semibold"}>{_organization.data.name}</Text>
                </Box>
                <ChevronRightIcon size={20} />
              </Flex>
              {/* <Flex className="cursor-pointer" textAlign={"start"} justifyContent={"space-between"} alignItems={"center"}>
              <Box flex={1}>
                <Text fontWeight={"semibold"}>
                  {_organization.data?.name || ""}
                </Text>
                <Text className="text-muted-foreground text-sm font-semibold">{_organization.data.teams} teams, {_organization.data.agents} agents</Text>
              </Box>
              <ChevronDownIcon size={20} />
            </Flex> */}
              <Flex
              display={"none"}
                gap={2}
                alignItems={"center"}
                p={2}
                px={3}
                className="border"
                mt={2}
                rounded={"full"}
              >
                <FunctionSquareIcon size={16} />
                <Text
                  className="font-semibold"
                  textAlign={"start"}
                  fontSize={"xs"}
                  flex={1}
                >
                  Quick actions
                </Text>
                <Text
                  display={"flex"}
                  alignItems={"center"}
                  whiteSpace={"nowrap"}
                  className="text-sm font-semibold"
                >
                  <CommandIcon size={10} />{" "}
                  <Text as={"span"} className="text-xs">
                    K
                  </Text>
                </Text>
              </Flex>
            </Box>
          </MenubarTrigger>
          <MenubarContent style={{ width: 260 }} className={"ml-2"}>
            <Box maxH={"60vh"} overflow={"scroll"}>
              {organizations.data.map((w: any) => (
                <>
                  <MenubarItem
                    onClick={() => {
                      setSelectedOrg(w.id);
                      window.location.href = `/app/${w.id}/home`;
                    }}
                    className={"gap-2 cursor-pointer"}
                  >
                    {/* <NewAvatar
                        className={
                          "py-1 w-6 h-6 justify-center rounded-md border"
                        }
                      >
                        <AvatarImage
                          src={`https://s2.googleusercontent.com/s2/favicons?domain=${w.workspace.website}&sz=256`}
                        />
                        <AvatarFallback
                          style={{
                            backgroundColor: `${generateHSL(
                              w?.workspace?.name || ""
                            )}`,
                          }}
                          className={
                            "uppercase bg-transparent text-center rounded-md"
                          }
                        >
                          {(w?.workspace?.name || "")[0]}
                        </AvatarFallback>
                      </NewAvatar> */}
                    <Box>
                      <Text className="capitalize text-semibold text-20">
                        {w?.name}
                      </Text>
                      <Text className="text-muted-foreground text-sm font-semibold">
                        {w.teams} teams, {w.agents} agents
                      </Text>
                    </Box>
                    <MenubarShortcut>
                      {w?.id === selectedOrg ? (
                        <Checkbox
                          readonly
                          checked={true}
                          iconSize={10}
                          className={"rounded-lg"}
                        />
                      ) : (
                        <></>
                      )}
                    </MenubarShortcut>
                  </MenubarItem>

                  <MenubarSeparator />
                </>
              ))}
            </Box>
            <MenubarItem className={"gap-2"}>
              <PlusIcon size={16} /> Create new organization
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      <Stack mt={4} gap={1} flex={1}>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(`/app/${organization}/home`)
              ? "hsl(var(--primary))"
              : "black"
          }
          rounded={"full"}
          px={3}
          variant={"none"}
          className={
            location.pathname.includes(`/app/${organization}/home`)
              ? "bg-primary/15"
              : "none"
          }
          size={"sm"}
          justifyContent={"start"}
          onClick={() => {
            navigate(`/app/${organization}/home`);
          }}
          fontSize={"sm"}
          colorScheme="primary"
          leftIcon={<BlocksIcon size={20} />}
          aria-label=""
        >
          My Teams
        </Button>
        <Button
          justifyContent={"start"}
          size={"sm"}
          rounded={"full"}
          px={3}
          onClick={() => {
            navigate(`/app/${organization}/member-access`);
          }}
          colorScheme="primary"
          fontSize={"sm"}
          variant={"none"}
          h={"36px"}
          color={
            location.pathname.includes(`/${organization}/member-access`)
              ? "hsl(var(--primary))"
              : "black"
          }
          className={
            location.pathname.includes(`/${organization}/member-access`)
              ? "bg-primary/15"
              : "none"
          }
          leftIcon={<ZapIcon size={20} />}
          aria-label=""
        >
          Members & Access
        </Button>
        <Button
          h={"36px"}
          onClick={() => {
            navigate(`/app/${organization}/notifications`);
          }}
          size={"sm"}
          rounded={"full"}
          px={3}
          color={
            location.pathname.includes(`/app/${organization}/notifications`)
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(`/app/${organization}/notifications`)
              ? "bg-primary/15"
              : "none"
          }
          fontSize={"sm"}
          justifyContent={"start"}
          aria-label=""
          leftIcon={<BellIcon size={20} />}
        >
          <Text flex={1} textAlign={"left"}>
            Notifications
          </Text>

          {/* <Text
              right={-2}
              top={-2}
              zIndex={2}
              fontWeight={"semibold"}
              fontSize={"xs"}
              textAlign={"center"}
              p={0.5}
              w={5}
              h={5}
              rounded={"full"}
              background={"red.600"}
              color={"white"}
              className="!bg-red-500"
            >
              2
            </Text> */}
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(`/app/${organization}/integrations`)
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(`/app/${organization}/integrations`)
              ? "bg-primary/15"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/integrations`);
          }}
          rounded={"full"}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<GitPullRequestIcon size={20} />}
        >
          Integrations
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(`/app/${organization}/manage-billing`)
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          background={
            location.pathname.includes(`/app/${organization}/manage-billing`)
            ? "bg-primary/15"
            : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/manage-billing`);
          }}
          rounded={"full"}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<CreditCardIcon size={20} />}
        >
          Manage Billing
        </Button>
        <Button
          h={"36px"}
          color={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "hsl(var(--primary))"
              : "black"
          }
          variant={"none"}
          className={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "bg-primary/15"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/settings`);
          }}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          rounded={"full"}
          px={3}
          leftIcon={<SettingsIcon size={20} />}
        >
          Settings
        </Button>
        {/* <Text
          ml={1}
          fontWeight={"bold"}
          color={"gray"}
          fontSize={"x-small"}
          textTransform={"uppercase"}
        >
          Organization
        </Text>
        <Button
          color={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "hsl(var(--primary))"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/settings`);
          }}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<Building2Icon size={20} />}
        >
          Your organization
        </Button>
        <Button
          color={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "hsl(var(--primary))"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes(`/app/${organization}/settings`)
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          onClick={() => {
            navigate(`/app/${organization}/settings`);
          }}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<UsersIcon size={20} />}
        >
          Members
        </Button> */}
        <Divider w={220} />
      </Stack>
      <Stack>
        <Button
          variant={"none"}
          onClick={() => {
            window.open("mailto:hello@electricspeech.co", "_blank");
          }}
          px={0}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          size={"sm"}
          leftIcon={<HelpCircleIcon size={16} />}
        >
          Need Help ?
        </Button>
        <Divider />
        <Flex justifyContent={"space-between"} alignItems={"center"} gap={2}>
          <Avatar
            size={"sm"}
            src={profile.profile_pic}
            icon={
              <Avatar
                size={"sm"}
                src={`https://avatar.vercel.sh/${profile.username}`}
              />
            }
          />
          <Box flex={1}>
            <Text fontWeight={600}>
              {_.upperFirst(profile.name || profile.username)}
            </Text>
            <Tooltip hasArrow label={profile.email}>
              <Text noOfLines={1} w={120} fontSize={"small"}>
                {profile.email}
              </Text>
            </Tooltip>
          </Box>
          <IconButton
            size={"sm"}
            onClick={() => {
              removeCookie("accessToken", {
                path: "/",
              });
              removeCookie("refreshToken", {
                path: "/",
              });
              window.location.href = "/auth/login?logout=true"
            }}
            aria-label=""
            icon={<LogOutIcon size={16} />}
          />
        </Flex>
      </Stack>
    </Stack>
  );
}
