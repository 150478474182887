import {
    SET_CREATE_INTEGRATIONS_LOADING,
    SET_CREATE_INTEGRATIONS_SUCCESS,
    SET_CREATE_INTEGRATIONS_ERROR,
    SET_UPDATE_INTEGRATIONS_LOADING,
    SET_UPDATE_INTEGRATIONS_SUCCESS,
    SET_UPDATE_INTEGRATIONS_ERROR,
    SET_DELETE_INTEGRATIONS_LOADING,
    SET_DELETE_INTEGRATIONS_SUCCESS,
    SET_DELETE_INTEGRATIONS_ERROR,
    SET_GET_INTEGRATIONS_LOADING,
    SET_GET_INTEGRATIONS_SUCCESS,
    SET_GET_INTEGRATIONS_ERROR,
    SET_LIST_INTEGRATIONS_LOADING,
    SET_LIST_INTEGRATIONS_SUCCESS,
    SET_LIST_INTEGRATIONS_ERROR
} from './constants';

// Action Creators for integrations
export const setCreateIntegrationsLoading = () => ({
    type: SET_CREATE_INTEGRATIONS_LOADING,
});

export const setCreateIntegrationsSuccess = (integration: any) => ({
    type: SET_CREATE_INTEGRATIONS_SUCCESS,
    payload: integration,
});

export const setCreateIntegrationsError = (error: string) => ({
    type: SET_CREATE_INTEGRATIONS_ERROR,
    payload: error,
});

export const setUpdateIntegrationsLoading = () => ({
    type: SET_UPDATE_INTEGRATIONS_LOADING,
});

export const setUpdateIntegrationsSuccess = (integration: any) => ({
    type: SET_UPDATE_INTEGRATIONS_SUCCESS,
    payload: integration,
});

export const setUpdateIntegrationsError = (error: string) => ({
    type: SET_UPDATE_INTEGRATIONS_ERROR,
    payload: error,
});

export const setDeleteIntegrationsLoading = () => ({
    type: SET_DELETE_INTEGRATIONS_LOADING,
});

export const setDeleteIntegrationsSuccess = (integrationId: string) => ({
    type: SET_DELETE_INTEGRATIONS_SUCCESS,
    payload: integrationId,
});

export const setDeleteIntegrationsError = (error: string) => ({
    type: SET_DELETE_INTEGRATIONS_ERROR,
    payload: error,
});

export const setGetIntegrationsLoading = () => ({
    type: SET_GET_INTEGRATIONS_LOADING,
});

export const setGetIntegrationsSuccess = (integration: any) => ({
    type: SET_GET_INTEGRATIONS_SUCCESS,
    payload: integration,
});

export const setGetIntegrationsError = (error: string) => ({
    type: SET_GET_INTEGRATIONS_ERROR,
    payload: error,
});

export const setListIntegrationsLoading = () => ({
    type: SET_LIST_INTEGRATIONS_LOADING,
});

export const setListIntegrationsSuccess = (integrations: any[]) => ({
    type: SET_LIST_INTEGRATIONS_SUCCESS,
    payload: integrations,
});

export const setListIntegrationsError = (error: string) => ({
    type: SET_LIST_INTEGRATIONS_ERROR,
    payload: error,
});
