import {
    SET_CREATE_INTEGRATIONS_LOADING,
    SET_CREATE_INTEGRATIONS_SUCCESS,
    SET_CREATE_INTEGRATIONS_ERROR,
    SET_UPDATE_INTEGRATIONS_LOADING,
    SET_UPDATE_INTEGRATIONS_SUCCESS,
    SET_UPDATE_INTEGRATIONS_ERROR,
    SET_DELETE_INTEGRATIONS_LOADING,
    SET_DELETE_INTEGRATIONS_SUCCESS,
    SET_DELETE_INTEGRATIONS_ERROR,
    SET_GET_INTEGRATIONS_LOADING,
    SET_GET_INTEGRATIONS_SUCCESS,
    SET_GET_INTEGRATIONS_ERROR,
    SET_LIST_INTEGRATIONS_LOADING,
    SET_LIST_INTEGRATIONS_SUCCESS,
    SET_LIST_INTEGRATIONS_ERROR
} from './constants';

interface BaseObjectState {
    state: string;
    data: any;
    error: any;
}

interface IntegrationsState {
    create: BaseObjectState;
    update: BaseObjectState;
    delete: BaseObjectState;
    get: BaseObjectState;
    list: BaseObjectState;
}

const INITIAL_STATE: IntegrationsState = {
    create: { state: 'pending', data: {}, error: {} },
    update: { state: 'pending', data: {}, error: {} },
    delete: { state: 'pending', data: {}, error: {} },
    get: { state: 'pending', data: {}, error: {} },
    list: { state: 'pending', data: [], error: {} },
};

const IntegrationsReducer = (state = INITIAL_STATE, action: any): IntegrationsState => {
    switch (action.type) {
        case SET_CREATE_INTEGRATIONS_LOADING:
            return { ...state, create: { ...state.create, state: 'loading', error: {} } };
        case SET_CREATE_INTEGRATIONS_SUCCESS:
            return { ...state, create: { ...state.create, state: 'success', data: action.payload, error: {} } };
        case SET_CREATE_INTEGRATIONS_ERROR:
            return { ...state, create: { ...state.create, state: 'error', error: action.payload } };

        case SET_UPDATE_INTEGRATIONS_LOADING:
            return { ...state, update: { ...state.update, state: 'loading', error: {} } };
        case SET_UPDATE_INTEGRATIONS_SUCCESS:
            return { ...state, update: { ...state.update, state: 'success', data: action.payload, error: {} } };
        case SET_UPDATE_INTEGRATIONS_ERROR:
            return { ...state, update: { ...state.update, state: 'error', error: action.payload } };

        case SET_DELETE_INTEGRATIONS_LOADING:
            return { ...state, delete: { ...state.delete, state: 'loading', error: {} } };
        case SET_DELETE_INTEGRATIONS_SUCCESS:
            return { ...state, delete: { ...state.delete, state: 'success', data: action.payload, error: {} } };
        case SET_DELETE_INTEGRATIONS_ERROR:
            return { ...state, delete: { ...state.delete, state: 'error', error: action.payload } };

        case SET_GET_INTEGRATIONS_LOADING:
            return { ...state, get: { ...state.get, state: 'loading', error: {} } };
        case SET_GET_INTEGRATIONS_SUCCESS:
            return { ...state, get: { ...state.get, state: 'success', data: action.payload, error: {} } };
        case SET_GET_INTEGRATIONS_ERROR:
            return { ...state, get: { ...state.get, state: 'error', error: action.payload } };

        case SET_LIST_INTEGRATIONS_LOADING:
            return { ...state, list: { ...state.list, state: 'loading', error: {} } };
        case SET_LIST_INTEGRATIONS_SUCCESS:
            return { ...state, list: { ...state.list, state: 'success', data: action.payload, error: {} } };
        case SET_LIST_INTEGRATIONS_ERROR:
            return { ...state, list: { ...state.list, state: 'error', error: action.payload } };

        default:
            return state;
    }
};

export default IntegrationsReducer;
