import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Button as CoreButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import {
  ChevronsUpDownIcon,
  Edit2Icon,
  EditIcon,
  MoreVerticalIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import { Field, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useEffect, useState } from "react";
import {
  addMemberTeam,
  getTeam,
  removeMemberTeam,
  updateMemberTeam,
} from "../../lib/app/team/thunk";
import _ from "lodash";
import { Select, chakraComponents } from "chakra-react-select";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import ReactWordcloud from "react-wordcloud";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

export default function Team() {
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const _organizationUpdated = useAppSelector(
    (state) => state.app.organizations.update
  );
  const profile = useAppSelector((state) => state.app.platform.profile);
  const team = useAppSelector((state) => state.app.team.list);
  const create = useAppSelector((state) => state.app.team.create);
  const update = useAppSelector((state) => state.app.team.update);
  const _delete = useAppSelector((state) => state.app.team.delete);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [currentMember, setCurrentMember] = useState<any | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(25)

  const debouncedSearch = _.debounce(() => {
    dispatch(getTeam(_organization.data.id, keyword, {
      page,
      size
    }))
  }, 300)

  useEffect(() => {
    if (_organization.data.id) {
      debouncedSearch()
    }
  }, [_organization.data, page, size, keyword]);

  return (
    <Page
      subtitle="Manage workspace members, set access levels"
      title="Members & Access"
    >
      <Modal
        isCentered
        isOpen={isDeleteOpen}
        onClose={() => {
          setCurrentMember(null);
          onDeleteClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Member</ModalHeader>
          <ModalBody>
            Are you sure to remove <strong>{currentMember?.name}</strong> from
            your team ?
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                dispatch(
                  removeMemberTeam(
                    _organization?.data?.id,
                    currentMember?.id,
                    () => {
                      onDeleteClose();
                      setCurrentMember(null);
                      toast({
                        title: "Member removed successfully",
                        status: "success",
                      });
                      setPage(1)
                      setKeyword('')
                      debouncedSearch()
                    }
                  )
                );
              }}
              isLoading={_delete.state === "loading"}
              variant="destructive"
            >
              Yes, remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setCurrentMember(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader pb={0}>
            {currentMember?.id ? `Update ${currentMember?.name}` : "Add Member"}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                email: currentMember?.email || "",
                name: currentMember?.name || "",
                role: currentMember?.role || "MEMBER",
              }}
              onSubmit={(values) => {
                if (currentMember?.id) {
                  dispatch(
                    updateMemberTeam(
                      _organization?.data?.id,
                      currentMember?.id,
                      values.role,
                      () => {
                        onClose();
                        setCurrentMember(null);
                        toast({
                          title: "Member updated successfully",
                          status: "success",
                        });
                        setPage(1)
                        setKeyword('')
                        debouncedSearch()
                      }
                    )
                  );
                } else {
                  dispatch(
                    addMemberTeam(
                      _organization?.data?.id,
                      values.email,
                      values.role,
                      values.name,
                      () => {
                        onClose();
                        setCurrentMember(null);
                        toast({
                          title: "Member added successfully",
                          status: "success",
                        });
                        setPage(1)
                        setKeyword('')
                        debouncedSearch()
                      },
                      () => {
                        toast({
                          title: "Member already invited",
                          status: "error",
                        });
                      }
                    )
                  );
                }
              }}
            >
              {({ errors, touched, values, setFieldValue }) => {
                return (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {!currentMember?.id ? (
                      <Stack gap={3}>
                      <FormControl isInvalid={!!errors.name}>
                        <FormLabel fontSize={"sm"}>Name</FormLabel>
                        <Field
                          fontSize={"sm"}
                          placeholder={"Enter your member's name"}
                          validate={(v: string) => {
                            let error;
                            if (!v) {
                              error = "Member's name is required";
                            }
                            return error;
                          }}
                          as={Input}
                          name="name"
                        />
                        <FormErrorMessage>
                          {errors.name as string}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.email}>
                        <FormLabel fontSize={"sm"}>Email address</FormLabel>
                        <Field
                          fontSize={"sm"}
                          placeholder={"Enter your member's email"}
                          validate={(v: string) => {
                            let error;
                            if (!v) {
                              error = "Member's email is required";
                            }
                            return error;
                          }}
                          as={Input}
                          name="email"
                        />
                        <FormErrorMessage>
                          {errors.email as string}
                        </FormErrorMessage>
                      </FormControl>
                      </Stack>
                    ) : (
                      <></>
                    )}
                    <FormControl mt={3} isInvalid={!!errors.role}>
                      <FormLabel fontSize={"sm"}>Choose Role</FormLabel>
                      <Select
                        value={{
                          label: _.capitalize(values.role),
                          value: values.role,
                        }}
                        placeholder={"Select background sound"}
                        selectedOptionColorScheme="primary"
                        size={"sm"}
                        onChange={(v: any) => {
                          setFieldValue?.("role", v.value);
                        }}
                        variant={"outline"}
                        chakraStyles={dropdownStyles}
                        components={dropdownComponents}
                        options={[
                          {
                            label: "Admin",
                            value: "ADMIN",
                          },
                          {
                            label: "Member",
                            value: "MEMBER",
                          },
                        ]}
                      />
                      <FormErrorMessage>
                        {errors.role as string}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      type="submit"
                      onClick={() => {}}
                      isLoading={
                        create.state === "loading" || update.state === "loading"
                      }
                      className="mt-4 w-fit self-end"
                      variant="default"
                    >
                      {currentMember?.id ? "Update Member" : "Add Member"}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack px={3} mt={4}>
        <Flex
          mx={2}
          my={4}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
           {team.state === "success" ? <Text fontWeight={600} fontSize={"semibold"}>Showing {team.metadata?.total_count > 10 ? "10" : team.metadata?.total_count} of {team.metadata?.total_count}</Text> : <></>}
          </Box>
          <Flex gap={2} alignItems={"center"}>
            <FormControl  w={300}>
              <Input
                borderRadius={12}
                fontSize={"sm"}
                fontWeight={"medium"}
                onChange={(v) => {
                  setKeyword(v.target.value)
                }}
                background={"#fff"}
                placeholder="Search for members"
              />
            </FormControl>
            <Button
              isLoading={team.state === "loading"}
              onClick={() => {
                onOpen();
              }}
              variant="dark"
              size={"sm"}
            >
              <PlusIcon size={16} />
              Invite Member
            </Button>
          </Flex>
        </Flex>
        <Stack className="border" gap={0} rounded={"xl"}>
          {team.data.map((member: any) => {
            return (
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                px={3}
                py={2}
                className="border-b last:border-b-0"
              >
                <Flex gap={3} alignItems={"center"}>
                  <Avatar
                    size={"sm"}
                    src={
                      member.profile_pic ||
                      `https://avatar.vercel.sh/${member.username}`
                    }
                    name={member.name}
                  />
                  <Box>
                    <Flex gap={2} alignItems={"center"}>
                      <Text fontWeight={"semibold"}>{member.name}</Text>
                      <Badge
                        variant={
                          member.role === "ADMIN"
                            ? "primary-subtle"
                            : "orange-subtle"
                        }
                      >
                        {_.capitalize(member.role)}
                      </Badge>
                    </Flex>
                    <Text className="text-muted-foreground" fontSize={"sm"}>
                      {member.email}
                    </Text>
                  </Box>
                </Flex>
                <Flex>
                  <Flex gap={2} alignItems={"center"}>
                    {profile.data.id !== member.id ? (
                      <>
                        <CoreButton
                        onClick={() => {
                          setCurrentMember(member);
                          onOpen();
                        }}
                          variant={"outline"}
                          leftIcon={<Edit2Icon size={16} />}
                          size={"sm"}
                        >
                          Edit Access
                        </CoreButton>
                        <IconButton
                        onClick={() => {
                          setCurrentMember(member);
                          onDeleteOpen();
                        }}
                          variant={"outline"}
                          colorScheme="red"
                          aria-label=""
                          icon={<Trash2Icon size={16} />}
                          size={"sm"}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  {/* {profile.data.id !== member.id ? <DropdownMenu>
                      <DropdownMenuTrigger>
                        <IconButton variant={"outline"} size={"xs"} icon={<MoreVerticalIcon size={14} />} aria-label="" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-52">
                        <DropdownMenuItem
                        onClick={() => {
                          setCurrentMember(member);
                          onOpen();
                        }}>
                        <EditIcon className="mr-2 size-4" />
                        <span>Edit</span></DropdownMenuItem>
                        <DropdownMenuItem 
                        onClick={() => {
                          setCurrentMember(member);
                          onDeleteOpen();
                        }} className="text-red-400 hover:text-red-600 focus:text-red-600">
                        <Trash2Icon className="mr-2 size-4" />
                        <span>Delete</span></DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu> : <></>} */}
                </Flex>
              </Flex>
            );
          })}
        </Stack>
      </Stack>
    </Page>
  );
}
