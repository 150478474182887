/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Box, Flex, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Img, InputGroup, InputRightElement, Link, Stack, Text, useBoolean } from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon, MailIcon } from "lucide-react";
import GoogleIcon from '~/assets/img/google.svg'
import MicrosoftIcon from '~/assets/img/microsoft.svg'
import { initiateEmailLogin, initiateForgotPassword, initiateGoogleLogin, initiateMicrosoftLogin } from "../../lib/auth/login/thunk";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { resetState, setLoginError } from "../../lib/auth/login/actions";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "~/utils/constants.loader";

export default function ForgotPassword () {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.auth.login.forgot.state)
    const error = useAppSelector(state => state.auth.login.forgot.error)
    const navigate = useNavigate()


    return (
        <Flex direction={"row-reverse"} justifyContent={"space-between"}>
            <Stack py={6} alignItems={"center"} justifyContent={"space-between"} height={"100vh"} flex={[1]}>
                <Box></Box>
                <Stack justifyContent={"center"} flex={1} alignItems={"center"} gap={4} borderRadius={8} py={8} px={[2, 12]} w={['90vw', '40vw']}>
                    <Stack w={"full"}>
                        <Heading w={"full"}  fontWeight={"black"} variant={"h1"}>Reset your password</Heading>
                        <Box h={2.5} background={"hsl(var(--primary))"} w={16}></Box>
                        <Text>Enter your email address below. We'll send you a password reset link</Text>
                    </Stack>
                    {/* @ts-ignore */}
                    {!["loading", "pending"].includes(state) ? <Alert py={2} mb={3} fontWeight={"medium"} borderRadius={6} variant={"solid"} status={state === "success" ? "success" : "error"}>
                       <AlertTitle>{state === "success" ? "Password reset link sent" : "Failed to send email"}</AlertTitle>
                    </Alert> : <></> }
                    <Formik onSubmit={(values) => {
                        dispatch(initiateForgotPassword(values.email))
                    }} initialValues={{
                        email: ''
                    }}>
                        {({ errors, touched }) => (
                            <Form style={{
                                width: "100%"
                            }} onChange={() => {
                                dispatch(resetState())
                            }}>
                                <Stack gap={4} w={"full"}>
                                    <FormControl isInvalid={!!errors.email && touched.email}>
                                        <FormLabel fontSize={"sm"}>Email address</FormLabel>
                                        <Field fontSize="sm" validate={(value: any) => {
                                            let error;
                                            if (!value) {
                                                return "Email address is required"
                                            }
                                            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                                               return "Invalid email address"
                                            }
                                            return error
                                        }} as={Input} name="email" rounded={6} placeholder="Enter your email address" type='email'  />
                                        <FormErrorMessage fontSize={"small"}>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <Button className="w-full font-semibold" disabled={(state === "loading")} isLoading={(state === "loading" || state === "success")} type="submit" variant={"dark"}> <MailIcon size={16} /> Send password reset email</Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Text mt={4} fontSize={"sm"}>Don't have an account ? <Link href="/auth/signup" fontWeight={"semibold"}  textDecor={"underline"} color="hsl(var(--primary))">Create one</Link></Text>
                </Stack>
            </Stack>
        </Flex>
    )
}