export const SET_CREATE_CONTACTS_LOADING = "contacts/create/loading";
export const SET_CREATE_CONTACTS_SUCCESS = "contacts/create/success";
export const SET_CREATE_CONTACTS_ERROR = "contacts/create/error";

export const SET_UPDATE_CONTACTS_LOADING = "contacts/update/loading";
export const SET_UPDATE_CONTACTS_SUCCESS = "contacts/update/success";
export const SET_UPDATE_CONTACTS_ERROR = "contacts/update/error";

export const SET_DELETE_CONTACTS_LOADING = "contacts/delete/loading";
export const SET_DELETE_CONTACTS_SUCCESS = "contacts/delete/success";
export const SET_DELETE_CONTACTS_ERROR = "contacts/delete/error";

export const SET_GET_CONTACTS_LOADING = "contacts/get/loading";
export const SET_GET_CONTACTS_SUCCESS = "contacts/get/success";
export const SET_GET_CONTACTS_ERROR = "contacts/get/error";

export const SET_LIST_CONTACTS_LOADING = "contacts/list/loading";
export const SET_LIST_CONTACTS_SUCCESS = "contacts/list/success";
export const SET_LIST_CONTACTS_ERROR = "contacts/list/error";

export const SET_CONTACT_INTERACTIONS_LOADING = "contacts/interactions/loading"
export const SET_CONTACT_INTERACTIONS_SUCCESS = "contacts/interactions/success"
export const SET_CONTACT_INTERACTIONS_ERROR = "contacts/interactions/error"