import { axios } from "~/utils/axios.loader";
import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setLogsGetLoading, setLogsGetSuccess, setLogsListLoading, setLogsListSuccess } from "./actions";

export const getAllLogs = (organization_id: string, assistant?: string, page: number = 1, size: number = 25, filters: any = {}, fuzzy: string | undefined = undefined) => async (dispatch: AppDispatch) => {
    dispatch(setLogsListLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        let interactions = []
        let total_count = 0
        if (assistant) {
            const data = await manager.getCallLogs(assistant, undefined, page, size, filters, fuzzy)
            interactions = data.interactions
            total_count = data.total_count
        } else {
            const data = await manager.getCallLogs(assistant, organization_id, page, size, filters, fuzzy)
            interactions = data.interactions
            total_count = data.total_count
        }
        dispatch(setLogsListSuccess((interactions).map((v: any) => ({
            ...v,
            sentiment: v?.analysis?.[0]?.extraction?.call_sentiment,
            result: v?.analysis?.[0]?.extraction?.result,
            agent: v?.agent?.name
        })), {
            total_count
        }))
    } catch (err) {
        console.log(err)
    }
}

export const getSpecificLog = (organization_id: string, log: string, assistant: string) => async (dispatch: AppDispatch) => {
    dispatch(setLogsGetLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.getSpecificCallLog(log)
        const analysis = await axios.get(`/interactions/analysis/${log}/assistants/${assistant}`)
        // TODO, need to make this streamlined
        response.analysis = analysis.data.analysis?.[0]
        dispatch(setLogsGetSuccess(response))
    } catch (err) {

    }
}

export const rerunAnalytics = (log: string, assistant: string) => async (dispatch: AppDispatch) => {
    // dispatch(setLogsGetLoading())
    try {
        // const manager = new VapiAPIManager(organization_id)
        // const response = await manager.getSpecificCallLog(log)
        await axios.post(`/interactions/assistants/${assistant}/${log}/retry`)
        // TODO, need to make this streamlined
        // response.analysis = analysis.data.analysis?.[0]
        // dispatch(setLogsGetSuccess(response))
    } catch (err) {

    }
}