import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setCreateFileError, setCreateFileLoading, setCreateFileSuccess, setGetFileError, setGetFileLoading, setGetFileSuccess, setListFilesError, setListFilesLoading, setListFilesSuccess } from "./actions";

export const createFile = (organization_id: string, blob: Blob | File | null, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateFileLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.createFile(blob)
        dispatch(setCreateFileSuccess(response))
        callback?.()
    } catch (err) {
        dispatch(setCreateFileError("Something went wrong"))
    }
}

export const getFiles = (organization_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setListFilesLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.getFiles()
        dispatch(setListFilesSuccess(response.files))
    } catch (err) {
        dispatch(setListFilesError("Something went wrong"))
    }
}

export const getFile = (organization_id: string, id: string) => async (dispatch: AppDispatch) => {
    dispatch(setGetFileLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.getFile(id)
        dispatch(setGetFileSuccess(response))
    } catch (err) {
        dispatch(setGetFileError("Something went wrong"))
    }
}