export const SET_CREATE_INTEGRATIONS_LOADING = "integrations/create/loading";
export const SET_CREATE_INTEGRATIONS_SUCCESS = "integrations/create/success";
export const SET_CREATE_INTEGRATIONS_ERROR = "integrations/create/error";

export const SET_UPDATE_INTEGRATIONS_LOADING = "integrations/update/loading";
export const SET_UPDATE_INTEGRATIONS_SUCCESS = "integrations/update/success";
export const SET_UPDATE_INTEGRATIONS_ERROR = "integrations/update/error";

export const SET_DELETE_INTEGRATIONS_LOADING = "integrations/delete/loading";
export const SET_DELETE_INTEGRATIONS_SUCCESS = "integrations/delete/success";
export const SET_DELETE_INTEGRATIONS_ERROR = "integrations/delete/error";

export const SET_GET_INTEGRATIONS_LOADING = "integrations/get/loading";
export const SET_GET_INTEGRATIONS_SUCCESS = "integrations/get/success";
export const SET_GET_INTEGRATIONS_ERROR = "integrations/get/error";

export const SET_LIST_INTEGRATIONS_LOADING = "integrations/list/loading";
export const SET_LIST_INTEGRATIONS_SUCCESS = "integrations/list/success";
export const SET_LIST_INTEGRATIONS_ERROR = "integrations/list/error";
