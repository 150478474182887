import { Alert, AlertTitle, Flex, FormControl, FormErrorMessage, FormLabel, Stack, Text, useToast } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { Field, Form, Formik } from "formik";
import { CheckIcon, ChevronsUpDownIcon, PlugZapIcon, TrashIcon, Unlink2Icon } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { createIntegration, deleteIntegration, listIntegrations, updateIntegration } from "~/lib/app/integrations/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import { axios } from "~/utils/axios.loader";

const dropdownStyles: any = {
    dropdownIndicator: (b: any) => ({
      ...b,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: 0,
    }),
    control: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
    menuList: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
  };
  
  const dropdownComponents = {
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronsUpDownIcon size={12} />
      </chakraComponents.DropdownIndicator>
    ),
  };

export default function WhatsappForm ({ config, onClose }: { config: any, onClose?: any }) {
    const { organization } = useParams()
    const dispatch = useAppDispatch()
    const create = useAppSelector((state) => state.app.integrations.create);
    const update = useAppSelector((state) => state.app.integrations.update);
    const _delete = useAppSelector((state) => state.app.integrations.delete);
    const [setupConfig, setSetupConfig] = useState<any>()
    const [validation, setValidateState] = useState<string>("pending")
    const [error, setError] = useState<string>('')
    const toast = useToast()

    async function fetchConfigs (values: any) {
        setError('')
        setValidateState("loading")
        try {
            const request = await axios.post(`/organization/${organization}/integrations/configs/exotel`, {
                "api_key": values.apiKey,
                "api_token": values.apiToken,
                "sid": values.sid,
                "subdomain": values.subdomain,
                "wabid": values.wabaid
            })
            const response = request.data
            if (!response.numbers.length) {
                setValidateState("error")
                setError("Failed to validate credentials")
            } else {
                setSetupConfig(response)
                setValidateState("success")
            }
        } catch (err) {
            setValidateState("error")
            setError("Failed to validate credentials")
        }
    }

    return (
        <Formik onSubmit={(values) => {
            if (config?.id) {
                if (validation === "success") {
                    dispatch(updateIntegration(organization!, config.id, {
                        config: {
                            "name": values.name,
                            "config": {
                                "api_key": values.apiKey,
                                "api_token": values.apiToken,
                                "sid": values.sid,
                                "subdomain": values.subdomain,
                                "wabaid": values.wabaid,
                                "namespace": values.namespace,
                                "number": values.number,
                                "template": values.template
                            },
                            "category": "COMMUNICATIONS",
                            "organization_id": organization,
                            "provider": "EXOTEL"
                        }
                    }, () => {
                        dispatch(listIntegrations(organization!))
                        toast({
                            title: "Integration updated successfully",
                            status: "success"
                        })
                        onClose?.()
                    }))
                }
            } else {
                if (validation === "success") {
                    dispatch(createIntegration(organization!, {
                        config: {
                            "name": values.name,
                            "config": {
                                "api_key": values.apiKey,
                                "api_token": values.apiToken,
                                "sid": values.sid,
                                "subdomain": values.subdomain,
                                "wabaid": values.wabaid,
                                "namespace": values.namespace,
                                "number": values.number,
                                "template": values.template
                            },
                            "category": "COMMUNICATIONS",
                            "organization_id": organization,
                            "provider": "EXOTEL"
                        }
                    }, () => {
                        dispatch(listIntegrations(organization!))
                        toast({
                            title: "Integration created successfully",
                            status: "success"
                        })
                        onClose?.()
                    }))
                }
            }
        }} initialValues={{
            name: config?.name,
            apiKey: config?.config?.api_key,
            apiToken: config?.config?.api_token,
            sid: config?.config?.sid,
            namespace: config?.config?.namespace,
            subdomain: config?.config?.subdomain,
            wabaid: config?.config?.wabaid,
            number: config?.config?.number,
            template: config?.config?.template
        }}>
            {({errors, touched, setFieldValue, values}) => {
                return (<Form className="h-full">
                    <Stack h={"full"} justifyContent={"space-between"}>
                    <Stack flex={1} gap={4}>
                    {error.length ? <Alert py={2} mb={3} fontWeight={"medium"} borderRadius={6} variant={"solid"} status="error">
                        <AlertTitle>{error}</AlertTitle>
                    </Alert> : <></> }
                    <FormControl isInvalid={!!errors.name}>
                            <FormLabel fontSize={"sm"}>Name for your integration</FormLabel>
                            <Field name={"name"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "name is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your integration name" />
                            <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.apiKey}>
                            <FormLabel fontSize={"sm"}>API Key</FormLabel>
                            <Field name={"apiKey"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "API key is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your exotel api key" />
                            <FormErrorMessage>{errors.apiKey as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.apiToken}>
                            <FormLabel fontSize={"sm"}>API Token</FormLabel>
                            <Field name={"apiToken"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "API token is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your exotel api token" />
                            <FormErrorMessage>{errors.apiToken as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.sid}>
                            <FormLabel fontSize={"sm"}>SID</FormLabel>
                            <Field name={"sid"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "SID is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your exotel sid" />
                            <FormErrorMessage>{errors.sid as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.subdomain}>
                            <FormLabel fontSize={"sm"}>Sub Domain</FormLabel>
                            <Field name={"subdomain"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "Subdomain is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your exotel subdomain" />
                            <FormErrorMessage>{errors.subdomain as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.namespace}>
                            <FormLabel fontSize={"sm"}>Namespace</FormLabel>
                            <Field name={"namespace"} validate={(v: any) => {
                                let error;
                                if (!v) {
                                    error = "Namespace is required"
                                }
                                return error
                            }} as={Input} placeholder="Enter your exotel namespace" />
                            <FormErrorMessage>{errors.namespace as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.wabaid}>
                            <FormLabel fontSize={"sm"}>WABA ID</FormLabel>
                            <Field name={"wabaid"} validate={(v: any) => {
                                let error;
                                return error
                            }} as={Input} placeholder="Enter your exotel wabaid" />
                            <FormErrorMessage>{errors.wabaid as string}</FormErrorMessage>
                        </FormControl>
                    </Stack>
                    {validation === "success" ? <>
                        <FormControl isInvalid={!!errors.number}>
                            <FormLabel fontSize={"sm"}>
                              Choose Phone Number
                            </FormLabel>
                            <Field
                                as={Select}
                                name={"number"}
                                validate={(v: any) => {
                                    let error;
                                    if (!v) {
                                        error = "Phone number is required"
                                    }
                                    return error
                                }}
                              value={{
                                label: values.number || "",
                                value: values.number || "",
                              }}
                              options={setupConfig?.numbers?.map((n: any) => {
                                return {
                                    "label": n.phone_number,
                                    "value": n.phone_number
                                }
                              })}
                              onChange={(v: any) => {
                                setFieldValue("number", v.value)
                              }}
                              placeholder={"Select phone number"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                            <FormErrorMessage>{errors.number as string}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.template}>
                            <FormLabel fontSize={"sm"}>
                              Choose Template
                            </FormLabel>
                            <Field
                                as={Select}
                                name={"template"}
                                validate={(v: any) => {
                                    let error;
                                    if (!v) {
                                        error = "Template is required"
                                    }
                                    return error
                                }}
                              value={{
                                label: values.template || "",
                                value: values.number || "",
                              }}
                              options={setupConfig?.templates?.filter((b: any) => b?.data?.name).map((n: any) => {
                                return {
                                    "label": n.data.name,
                                    "value": n.data.name
                                }
                              })}
                              onChange={(v: any) => {
                                setFieldValue("template", v.value)
                              }}
                              placeholder={"Select template"}
                              selectedOptionColorScheme="primary"
                              size={"sm"}
                              variant={"outline"}
                              chakraStyles={dropdownStyles}
                              components={dropdownComponents}
                            />
                            <FormErrorMessage>{errors.template as string}</FormErrorMessage>
                        </FormControl>
                    </> : <></>}
                    <Flex justifyContent={"end"} gap={2} alignItems={"center"} h={12}>
                        <Button onClick={() => {
                            dispatch(deleteIntegration(organization!, config.id, () => {
                                dispatch(listIntegrations(organization!))
                                toast({
                                    title: "Integration deleted successfully",
                                    status: "info"
                                })
                                onClose?.()
                            }))
                        }} type="button" hidden={!config?.id} variant={"destructive"} isLoading={_delete.state === "loading"}>
                            <Unlink2Icon size={20} />
                            <Text>Disconnect</Text>
                        </Button>
                         {validation !== "success" ? <Button isLoading={validation === "loading"} type="submit" onClick={() => {
                            fetchConfigs(values)
                    }} variant={"dark"}>Validate Credentials</Button> : <Button isLoading={update.state === "loading" || create.state === "loading"}>
                            <PlugZapIcon size={20} />
                            <Text>{config?.id ? "Update connection" : "Connect account"}</Text>
                        </Button>}
                    </Flex>
                    </Stack>
                </Form>)
            }}
        </Formik>
    )
}