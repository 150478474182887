"use client"

import * as React from "react"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { type ColumnDef } from "@tanstack/react-table"
import { toast } from "sonner"

import { getErrorMessage } from "~/lib/handle-error"
import { formatDate } from "~/lib/utils"
import { Badge } from "~/components/ui/badge"
import { Button } from "~/components/ui/button"
import { Checkbox } from "~/components/ui/checkbox"
import { Box, HStack, IconButton, Skeleton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { PauseIcon, PlayIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu"
import { DataTableColumnHeader } from "~/components/data-table/data-table-column-header"
import { ZapIcon } from "lucide-react"
import _ from 'lodash'
import { useAppDispatch } from "~/lib/hooks"
import { rerunAnalytics } from "~/lib/app/logs/thunk"


const AudioPlayer = ({ url }: { url: string }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Update duration when metadata is loaded
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });

      // Update progress as the audio plays
      audio.addEventListener("timeupdate", () => {
        setProgress(audio.currentTime);
      });
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", () => {});
        audio.removeEventListener("timeupdate", () => {});
      }
    };
  }, []);

  const togglePlayPause = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSliderChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = value;
      setProgress(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <Box width="200px" bg="gray.100" borderRadius="lg">
      <audio ref={audioRef} src={url} />

      <HStack>
        <IconButton
        size={"xs"}
          aria-label={isPlaying ? "Pause" : "Play"}
          icon={isPlaying ? <PauseIcon size={12} /> : <PlayIcon size={12} />}
          onClick={togglePlayPause}
        />
        <Text>{formatTime(progress)}</Text>

        <Slider
          aria-label="audio-progress"
          value={progress}
          min={0}
          onClick={(e) => {
            e.stopPropagation()
          }}
          max={duration}
          onChange={handleSliderChange}
          flex="1"
          focusThumbOnChange={false}
        >
          <SliderTrack bg="gray.300">
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text>{formatTime(duration)}</Text>
      </HStack>
    </Box>
  );
};

export function getColumns(): ColumnDef<any>[] {

  return [
    {
      id: "serial",
      accessorKey: "serial",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="w-10">{row.getValue("serial")}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      enableSorting: false,
      enableHiding: false,
      cell: ({ row }) => {
        const meta: any = row.getValue("meta")

        return (
          <div className="flex space-x-2">
            {meta.type ? <Badge variant="outline">{meta.type}</Badge> : <></>}
          </div>
        )
      },
    },
    {
      accessorKey: "state",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const state: string = row.getValue("state")
        return (
          <div className="flex w-[6.25rem] items-center">
            
            <span className="capitalize">{state}</span>
          </div>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "customer",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer ID" />
      ),
      cell: ({ row }) => {
        const meta: any = row.getValue("meta")

        // if (!priority) return null

        // const Icon = getPriorityIcon(priority)

        return (
          <div className="flex items-center">
            {/* <Icon
              className="mr-2 size-4 text-muted-foreground"
              aria-hidden="true"
            /> */}
            <span className="capitalize">{meta?.customer?.number}</span>
          </div>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "sentiment",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Sentiment" />
      ),
      cell: ({ row }) => {
        const sentiment: string = row.getValue("sentiment")
        
        // if (!priority) return null

        // const Icon = getPriorityIcon(priority)

        return (
          <Badge
            variant="transparent"
            className={"text-sm text-medium gap-2"}
          >
            {sentiment === "positive" ? (
              <ZapIcon size={16} fill="#22c55e" stroke={"#22c55e"} />
            ) : (
              <div
                className={`w-2.5 h-2.5 rounded-sm ${
                  sentiment === "positive"
                    ? "bg-green-500"
                    : sentiment === "neutral"
                    ? "bg-blue-500"
                    : sentiment === "negative"
                    ? "bg-red-500"
                    : "bg-slate-500"
                }`}
              />
            )}
            {_.capitalize(sentiment || "Unknown")}
          </Badge>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "result",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Result" />
      ),
      cell: ({ row }) => {
        const result: string = row.getValue("result")

        return (
          <Badge variant="outline">{result || "Unknown"}</Badge>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      id: "agent",
      accessorKey: "agent",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Agent" />
      ),
      cell: ({ row }) => {
        const agent: string = row.getValue("agent")

        return (
          <Badge variant="outline">{agent}</Badge>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "meta",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Recording" />
      ),
      cell: ({ row }) => {
        const meta: any = row.getValue("meta")

        // if (!priority) return null

        // const Icon = getPriorityIcon(priority)

        return (
          <AudioPlayer url={meta?.recordingUrl } />
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created At" />
      ),
      cell: ({ cell }) => formatDate(cell.getValue() as Date),
    },
    {
      id: "actions",
      cell: function Cell({ row }) {
        const dispatch = useAppDispatch()
        const toast = useToast()
        const [isUpdatePending, startUpdateTransition] = React.useTransition()
        const [showUpdateTaskSheet, setShowUpdateTaskSheet] =
          React.useState(false)
        const [showDeleteTaskDialog, setShowDeleteTaskDialog] =
          React.useState(false)
        const payload: any = row.original
        const agent: string = payload["agent_id"]
        const id: string = payload["id"]

        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  aria-label="Open menu"
                  variant="ghost"
                  className="flex size-8 p-0 data-[state=open]:bg-muted"
                >
                  <DotsHorizontalIcon className="size-4" aria-hidden="true" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-40">
                <DropdownMenuItem onSelect={(e) => {
                  e.stopPropagation()
                  dispatch(rerunAnalytics(id, agent))
                  toast({
                    title: "Analytics retriggered",
                    status: "info"
                  })
                }}>
                  <PlayIcon size={16} className="mr-2" /> Replay Analytics
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        )
      },
      size: 40,
    },
  ]
}
