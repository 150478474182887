import { useParams, useSearchParams } from "react-router-dom";
import Demo from "./Demo";

export default function Start () {
    const [params]  = useSearchParams()

    return (
        <Demo metadata={{
            ...(Object.fromEntries(params.entries() || new Map()))
        }} />
    )
}