import { Box, Center, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Heading, IconButton, Spinner, Stack, Text, Textarea, useDisclosure } from "@chakra-ui/react";
import { parse } from "best-effort-json-parser";
import _ from "lodash";
import { CheckCircle2Icon, SendIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import { axios } from "~/utils/axios.loader";

export default function Chat(props: { id: string, onSetFeedback?: (fn: any) => void, onSetCallbackProps?: (fn: any) => void }) {
  const dispatch = useAppDispatch();
  const startsWithValidJSON = useRef<boolean>(false);
  const [messages, setMessages] = useState<
    {
      role?: string;
      content?: string;
      type?: string;
    }[]
  >([]);
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const session = useRef<string | boolean | null>(null);
  const [text, setText] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const socket = useRef<WebSocket | null>(null);
  const [message, setCurrentMessage] = useState<string>("");
  const messageRef = useRef<string>("");
  const jsonRef = useRef<{
    output?: string;
    status?: string;
    feedback_summary?: string;
  }>({});
  const chunk = useRef<any[]>([]);
  const stream = useRef<string[]>([]);
  const stack = useRef<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const endCursor = useRef<boolean>(false);
  const navigate = useNavigate();
  const [displayHeader, setDisplayHeader] = useState(false);
  const {
    isOpen: isChatOpen,
    onOpen: onChatOpen,
    onClose: onChatClose,
  } = useDisclosure();
  const create = useAppSelector((state) => state.app.feedback.create);
  const update = useAppSelector((state) => state.app.feedback.update);

  const [history, setHistory] = useState<
    { role: string; content?: string; metadata?: any; image?: string }[]
  >([
    {
      role: "assistant",
      content: "Hi there",
    },
  ]);

  const sanitiseJSON = (object: any, target: any = {}) => {
    const keys = Object.keys(object || {});
    for (const key of keys) {
      const _parsed = key.replace(/"/g, "").replace(/\n/g, "");
      if (_parsed.length) {
        const value = object[key];
        if (Array.isArray(value)) {
          target[_parsed] = value.map((item) =>
            typeof item === "object" ? sanitiseJSON(item, {}) : item
          );
        } else if (typeof value === "object" && value !== null) {
          target[_parsed] = sanitiseJSON(value, {});
        } else if (value !== undefined && value !== null) {
          if (
            ["target", "current_value"].includes(_parsed) &&
            typeof value === "number"
          ) {
            target[_parsed] = `${value}`;
          } else {
            target[_parsed] = value;
          }
        }
      }
    }
    return Array.isArray(object) ? Object.values(target) : target;
  };

  const saveSessionDetails = useCallback(
    async (data: any, feedback: any) => {
      const payload = {
        id: session.current,
        status: data.status,
        feedback: data.feedback_summary,
      };
      console.info("Updated feedback payload", payload);
      try {
        await axios.put(`/agent/update-feedback/${props.id}`, payload);
        session.current = "";
        //   dispatch(getFeedbackByAssistant(props.id!))
        onChatClose();
      } catch (e) {
        console.error("Failed to update: ", e);
      }
    },
    [session]
  );
  const [isReadOnly, setReadOnly] = useState(false);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (props.onSetFeedback) {
      props.onSetFeedback?.(() => {
        initiateConnection()
        onChatOpen()
      })
    }
  }, [])

  useEffect(() => {
    props?.onSetCallbackProps?.({
      onChatOpen,
      setMessages,
      setReadOnly,
      setLoading
    })
  }, [])

  const initiateConnection = useCallback(() => {
    if (!socket.current) {
      // todo: replace this with actual selected agent id
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_URL}/agent/${props.id}/start-agent-feedback-conversation`,
        cookies.accessToken
      );
      socket.current.addEventListener("open", () => {
        socket.current?.send(
          JSON.stringify({
            user_input: "Hi",
            response_type: "TEXT",
          })
        );
      });
      socket.current.addEventListener("message", (e) => {
        setLoading(false);
        let isValidChunk = true;
        let _chunk = e.data.toString();
        if (_chunk.trim().endsWith("\\")) {
          endCursor.current = true;
        }
        if (_chunk.includes("<eof>") && messageRef.current) {
          const a = messageRef.current;
          setCurrentMessage("");
          console.log(a);
          setMessages((m) => [
            ...m,
            {
              role: "ai",
              content: a,
            },
          ]);
          messageRef.current = "";
          chunk.current = [];
          stream.current = [];
          endCursor.current = false;
        } else if (
          _chunk.includes("<eof>") &&
          jsonRef.current?.status === "SUBMITTED"
        ) {
          setReadOnly(true);
          const clone = _.cloneDeep(jsonRef.current);
          saveSessionDetails(clone, session.current);
          jsonRef.current = {};
          endCursor.current = false;
        } else if (!_chunk.includes("<eof>")) {
          try {
            if (_chunk.trim() === '""') {
              throw Error("Skipping this as this is not a valid chunk");
            }
            const _parsed = JSON.parse(_chunk);
            if (_parsed.feedback_response) {
              session.current = JSON.parse(_parsed.feedback_response).id;
              stream.current = [];
              chunk.current = [];
              setLoading(true);
            }
          } catch (er) {
            if (_chunk.includes("{") && !startsWithValidJSON.current) {
              startsWithValidJSON.current = true;
            }
            if (!startsWithValidJSON.current) {
              const _parsedChunk =
                _chunk.trim() === '""'
                  ? '""'
                  : _chunk
                      .replaceAll("`", "")
                      .replaceAll("}", "")
                      .replaceAll("{", "")
                      .replaceAll('"', "");
              messageRef.current += _parsedChunk;
              setCurrentMessage((m) => messageRef.current);
            } else {
              chunk.current.push(_chunk);
              stack.current.push(_chunk);
              const joined = chunk.current!.join("");
              const _parsed = (
                endCursor.current && joined === "n" ? `\\${joined}` : joined
              )
                .replaceAll("/\\+\n/g", "\n")
                .replaceAll(/\\\\*"/g, '"')
                .replaceAll(/\\(?!n)/g, "")
                .replaceAll(/\\\\(?!n)/g, "")
                .replaceAll('"{', "{")
                .replaceAll('}"', "}");
              stream.current.push(_parsed);
              try {
                const parsedJson = parse(
                  _parsed.replaceAll('}"', "}").replaceAll('"{', "}")
                );
                setCurrentMessage(() => {
                  const merged = sanitiseJSON(parsedJson);
                  messageRef.current = merged.output;
                  jsonRef.current = {
                    output: merged.output,
                    status: merged.status,
                    feedback_summary: merged.feedback_summary,
                  };
                  return merged.output;
                });
              } catch (e) {
                const parsedJson = parse(
                  _parsed.replaceAll('}"', "}").replaceAll('"{', "}")
                );
                setCurrentMessage(() => {
                  const merged = sanitiseJSON(parsedJson);
                  messageRef.current = merged.output;
                  jsonRef.current = {
                    output: merged.output,
                    status: merged.status,
                    feedback_summary: merged.feedback_summary,
                  };
                  return merged.output;
                });
              }
            }
          }
        }
      });
    }
  }, [cookies, message, history]);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      scrollContainer.current?.scrollTo({
        top: scrollContainer.current.scrollHeight,
      });
      // if (!displayHeader && scrollContainer.current) {
      //   setDisplayHeader(
      //     scrollContainer.current!.offsetHeight + 120 <
      //     scrollContainer.current!.scrollHeight
      //   );
      // }
    }, 0);
  }, [scrollContainer, displayHeader]);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
    }
  }, [messages, scrollContainer]);

  useEffect(() => {
    scrollToBottom();
  }, [history, scrollToBottom, displayHeader, loading, message]);

  return (
    <Box>
      <Drawer size={"md"} isOpen={isChatOpen} onClose={() => {
        onChatClose()
        if (socket.current) {
          socket.current.close()
          socket.current = null;
        }
        chunk.current = []
        stream.current = []
        stack.current = []
        messageRef.current = ''
        setCurrentMessage('')
        setMessages([])
        setReadOnly(false)
      }}>
        <DrawerOverlay
         background={"transparent"}
          style={{
            backdropFilter: "blur(4px)",
          }}
        >
        </DrawerOverlay>
        <DrawerContent borderLeft={"1px solid #e4e4e4"} boxShadow={0} overflow='hidden'>
        <DrawerHeader alignItems={"center"}>
          <Heading size={"md"}>Enter your feedback</Heading>
          <DrawerCloseButton top={2.5} />
        </DrawerHeader>
        <DrawerBody overflow={"scroll"}>
          <Stack h={"calc(100vh - 90px)"} justifyContent={"space-between"}>
            <Box 
          ref={scrollContainer} mt={6} overflow={"scroll"} maxH={"calc(100vh - 84px)"} gap={6} flex={0.95}>
            {messages.map((m: any) => {
                return (<Flex  mb={4} justifyContent={m.role === "ai" ? "start" : "end"}>
                  <Box
                pointerEvents={m.isReported ? "none" : "all"}
                py={m.role === "ai" || m.role === "assistant" || m.role === "system" ? 4 : 3}
                maxW={"85%"}
                px={4}
                position={"relative"}
                borderRadius={8}
                color={
                  m.role === "ai" || m.role === "assistant" || m.role === "system" ? "black" : "white"
                }
                backgroundColor={
                  m.role === "ai" || m.role === "assistant" || m.role === "system"
                    ? "#F2F8FF"
                    : "#5452F6"
                }
              >
                <Text
                fontWeight={500}>
                {m.content.replaceAll("/\\+\n/g", " \n ")}
                </Text>
                </Box>
                </Flex>)
              })}
              {loading ? <Stack>
                <Spinner size={"sm"} />
              </Stack> : <></>}
              {message ? <Flex  mb={4} justifyContent={"start"}>
                  <Box
                py={4}
                maxW={"85%"}
                px={4}
                position={"relative"}
                borderRadius={8}
                color={
                 "black" 
                }
                backgroundColor={"#F2F8FF"}
              >
                <Text
                fontWeight={500}>
                {message.replaceAll("/\\+\n/g", " \n ")}
                </Text>
                </Box>
                </Flex> : <></>
              }
            </Box>
            {!isReadOnly ? <Flex p={3} pl={0} justifyContent={"space-between"} gap={4} rounded={8} border={"1px solid #cacaca"}>
              <Textarea onKeyDown={(e) => {
                if ((e.key === "Enter" || e.keyCode === 13)) {
                  e.preventDefault()
                  stream.current = [];
                  if (text?.length) {
                    // @ts-ignore
                    setMessages((m) => [
                      ...m,
                      {
                        role: "user",
                        // @ts-ignore
                        content: e.target.value,
                      },
                    ]);
                    setLoading(true);
                    stream.current = [];
                    chunk.current = [];
                    messageRef.current = ''
                    socket.current!.send(
                      JSON.stringify({
                        user_input: text.replaceAll("\n", " "),
                        response_type: "TEXT"
                      })
                    );
                    setText('')
                  }
                }
              }} value={text} onChange={(v) => {
                setText(v.target.value)
              }} placeholder="Enter your feedback" rows={4} resize={"none"} border={0} outline={0} boxShadow={0} _focusVisible={{ boxShadow: 0, outline: 0, border: 0 }}></Textarea>
              <Stack>
                <IconButton onClick={() => {
                  // if (text.trim()) {
                    setLoading(true)
                    setMessages((m) => [
                      ...m,
                      {
                        role: "user",
                        content: text
                      }
                    ])
                    stream.current = [];
                    chunk.current = [];
                    jsonRef.current = {}
                    messageRef.current = ''
                    socket.current!.send(
                      JSON.stringify({
                        user_input: text,
                        response_type: "TEXT"
                      })
                    );
                    // if (currentMessage?.id) {
                    //   dispatch(updateFeedback(props.id, currentMessage.id, {
                    //     feedback: `${currentMessage.feedback}\n${text}`,
                    //     organization_id: organization.data.id
                    //   }, () => {
                    //     dispatch(getFeedbackByAssistant(props.id));
                    //   }))
                    // } else {
                    //   dispatch(createFeedback(props.id, {
                    //     feedback: text,
                    //     organization_id: organization.data.id
                    //   }, () => {
                    //     dispatch(getFeedbackByAssistant(props.id));
                    //   }))
                    // }
                    setText('')
                  // }
                }} isDisabled={create.state === "loading" || update.state === "loading" || loading} colorScheme="primary" aria-label="" icon={<SendIcon size={16} />} />
              </Stack>
            </Flex> : <Box>
              
            <Divider /><Center h={100}>
              <Stack alignItems={"center"}>
                <CheckCircle2Icon color="green" />
                <Text>Your feedback has been submitted</Text>
                <Text>Please start another session to submit new feedback</Text>
              </Stack>
              </Center></Box>}
          </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
