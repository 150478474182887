import { BaseObjectState, BaseArrayState } from "../../types";
import { 
    SET_LOGS_LIST_LOADING,
    SET_LOGS_LIST_SUCCESS,
    SET_LOGS_LIST_ERROR,
    SET_LOGS_GET_LOADING,
    SET_LOGS_GET_SUCCESS,
    SET_LOGS_GET_ERROR
} from "./constants";

interface LogsState {
    list: BaseArrayState,
    get: BaseObjectState
}

const INITIAL_STATE: LogsState = {
    list: {
        state: "loading",
        data: [],
        error: {}
    },
    get: {
        state: "loading",
        data: {},
        error: {}
    }
}

export const LogsReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_LOGS_LIST_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: '',
                    metadata: {}
                }
            }
        case SET_LOGS_LIST_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: '',
                    metadata: action.metadata
                }
            }
        case SET_LOGS_LIST_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong',
                    metadata: action.metadata || {}
                }
            }
        case SET_LOGS_GET_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_LOGS_GET_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_LOGS_GET_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
