import { Box, Divider, Flex, Heading, IconButton, Skeleton, Stack, Text, Button as CoreButton, Drawer, useDisclosure, DrawerOverlay, DrawerContent, DrawerBody, DrawerHeader, CloseButton, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { CheckCircle2Icon, CheckCircleIcon, CheckIcon, DownloadIcon, MoreVertical, MoreVerticalIcon, PlayIcon, ZapIcon } from "lucide-react";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { getTaskRun, listTaskRunsByAssistant, triggerTaskRun } from "~/lib/app/task_runs/thunk";
import { listTasks } from "~/lib/app/tasks/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import Markdown from 'react-markdown'

export default function Tasks() {
    const tasks = useAppSelector(state => state.app.tasks.list)
    const task_runs = useAppSelector(state => state.app.task_runs.list)
    const task_run = useAppSelector(state => state.app.task_runs.get)
    const dispatch = useAppDispatch()
    const { organization, assistant } = useParams()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const create_run = useAppSelector((state) => state.app.task_runs.create);

    useEffect(() => {
        if (organization && assistant) {
            dispatch(listTasks(organization, assistant))
        }
    }, [organization, assistant])

    useEffect(() => {
        if (organization && assistant) {
            dispatch(listTaskRunsByAssistant(organization, assistant, {}))
        }
    }, [organization, assistant])


    return (
        <Stack gap={4}>
            <Drawer size={"lg"} isOpen={isOpen} onClose={() => {
                onClose()
            }}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader justifyContent={"space-between"} display={"flex"} alignItems={"center"}>
                        <Heading size={"md"}>{task_run.data?.task_config?.topic}</Heading>
                        <Flex alignItems={"center"} gap={2}>
                        <CoreButton
                        size={"xs"}
                        isDisabled={!task_run.data?.task_output?.file}
                            onClick={(e) => {
                                e.preventDefault()
                                window.open(`${task_run.data?.task_output?.file}`, "_blank")
                            }}
                            aria-label=""
                            colorScheme="black"
                            bg={"#212121"}
                            leftIcon={<DownloadIcon size={14} />}
                        >Download Report </CoreButton>
                        <CloseButton onClick={() => {
                            onClose()
                        }} />
                        </Flex>
                    </DrawerHeader>
                    <Divider />
                    <DrawerBody>
                        <Stack>
                            <Markdown className={"markdown"}>{task_run.data?.task_output?.report}</Markdown>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        <Stack gap={4} mt={4}>
            <Box>
            <Heading size={"md"}>Your topics</Heading>
            <Text className="text-muted-foreground text-sm">Topics that you want to research</Text>
            </Box>
            <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
                <TableHeader className="!bg-slate-50">
                <TableRow>
                    <TableHead className="font-semibold">Name</TableHead>
                    <TableHead className="font-semibold">
                    Status
                    </TableHead>
                    <TableHead className="font-semibold">Last Run At</TableHead>
                    <TableHead className="font-semibold text-right pr-6">
                    Actions
                    </TableHead>
                </TableRow>
                </TableHeader>
                <TableBody>
                {tasks.data.map((run: any) => {
                    return (
                    <TableRow
                        className="cursor-pointer"
                        onClick={() => {
                        // setAssistantId(call.id)
                        // onOpen()
                        }}
                    >
                        <TableCell className="font-medium border-b">
                        {run.title}
                        </TableCell>
                        {/* <TableCell colSpan={2}  className="border-b">{<Badge variant={generateCanonicalVariants(call.endedReason) as any}>{generateCanonicalNames(call.endedReason)}</Badge>}</TableCell> */}
                        <TableCell className="font-semibold border-b">
                        {<Badge
                            className={"text-sm font-medium gap-2"}
                            variant="transparent"
                            >
                            {run.status === "COMPLETED" ? (
                                <CheckCircle2Icon stroke="#4ade80" strokeWidth={1.5} size={16}  />
                            ) : (
                                <div
                                className={`w-2.5 h-2.5 rounded-sm ${
                                    run.status === "RUNNING"
                                    ? "bg-blue-500"
                                    : run.status === "FAILED"
                                    ? "bg-red-500"
                                    : run.status === "UPCOMING"
                                    ? "bg-yellow-500"
                                    : "bg-gray-500"
                                }`}
                                />
                            )}
                            {_.capitalize(run.status)}
                            </Badge>}
                        </TableCell>
                        <TableCell className="border-b">
                            {moment(run.created_at).format("DD, MMM, YYYY hh:mm A")}
                        </TableCell>
                        <TableCell className="text-right pr-6 border-b">
                        <Flex justifyContent={"end"} alignItems={"center"} gap={2}>
                            <CoreButton isLoading={create_run.state === "loading"} onClick={() => {
                                dispatch(triggerTaskRun(organization!, run.id, () => {
                                    dispatch(listTaskRunsByAssistant(organization!, assistant!, {}))
                                    toast({
                                        title: "Research started",
                                        description: "You will recieve an email when the report is ready",
                                        status: "success",
    
                                    })

                                }))
                            }} leftIcon={<PlayIcon fill="#4ade80" stroke="0" size={16} />} size={"xs"} colorScheme="black" bg={"#212121"} >Run</CoreButton>
                            <IconButton
                                onClick={() => {
                                // window.open(`${process.env.REACT_APP_API_V2_URL}/agent/organization/${organization.data.id}/conversations/${log.data.id}/recording`, "_blank")
                                }}
                                variant={"outline"}
                                size={"xs"}
                                aria-label=""
                                icon={<MoreVerticalIcon size={16} />}
                            />
                        </Flex>
                        </TableCell>
                    </TableRow>
                    );
                })}
                {tasks.state === "loading" ? (
                    <>
                    {[...Array(18)].map((e: any) => {
                        return (
                        <TableRow>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    </>
                ) : (
                    <></>
                )}
                </TableBody>
            </Table>
        </Stack>
        <Divider />
        <Stack gap={4} mt={4}>
            <Box>
            <Heading size={"md"}>Recent reports</Heading>
            <Text className="text-muted-foreground text-sm">Reports generated from the above topics. Click on the report to view detailed analysis</Text>
            </Box>
            <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
                <TableHeader className="!bg-slate-50">
                <TableRow>
                    <TableHead className="font-semibold">Topic</TableHead>
                    <TableHead className="font-semibold">Status</TableHead>
                    <TableHead className="font-semibold">Created At</TableHead>
                    <TableHead className="font-semibold text-right pr-6">
                    Actions
                    </TableHead>
                </TableRow>
                </TableHeader>
                <TableBody>
                {task_runs.data.map((run: any) => {
                    return (
                    <TableRow
                        className="cursor-pointer"
                        onClick={() => {
                            dispatch(getTaskRun(organization!, run.task_id, run.id))
                            onOpen()
                        }}
                    >
                        <TableCell className="font-semibold border-b">
                        {run.task_config?.topic}
                        </TableCell>
                        <TableCell className="border-b">{
                            <Badge
                            className={"text-sm font-medium gap-2"}
                            variant="transparent"
                            >
                            {run.status === "COMPLETED" ? (
                                <CheckCircle2Icon stroke="#4ade80" strokeWidth={1.5} size={16}  />
                            ) : (
                                <div
                                className={`w-2.5 h-2.5 rounded-sm ${
                                    run.status === "RUNNING"
                                    ? "bg-blue-500"
                                    : run.status === "FAILED"
                                    ? "bg-red-500"
                                    : run.status === "UPCOMING"
                                    ? "bg-yellow-500"
                                    : "bg-gray-500"
                                }`}
                                />
                            )}
                            {_.capitalize(run.status)}
                            </Badge>
                        }</TableCell>
                        {/* <TableCell colSpan={2}  className="border-b">{<Badge variant={generateCanonicalVariants(call.endedReason) as any}>{generateCanonicalNames(call.endedReason)}</Badge>}</TableCell> */}
                        <TableCell className="border-b">
                            {moment(run.created_at).format("DD MMM, YYYY")}
                        </TableCell>
                        <TableCell className="text-right pr-6 border-b">
                        <CoreButton
                        size={"sm"}
                        isDisabled={!run?.task_output?.file}
                            onClick={(e) => {
                                e.preventDefault()
                                window.open(`${run?.task_output?.file}`, "_blank")
                            }}
                            variant={"outline"}
                            aria-label=""
                            leftIcon={<DownloadIcon size={14} />}
                        >Download Report </CoreButton>
                        </TableCell>
                    </TableRow>
                    );
                })}
                {task_runs.state === "loading" ? (
                    <>
                    {[...Array(18)].map((e: any) => {
                        return (
                        <TableRow>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                            <TableCell>
                            <Skeleton h={4} rounded={8} />
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    </>
                ) : (
                    <></>
                )}
                </TableBody>
            </Table>
        </Stack>
        </Stack>
    );
}
