import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  TabProps,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import { loadUserProfile } from "~/lib/app/platform/thunk";
import {
  ArrowUpRightIcon,
  BoxIcon,
  BrainIcon,
  CodeIcon,
  DeleteIcon,
  FlaskConicalIcon,
  GoalIcon,
  HandshakeIcon,
  HeadsetIcon,
  LayoutDashboardIcon,
  MoreVerticalIcon,
  PencilIcon,
  PhoneCallIcon,
  PlusIcon,
  PresentationIcon,
  Trash2Icon,
  TrashIcon,
  Users2Icon,
} from "lucide-react";
import { useCookies } from "react-cookie";
import _ from "lodash";
import { Button } from "~/components/ui/button";
import { HomeSidebar } from "~/@components/SidebarV2";
import {
  getOrganization,
  listOrganizations,
} from "~/lib/app/organization/thunk";
import { createOrganizationTeams, deleteOrganizationTeams, getOrganizationTeams, updateOrganizationTeams } from "~/lib/app/teams/thunk";
import moment from "moment-timezone";
import { Field, Form, Formik } from "formik";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Textarea } from "~/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSeparator, MenubarTrigger } from "~/components/ui/menubar";

interface TabItemProps extends TabProps {
  icon?: ReactNode;
}

export function TabItem(props: TabItemProps) {
  return (
    <Flex h={10} align={"center"} gap={2}>
      {props.icon}
      <Text fontWeight={"semibold"} fontSize={14}>
        {props.title}
      </Text>
    </Flex>
  );
}
interface TeamCardProps {
  icon?: ReactElement;
  title: string;
  subtitle?: string;
  total?: number;
  url?: string;
  color?: string;
  bgIcon?: ReactElement;
  id: string,
  onItemClick?: () => void,
  onDeleteClick?: () => void
}

function TeamCard({
  icon,
  title,
  id,
  bgIcon,
  subtitle,
  total,
  url,
  color,
  onItemClick,
  onDeleteClick
}: TeamCardProps) {
  const { organization } = useParams();
  const navigate = useNavigate();

  return (
    <Stack
      p={6}
      overflow={"hidden"}
      pos={"relative"}
      className="border"
      rounded={"2xl"}
    >
      <Flex justifyContent={"space-between"}>
        <Box>
        <Box right={-10} top={-4} pos={"absolute"}>
        {bgIcon}
      </Box>
      <IconButton
        aria-label=""
        rounded={"full"}
        icon={icon}
        w={"fit-content"}
        size={"lg"}
      />
        </Box>
        <Box>
        </Box>
      </Flex>
      <Box>
        <Text fontSize={"lg"} fontWeight={"semibold"}>
          {title}
        </Text>
        <Text className="text-muted-foreground" fontSize={"sm"}>
          {subtitle}
        </Text>
      </Box>
      <Divider />
      <Heading fontSize={48} fontWeight={"bold"}>
        {total}
      </Heading>
      <Text mt={-2}>agents</Text>
      <Flex direction={"row-reverse"} alignItems={"center"} mt={1} gap={2} justifyContent={"end"}>

      <Menubar className="p-0">
  <MenubarMenu>
    <MenubarTrigger className="px-2">
    <MoreVerticalIcon size={20} />
    </MenubarTrigger>
    <MenubarContent>
      <MenubarItem onClick={() => {
        onItemClick?.()
      }}>
      <PencilIcon size={16} /><Text ml={2}>Edit Team</Text>
      </MenubarItem>
      <MenubarSeparator />
      <MenubarItem onClick={() => {
        onDeleteClick?.()
      }} className="text-red-500"> <Trash2Icon size={16} /><Text ml={2}>Delete</Text></MenubarItem>
    </MenubarContent>
  </MenubarMenu>
</Menubar>
        <Button
          onClick={() => {
            navigate(`/app/${organization}/team/${id}/assistants`);
          }}
          style={{
            background: "inherit",
          }}
          variant={"outlineHover"}
          className="font-bold"
        >
          View Team <ArrowUpRightIcon size={20} />
        </Button>
      </Flex>
    </Stack>
  );
}

function getGreetingTime() {
  var g = null; //return g

  var split_afternoon = 12; //24hr time to split the afternoon
  var split_evening = 17; //24hr time to split the evening
  var currentHour = parseFloat(moment().format("HH"));
  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = "afternoon";
  } else if (currentHour >= split_evening) {
    g = "evening";
  } else {
    g = "morning";
  }

  return g;
}

export default function Dashboard() {
  const profile = useAppSelector((state) => state.app.platform.profile.data);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);
  const { organization } = useParams();
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const organizations = useAppSelector((state) => state.app.organizations.list);
  const teams = useAppSelector((state) => state.app.teams.list);
  const create = useAppSelector((state) => state.app.teams.create);
  const update = useAppSelector((state) => state.app.teams.update);
  const _delete = useAppSelector((state) => state.app.teams.delete);
  const [currentTeam, setCurrentTeam] = useState<any>()
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure();
  const toast = useToast()
  const iconColorMapping: {
    [key: string]: {
      color: string;
      icon: ReactElement;
      bgIcon: ReactElement;
    };
  } = {
    SALES: {
      color: "#fce7f3",
      bgIcon: <PhoneCallIcon size={120} color={"#fce7f3"} strokeWidth={2.5} />,
      icon: <PhoneCallIcon size={20} />,
    },
    RESEARCH: {
      color: "#fae8ff",
      bgIcon: <BrainIcon size={120} color={"#fae8ff"} strokeWidth={2.5} />,
      icon: <BrainIcon size={20} />,
    },
    MARKETING: {
      color: "#dbeafe",
      bgIcon: (
        <PresentationIcon size={120} color={"#dbeafe"} strokeWidth={2.5} />
      ),
      icon: <PresentationIcon size={20} />,
    },
    SUPPORT: {
      color: "#e0e7ff",
      bgIcon: <HeadsetIcon size={120} color={"#e0e7ff"} strokeWidth={2.5} />,
      icon: <HeadsetIcon size={20} />,
    },
    RECRUITMENT: {
      color: "#99f6e4",
      bgIcon: <Users2Icon size={120} color={"#99f6e4"} strokeWidth={2.5} />,
      icon: <Users2Icon size={20} />,
    },
  };

  useEffect(() => {
    // if (_organization?.data?.providers?.vapi?.api_key) {
    //     const start = moment().startOf("month").toDate().toISOString()
    //     const end = moment().endOf("month").toDate().toISOString()
    //     dispatch(loadMetrics(_organization.data.id, start, end))
    // }
  }, [_organization]);


  return (
    <Stack flex={1}>
      <Modal size={"lg"} isCentered isOpen={isDeleteOpen} onClose={() => {
        setCurrentTeam(null)
        onDeleteClose()
      }}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader pb={0}>Delete Team</ModalHeader>
        <ModalBody>
          Are you sure you want to delete team <strong>{currentTeam?.name}</strong> ? 
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={() => {
             setCurrentTeam(null)
             onDeleteClose()
          }} variant={"outline"}>Cancel</Button>
          <Button isLoading={_delete.state === "loading"} onClick={() => {
            dispatch(deleteOrganizationTeams(organization!, currentTeam?.id, () => {
              setCurrentTeam(null)
              onDeleteClose()
              dispatch(getOrganizationTeams(organization!))
              toast({
                title: "Team deleted successfully",
                status: "success"
              })
            }))
          }} variant={"destructive"}>Yes, Delete</Button>
        </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size={"xl"} isCentered isOpen={isOpen} onClose={() => {
        setCurrentTeam(null)
        onClose()
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>{currentTeam ? `Update ${currentTeam?.name}` : "Create your team"}</ModalHeader>
          <ModalBody pb={6}>
            <Formik
              onSubmit={(values) => {
                if (!currentTeam) {
                  dispatch(createOrganizationTeams(organization!, values, () => {
                    dispatch(getOrganizationTeams(organization!))
                    toast({
                      title: "Team created successfully",
                      status: "success"
                    })
                  }));
                  setCurrentTeam(null)
                  onClose()
                } else {
                  dispatch(updateOrganizationTeams(organization!, currentTeam?.id, values, () => {
                      dispatch(getOrganizationTeams(organization!))
                      toast({
                        title: "Team updated successfully",
                        status: "success"
                      })
                      setCurrentTeam(null)
                      onClose()
                  }))
                }
              }}
              initialValues={{
                name: currentTeam?.name || "",
                type: currentTeam?.type || "RESEARCH",
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form
                  style={{
                    width: "100%",
                  }}
                  onChange={() => {
                   
                  }}
                >
                  <Stack gap={4} mt={4}>
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel fontWeight={500} fontSize={"sm"}>
                        Name your team{" "}
                        <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip>
                      </FormLabel>
                      <Field
                        name="name"
                        fontWeight={500}
                        value={values.name}
                        background
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Team name is required";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Sales team"
                      />
                      <FormErrorMessage fontWeight={500}>
                        {errors.name as string}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.type}>
                      <FormLabel fontSize={"sm"}>
                        Choose Team Type{" "}
                      </FormLabel>
                      <Grid gap={3} gridTemplateColumns={'repeat(3, 1fr)'}>
                        <Flex onClick={() => {
                          setFieldValue("type", "SALES")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer rounded-md transition-all duration-300 ${values.type === "SALES" ? "bg-primary text-white" : 'hover:bg-blue-50/50'}`} alignItems={"center"}>
                          <Box>
                          <PhoneCallIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Sales</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "RESEARCH")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer rounded-md transition-all duration-300 ${values.type === "RESEARCH" ? "bg-primary text-white" : 'hover:bg-blue-50/50'}`} alignItems={"center"}>
                          <Box>
                          <BrainIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Research</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "ENGINEERING")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "ENGINEERING" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <BoxIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Engineering</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "MARKETING")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "MARKETING" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <PresentationIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Marketing</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "OPERATIONS")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "OPERATIONS" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <GoalIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Operations</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "TESTING")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "TESTING" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <FlaskConicalIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Testing</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "SUPPORT")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "SUPPORT" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <HandshakeIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Support</Text>
                        </Flex>
                        <Flex onClick={() => {
                          setFieldValue("type", "OTHER")
                        }} gap={2} px={3} py={2} className={`border cursor-pointer transition- rounded-md transition-all duration-300 ${values.type === "OTHER" ? "bg-primary text-white" : "hover:bg-blue-50/50"}`} alignItems={"center"}>
                          <Box>
                          <LayoutDashboardIcon size={16} />
                          </Box>
                          <Text fontWeight={"semibold"}>Other</Text>
                        </Flex>
                      </Grid>
                      <FormErrorMessage>{errors.type as string}</FormErrorMessage>
                    </FormControl>
                    {/* <FormControl
                      isInvalid={!!errors.description}
                    >
                      <FormLabel fontSize={"sm"}>
                        About{" "}
                        <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip>
                      </FormLabel>
                      <Field
                        name="description"
                        value={values.description}
                        background
                        as={Textarea}
                        rows={3}
                        maxLength={250}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Description is required";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="My first sales team"
                      />
                      <FormErrorMessage>{errors.description as string}</FormErrorMessage>
                    </FormControl> */}
                    <Flex justifyContent={"end"} w={"full"}>
                    <Button
                      variant={"dark"}
                      isLoading={create.state === "loading" || update.state === "loading"}
                      size={"sm"}
                      type="submit"
                    >
                      {!currentTeam?.id ? `Create` : 'Update'} team
                    </Button>
                    </Flex>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box p={4} mt={4} px={8}>
        <Stack>
          <Heading>
            Good {_.capitalize(getGreetingTime())},{" "}
            {_.upperFirst(profile?.name || profile?.username)} 👋
          </Heading>
          <Text fontSize={"0.5xl"} className="text-muted-foreground">
            Collaborate with your AI team and organize your workflows here
          </Text>
        </Stack>
        <Stack mt={6}>
          {teams.state !== "loading" && !teams.data.length ? (
            <Stack
              gap={1}
              h={400}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <svg
                width="260"
                height="140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 105h-11.206a2 2 0 0 1-2-2V71a2 2 0 0 0-2-2H94"
                  stroke="#BCC3CE"
                  stroke-width="0.5"
                  stroke-linejoin="round"
                  stroke-dasharray="4 4"
                ></path>
                <g filter="url(#a)">
                  <rect
                    x="30"
                    y="38"
                    width="56"
                    height="56"
                    rx="28"
                    fill="#FFFFFF"
                  ></rect>
                  <rect
                    x="30"
                    y="38"
                    width="56"
                    height="56"
                    rx="28"
                    stroke="#266DF0"
                    stroke-width="0.75"
                  ></rect>
                </g>
                <rect
                  opacity="0.4"
                  x="36"
                  y="44"
                  width="44"
                  height="44"
                  rx="22"
                  stroke="#266DF0"
                  stroke-width="0.75"
                ></rect>
                <circle
                  cx="58"
                  cy="66"
                  r="6.75"
                  stroke="#266DF0"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></circle>
                <path
                  d="m55.5 66.273.13.207c.532.839.797 1.258 1.138 1.405a1.2 1.2 0 0 0 .933.007c.343-.141.615-.557 1.159-1.387L60.5 64"
                  stroke="#266DF0"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="m139 24.813.026 14.561c0 1.55.776 2.993 2.063 3.816l13.319 8.528a4.354 4.354 0 0 0 4.273.239l12.828-6.331c1.517-.748 2.486-2.314 2.491-4.033l-.026-14.562c0-1.55-.776-2.993-2.063-3.816l-13.319-8.528a4.354 4.354 0 0 0-4.273-.238l-6.899 3.401-5.935 2.93c-1.516.748-2.48 2.313-2.485 4.027v.006Z"
                  stroke="#99A2AF"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                ></path>
                <path
                  opacity="0.4"
                  d="m157.487 45.329.026-9.85c.016-1.173.688-2.255 1.725-2.765l5.518-2.722 1.193-.589 2.267-1.12 2.089-1.03a.973.973 0 0 1 .97.054c.14.09.469.355.469.865l.015 7.19-.01-.01.01 5.402a3.11 3.11 0 0 1-1.714 2.77l-10.192 5.026.01-.016-.865.43c-.453.223-.854.021-1.011-.075-.151-.095-.505-.376-.5-.891v-2.685.016Z"
                  stroke="#99A2AF"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="m139 96.813.026 14.561c0 1.55.776 2.993 2.063 3.816l13.319 8.528a4.353 4.353 0 0 0 4.273.239l12.828-6.331c1.517-.748 2.486-2.314 2.491-4.033l-.026-14.562c0-1.55-.776-2.993-2.063-3.816l-13.319-8.528a4.354 4.354 0 0 0-4.273-.238l-6.899 3.401-5.935 2.93c-1.516.748-2.48 2.313-2.485 4.027v.006Z"
                  stroke="#99A2AF"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                ></path>
                <path
                  opacity="0.4"
                  d="m157.487 117.329.026-9.85c.016-1.173.688-2.255 1.725-2.765l5.518-2.722 1.193-.589 2.267-1.12 2.089-1.03a.973.973 0 0 1 .97.054c.14.09.469.355.469.865l.015 7.19-.01-.01.01 5.402a3.11 3.11 0 0 1-1.714 2.77l-10.192 5.026.01-.016-.865.43c-.453.223-.854.021-1.011-.075-.151-.095-.505-.376-.5-.891v-2.685.016Z"
                  stroke="#99A2AF"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M187 33h43M187 105h43"
                  stroke="#BCC3CE"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-dasharray="4 4"
                ></path>
                <path
                  d="M126 33h-11.206a2 2 0 0 0-2 2v32a2 2 0 0 1-2 2H94"
                  stroke="#266DF0"
                  stroke-width="0.5"
                  stroke-linejoin="round"
                  stroke-dasharray="4 4"
                ></path>
                <defs>
                  <filter
                    id="a"
                    x="9.625"
                    y="25.625"
                    width="96.75"
                    height="96.75"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                      in="SourceAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feMorphology
                      radius="4"
                      in="SourceAlpha"
                      result="effect1_dropShadow_96_22007"
                    ></feMorphology>
                    <feOffset dy="8"></feOffset>
                    <feGaussianBlur stdDeviation="12"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.14902 0 0 0 0 0.427451 0 0 0 0 0.941176 0 0 0 0.08 0"></feColorMatrix>
                    <feBlend
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_96_22007"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feMorphology
                      radius="6"
                      in="SourceAlpha"
                      result="effect2_dropShadow_96_22007"
                    ></feMorphology>
                    <feOffset dy="6"></feOffset>
                    <feGaussianBlur stdDeviation="6"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.14902 0 0 0 0 0.427451 0 0 0 0 0.941176 0 0 0 0.12 0"></feColorMatrix>
                    <feBlend
                      in2="effect1_dropShadow_96_22007"
                      result="effect2_dropShadow_96_22007"
                    ></feBlend>
                    <feBlend
                      in="SourceGraphic"
                      in2="effect2_dropShadow_96_22007"
                      result="shape"
                    ></feBlend>
                  </filter>
                </defs>
              </svg>
              <Heading size={"md"} fontWeight={700}>
                No teams found
              </Heading>
              <Text className="text-muted-foreground">
                Create your first AI team to get started
              </Text>
              <Button
                onClick={() => {
                  onOpen();
                }}
                variant={"dark"}
                className="rounded-md mt-2"
              >
                <PlusIcon size={16} /> Create team
              </Button>
            </Stack>
          ) : (
            <>
              <Flex
                w={"100%"}
                justifyContent={"space-between"}
                className="border-b"
                pb={4}
              >
                <Heading size={"lg"}>My teams</Heading>
                <Button
                  onClick={() => {
                    onOpen();
                  }}
                  variant={"dark"}
                  className="rounded-full font-bold"
                >
                  <PlusIcon size={16} /> Create team
                </Button>
              </Flex>
              <Grid mt={4} gap={4} gridTemplateColumns={[null, null, null, "repeat(4, 1fr)", "repeat(4, 1fr)"]}>
                {teams.data.map((team: any) => {
                  const { icon, bgIcon } = iconColorMapping[team.type] || {
                    bgIcon: (
                      <LayoutDashboardIcon
                        size={120}
                        color={"#fef9c3"}
                        strokeWidth={2.5}
                      />
                    ),
                    icon: <LayoutDashboardIcon size={20} />,
                  };
                  return (
                    <TeamCard
                    onItemClick={() => {
                      setCurrentTeam(team)
                      onOpen()
                    }}
                      bgIcon={bgIcon}
                      icon={icon}
                      id={team.id}
                      title={team.name}
                      subtitle={team.description}
                      total={team.agents || 0}
                      onDeleteClick={() => {
                        setCurrentTeam(team)
                        onDeleteOpen()
                      }}
                    />
                  );
                })}
              </Grid>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
