import {
  Flex,
  Stack,
  Text,
  IconButton,
  Grid,
  Tooltip,
  useToast,
  Skeleton,
  Center,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  Heading,
  Divider,
  FormControl,
  FormLabel,
  List,
  Img,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import {
  Building2Icon,
  CheckIcon,
  CloudDownloadIcon,
  CopyIcon,
  DownloadCloudIcon,
  FilesIcon,
  MoreVerticalIcon,
  PlusIcon,
  RotateCcwIcon,
  XIcon,
} from "lucide-react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useEffect } from "react";
import CircleIcon from "../../@components/CircleIcon";
import { createFile, getFiles } from "../../lib/app/files/thunk";
import { AddIcon } from "@chakra-ui/icons";
import { Form, Formik } from "formik";
import Dropzone from "react-dropzone";
import pdf from '../../assets/img/pdf.png'
import doc from '../../assets/img/doc.png'
import txt from '../../assets/img/txt.png'
import { Button } from "~/components/ui/button";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";

function returnImageOnType (type: string) {
  switch (type) {
    case "pdf":
      return pdf
    case "doc":
    case "docx":
      return doc
    case "txt":
      return txt
    default:
      return undefined
  }
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

export default function KnowledgeBase() {
  const files = useAppSelector((state) => state.app.files.list);
  const create = useAppSelector((state) => state.app.files.create);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const organization = useAppSelector(state => state.app.organizations.get)
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    if (organization?.data?.providers?.vapi?.api_key) {
      dispatch(getFiles(organization?.data?.id));
    }
  }, [organization]);

  return (
    <Page title="Knowledge Base" subtitle="Create and upload your documents that your agents can use as their knowledgebase" actions={[!files.data.length && files.state === "success" ? <></> : 
      <Button
        onClick={() => {
          onOpen();
        }}
        size={"sm"}
        variant="dark"
      >
       <PlusIcon size={16} /> Upload File
      </Button>]}>
      <Drawer size={"md"} isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay
          style={{
            backdropFilter: "blur(4px)",
          }}
        />
        <DrawerContent boxShadow={0} background={"transparent"}>
          <Formik
            initialValues={{
              file: null,
            }}
            onSubmit={(values) => {
                dispatch(createFile(organization?.data?.id, values.file, () => {
                  onClose()
                  toast({
                    title: "File has been uploaded successfully",
                    status: "success"
                  })
                  dispatch(getFiles(organization?.data?.id))
                }))
            }}
          >
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Stack
                    h={"calc(100vh - 24px)"}
                    rounded={10}
                    m={3}
                    justifyContent={"space-between"}
                    background={"white"}
                  >
                    <Flex
                      p={4}
                      pb={0}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Heading size={"md"}>Add your knowledge base</Heading>
                      <IconButton
                        size={"sm"}
                        variant={"none"}
                        icon={<XIcon size={20} />}
                        aria-label=""
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </Flex>
                    <Divider my={2} />
                    <Stack px={4} flex={1}>
                    <FormControl>
                      <FormLabel>Choose your file</FormLabel>
                      <Dropzone maxFiles={1} accept={{
                        "application/*": [".pdf", ".docx", ".doc"],
                        "text/*": [ ".txt" ]
                      }} useFsAccessApi={false} onDrop={acceptedFiles => {
                        setFieldValue("file", acceptedFiles[0])
                      }}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              {/* @ts-ignore */}
                              <div {...getRootProps({style: baseStyle})}>
                                <input accept=".pdf,.docx,.doc,.txt" {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                    </FormControl>
                    <Text>
                      Files
                    </Text>
                    <List>
                      {/* @ts-ignore */}
                      {values.file ? <Text>{values.file.name}</Text> : <Text>No files selected</Text>}
                    </List>
                    </Stack>
                    <Flex m={2} justify={"flex-end"}>
                      <Button
                        disabled={!values.file}
                        isLoading={create.state === "loading"}
                        type="submit"
                        variant={"dark"}
                      >
                        Click to upload
                      </Button>
                    </Flex>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DrawerContent>
      </Drawer>
      {!organization.data.providers?.vapi?.api_key ? <Center h={"80vh"}>
        <Stack align={"center"}>
            <Building2Icon size={30} />
            <Text fontWeight={600} fontSize={"lg"}>We are setting up your organisation</Text>
            <Text>Kindly re-visit after 24 hours</Text>
        </Stack>
      </Center> : <> <Stack mt={4} mx={4}>
        {!files.data.length && files.state === "success" ? <Center h={"60vh"}>
              <Stack alignItems={"center"} gap={1}>
                <FilesIcon size={36} />
                <Text fontWeight={600}>No files found</Text>
                <Text fontSize={"sm"} color={"gray"}>Click below to add your first file</Text>
                <Button onClick={() => {
                  onOpen()
                }} variant="dark"><AddIcon boxSize={3} /> Upload File</Button>
              </Stack>
            </Center> : 
        <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
          <TableHeader className="!bg-slate-50">
            <TableRow>
              <TableHead className="w-[300px] font-semibold">Id</TableHead>
              <TableHead colSpan={2} className="font-semibold">Name</TableHead>
              <TableHead className="font-semibold">Status</TableHead>
              <TableHead className="font-semibold">File Type</TableHead>
              <TableHead className="font-semibold text-right pr-4">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {files.data.map((file: any) => {
              return (
                <TableRow>
                  <TableCell className="font-medium border-b">{file.id}</TableCell>
                  <TableCell colSpan={2} className="border-b">{file.name}</TableCell>
                  <TableCell className="border-b">{<Badge variant={file.status === "indexed" ? "green-subtle" : "orange-subtle"}>{file.status === "indexed" ? <CheckIcon className="mr-1" size={12} /> : <RotateCcwIcon className="mr-1" size={12} />} {file.status}</Badge>}</TableCell>
                  <TableCell className="font-semibold border-b">                    <Flex gap={2} alignItems={"center"}><Img h={4} src={returnImageOnType(file.file_type?.split("/").pop())} />
                   {file.file_type?.split("/").pop()}</Flex></TableCell>
                  <TableCell className="text-right pr-6 border-b">
                    <IconButton onClick={() => {
                      window.open(file.url, "_blank")
                    }} className="mr-3" size={"xs"} aria-label="" icon={<CloudDownloadIcon size={16} />} variant={"outline"} />
                    <IconButton size={"xs"} aria-label="" icon={<MoreVerticalIcon size={16} />} variant={"outline"} />
                  </TableCell>
                </TableRow>
              )
            })}
            {files.state === "loading" ? <>
            {[...Array(18)].map((e: any) => {
              return ( <TableRow>
               <TableCell className="w-[300px]"><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell colSpan={2}><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
           </TableRow>)
            })}
             
              
            
            </> : <></>}
          </TableBody>
        </Table>}
      </Stack> </>}
    </Page>
  );
}
