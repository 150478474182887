"use client"

import * as React from "react"

import { dataTableConfig, type DataTableConfig } from "~/config/data-table"
import { ToggleGroup, ToggleGroupItem } from "~/components/ui/toggle-group"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip"

type FeatureFlagValue = DataTableConfig["featureFlags"][number]["value"]

interface InteractionsTableContextProps {
  featureFlags: FeatureFlagValue[]
  setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagValue[]>>
  onCellClick?: (call: any) => void
}

const InteractionsTableContext = React.createContext<InteractionsTableContextProps>({
  featureFlags: [],
  setFeatureFlags: () => {}
})

export function useInteractionsTable() {
  const context = React.useContext(InteractionsTableContext)
  if (!context) {
    throw new Error("useInteractionsTable must be used within a InteractionsTableProvider")
  }
  return context
}

interface InteractionsTableProviderProps {
  onCellClick?: (call: any) => void;
}

export function InteractionsTableProvider({ children, onCellClick }: React.PropsWithChildren<InteractionsTableProviderProps>) {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagValue[]>([])

  return (
    <InteractionsTableContext.Provider
      value={{
        featureFlags,
        setFeatureFlags,
        onCellClick
      }}
    >
      {/* <div className="w-full overflow-x-auto">
        <ToggleGroup
          type="multiple"
          variant="outline"
          size="sm"
          value={featureFlags}
          onValueChange={(value: FeatureFlagValue[]) => setFeatureFlags(value)}
          className="w-fit"
        >
          {dataTableConfig.featureFlags.map((flag) => (
            <Tooltip key={flag.value} delayDuration={250}>
              <ToggleGroupItem
                value={flag.value}
                className="whitespace-nowrap px-3 text-xs"
                asChild
              >
                <TooltipTrigger>
                  <flag.icon
                    className="mr-2 size-3.5 shrink-0"
                    aria-hidden="true"
                  />
                  {flag.label}
                </TooltipTrigger>
              </ToggleGroupItem>
              <TooltipContent
                align="start"
                side="bottom"
                sideOffset={6}
                className="flex max-w-60 flex-col space-y-1.5 border bg-background py-2 font-semibold text-foreground"
              >
                <div>{flag.tooltipTitle}</div>
                <div className="text-xs text-muted-foreground">
                  {flag.tooltipDescription}
                </div>
              </TooltipContent>
            </Tooltip>
          ))}
        </ToggleGroup>
      </div> */}
      {children}
    </InteractionsTableContext.Provider>
  )
}
