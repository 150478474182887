import { Button, Center, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Heading, Link, Stack, Text, useToast, Image, Icon } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";
import { FiLink } from "react-icons/fi"; // Import a link icon from react-icons

export default function ConsentForm() {
    const toast = useToast();
    const [state, setState] = useState<string>("pending");

    return (
        <Center height={"100vh"} width={"100vw"} backgroundColor={"#f3f3fe"}>
            {state === "success" ? (
                <Stack
                width={"40vw"}
                backgroundColor={"white"}
                padding={8}
                borderRadius="20px"
                boxShadow="md"
                alignItems="center"  // Center everything inside the Stack
                spacing={4}          // Add some spacing between the items
            >
                {/* Success GIF */}
                <Image
                    src="sucess.gif"  // Replace this with the actual path to your success GIF
                    alt="Success"
                    boxSize="100px"     // Adjust the size of the GIF as needed
                />
            
                {/* Success Message */}
                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                    Your submission was successful!
                </Text>
            </Stack>
            ) : (
                <Stack
                    width={"40vw"}
                    backgroundColor={"white"}
                    padding={8}
                    borderRadius="20px"
                    boxShadow="md"
                >
                    <Image src="/logoFull.png" alt="Company Logo" marginBottom={8} />

                    <Heading fontSize={"2xl"}>Opting Out Request</Heading>
                    <Text mb={4}>
                    If you change your mind in the future, we'd love to connect and discuss potential opportunities with you!
                    </Text>
                    <Stack alignItems={"center"} width="100%">
                        <Formik
                            onSubmit={(values) => {
                                toast({
                                    title: "Feedback submitted",
                                    status: "success",
                                });
                                setState("success");
                            }}
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                email: "",
                                areaCode: "",
                                phoneNumber: "",
                                description: "",
                            }}
                        >
                            {({ errors, touched, values }) => (
                                <Form style={{ width: "100%" }}>

                                    {/* Align Email, Area Code, and Phone Number in the same row */}
                                    <Grid gap={4} gridTemplateColumns={`2fr 1fr 2fr`} mt={4}>
                                        <FormControl isInvalid={!!errors.email && touched.email}>
                                            <FormLabel fontSize={"sm"}>E-mail</FormLabel>
                                            <Field
                                                name="email"
                                                type="email"
                                                value={values.email}
                                                as={Input}
                                                validate={(v: string) => {
                                                    let error;
                                                    if (!v) error = "Enter a valid email";
                                                    return error;
                                                }}
                                                fontSize={"sm"}
                                                placeholder="example@example.com"
                                            />
                                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={!!errors.areaCode && touched.areaCode}>
                                            <FormLabel fontSize={"sm"}>Area Code</FormLabel>
                                            <Field
                                                name="areaCode"
                                                value={values.areaCode}
                                                as={Input}
                                                validate={(v: string) => {
                                                    let error;
                                                    if (!v) error = "Enter area code";
                                                    return error;
                                                }}
                                                fontSize={"sm"}
                                                placeholder="Ex: +91"
                                            />
                                            <FormErrorMessage>{errors.areaCode}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl isInvalid={!!errors.phoneNumber && touched.phoneNumber}>
                                            <FormLabel fontSize={"sm"}>Phone Number</FormLabel>
                                            <Field
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                as={Input}
                                                validate={(v: string) => {
                                                    let error;
                                                    if (!v) error = "Enter phone number";
                                                    return error;
                                                }}
                                                fontSize={"sm"}
                                                placeholder="Phone Number"
                                            />
                                            <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                                        </FormControl>
                                    </Grid>

                                    <FormControl isInvalid={!!errors.description && touched.description} mt={4}>
                                        <FormLabel fontSize={"sm"}>Additional Instructions</FormLabel>
                                        <Field
                                            name="description"
                                            value={values.description}
                                            as={Textarea}
                                            validate={(v: string) => {
                                                let error;
                                                if (!v) error = "Enter additional instructions";
                                                return error;
                                            }}
                                            fontSize={"sm"}
                                            placeholder="Additional Instructions"
                                        />
                                        <FormErrorMessage>{errors.description}</FormErrorMessage>
                                    </FormControl>

                                    <Flex justifyContent={"center"} alignItems={"center"} mt={6}>
                                        <Button colorScheme={"green"} size={"sm"} type="submit" mr={4}>
                                            Submit
                                        </Button>
                                        
                                        {/* Spacer pushes the link to the right */}
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                    </Stack>
                </Stack>
            )}
        </Center>
    );
}
