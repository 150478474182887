import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  IconButton,
  Skeleton,
  Spinner,
  Stack,
  Tab,
  Select as CoreSelect,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
  SliderTrack,
  SliderFilledTrack,
  Slider,
  HStack,
  SliderThumb,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  Building2Icon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronsUpDownIcon,
  CloudDownloadIcon,
  DownloadIcon,
  GlobeIcon,
  LineChartIcon,
  MoreVerticalIcon,
  PauseIcon,
  PhoneIcon,
  PhoneOutgoingIcon,
  PlayIcon,
  RotateCcwIcon,
  SubtitlesIcon,
  ZapIcon,
} from "lucide-react";
import CircleIcon from "../../@components/CircleIcon";
import moment from "moment-timezone";
import JsonView from "@uiw/react-json-view";
import { githubDarkTheme } from '@uiw/react-json-view/githubDark';
import { getAllLogs, getSpecificLog } from "../../lib/app/logs/thunk";
import WavesurferPlayer from "@wavesurfer/react";
// @ts-ignore
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
// @ts-ignore
import Regions from 'wavesurfer.js/dist/plugins/regions.esm.js'
import WaveSurfer from "wavesurfer.js";
import { useParams } from "react-router-dom";
import { generateCanonicalNames, generateCanonicalVariants } from "../../utils/helper.utils";
import _ from "lodash";
import { Select, chakraComponents } from "chakra-react-select";
import { loadAgents } from "../../lib/app/platform/thunk";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import Markdown from "react-markdown";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

function TableAudio({
  url,
  onInteraction,
}: {
  url: string;
  onInteraction: (
    state: "playing" | "paused",
    ref?: RefObject<HTMLAudioElement> | null
  ) => void;
}) {
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(0);
  const [isPaused, setPaused] = useState(true);
  const ref = useRef<HTMLAudioElement>(null);

  return (
    <Flex alignItems={"center"} gap={3}>
      <audio
        onEnded={() => {
          onInteraction("paused", ref);
          setTrack(0);
          setPaused(true);
        }}
        onTimeUpdate={(e) => {
          setTrack(Math.ceil(e.currentTarget.currentTime));
        }}
        hidden
        ref={ref}
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(Math.ceil(ref.current?.duration || 0));
        }}
      />

      <CircularProgress
        max={duration}
        value={track}
        color="hsl(var(--primary))"
        size={6}
      >
        <CircularProgressLabel mx={"auto"}>
          {isPaused ? (
            <PlayIcon
              size={10}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          ) : (
            <PauseIcon
              color="hsl(var(--primary))"
              size={10}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          )}
        </CircularProgressLabel>
      </CircularProgress>
      {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}
    </Flex>
  );
}

const AudioPlayer = ({ url }: { url: string }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Update duration when metadata is loaded
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });

      // Update progress as the audio plays
      audio.addEventListener("timeupdate", () => {
        setProgress(audio.currentTime);
      });
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", () => {});
        audio.removeEventListener("timeupdate", () => {});
      }
    };
  }, []);

  const togglePlayPause = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSliderChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = value;
      setProgress(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <Box width="240px" bg="gray.100" borderRadius="lg">
      <audio ref={audioRef} src={url} />

      <HStack>
        <IconButton
        size={"xs"}
          aria-label={isPlaying ? "Pause" : "Play"}
          icon={isPlaying ? <PauseIcon size={12} /> : <PlayIcon size={12} />}
          onClick={togglePlayPause}
        />
        <Text>{formatTime(progress)}</Text>

        <Slider
          aria-label="audio-progress"
          value={progress}
          min={0}
          max={duration}
          onChange={handleSliderChange}
          flex="1"
          focusThumbOnChange={false}
        >
          <SliderTrack bg="gray.300">
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text>{formatTime(duration)}</Text>
      </HStack>
    </Box>
  );
};

function convertSecondsToReadableFormat(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
      result += `${hours} hr `;
  }
  if (minutes > 0) {
      result += `${minutes} min `;
  }
  if (remainingSeconds > 0) {
      result += `${remainingSeconds.toFixed()} sec${remainingSeconds > 1 ? 's' : ''}`;
  }

  return result.trim();
}

export default function AuditLogs() {
  const logs = useAppSelector((state) => state.app.logs.list);
  const log = useAppSelector((state) => state.app.logs.get);
  const metadata = useAppSelector((state) => state.app.voices.list.metadata);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const toast = useToast();
  const [assistantId, setAssistantId] = useState<string | undefined>(undefined)
  const [selectedAgent, setSelectedAgent] = useState<{
    label?: string,
    value?: string
  }>({})
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("year").toDate(),
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const organization = useAppSelector(state => state.app.organizations.get)
  const [range, setRange] = useState<moment.unitOfTime.StartOf>("day")
  const assistants = useAppSelector((state) => state.app.platform.agents.list)
  const wavesurfer = useRef<WaveSurfer | null>(null)
  const isPlaying = useRef<boolean>(false)
  const [playing, setPlaying] = useState(false)
  const timer = useRef<NodeJS.Timeout | number | null>(null)
  const { log: logId } = useParams()

  useEffect(() => {
    if (!assistants.data.length && organization.data.id) {
      dispatch(loadAgents(organization.data.id!, page, size, '', {}))
    }
  }, [organization, page, size])

  useEffect(() => {
    // if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id
      dispatch(getAllLogs(key, selectedAgent.value))
    // }
  }, [organization, selectedAgent])


  useEffect(() => {
    if (logId) {
      setAssistantId(logId)
    } else if (logs.data?.length) {
      setAssistantId(logs.data[0].id)
    }
  }, [ logs ])

  const debouncedSearch = _.debounce(() => {
    dispatch(getAllLogs(organization.data.id, undefined, page, size))
  }, 300)

  useEffect(() => {
    if (organization.data.id) {
      debouncedSearch()
    }
  }, [organization.data, page, size]);


  return (
    <Page subtitle="Find all your assistant communications here" title="Conversations">
      <Drawer
        size={"xl"}
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p={2}>
          <Flex display={logs.state === "success" && !logs.data.length ? "none" : "flex"} overflow={"scroll"} h={'calc(100vh)'} flex={0.7}>
          <Stack p={4} flex={0.65} borderRight={"1px solid #e4e4e4"}>
            <Box mb={3}>
              <Text fontWeight={600}>Recording</Text>
              {log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl  ? <Box>
                <WavesurferPlayer
                waveColor={"violet"}
                progressColor={"purple"}
                cursorWidth={1}
                // @ts-ignore
                // media={document.querySelector(`audio[src="${log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl}"]`)}
                url={log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl}
                cursorColor={"#e4e4e4"}
                height={50}
                normalize={true}
                autoScroll={true}
                //@ts-ignore
                onReady={(w) => {
                  wavesurfer.current = w
                }}
                onPlay={() => {
                  isPlaying.current = true
                }}
                onPause={() => {
                  isPlaying.current = false
                }}
                backend={"WebAudio"}
                splitChannels={[{
                    progressColor: "#fde047",
                    waveColor: "#854d0e"
                }, {
                    progressColor: "#67e8f9",
                    waveColor: "#115e59"
                }]} plugins={[
                  Timeline.create(),
                  Regions.create(),
                ]} />
                <Flex align={"center"} mt={4} justify={"space-between"}>
                  <IconButton onClick={() => {
                    wavesurfer.current?.playPause()
                  }} variant={"outline"} size={"sm"} aria-label="" icon={wavesurfer.current?.isPlaying() ? <PauseIcon size={20} /> : <PlayIcon size={20} />} />
                  <IconButton onClick={() => {
                    window.open(log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl, "_blank")
                  }} variant={"outline"} size={"sm"} aria-label="" icon={<DownloadIcon size={20} />} />
                </Flex>
              </Box> : <Text fontSize={"sm"} color={"gray"}>No Recording available</Text>}
            </Box>
            <Tabs size={"sm"} variant={"enclosed"} colorScheme="primary" isLazy>
              <TabList gap={log.state === "loading" ? 2 : 0} borderColor={log.state === "loading" ? "transparent" : "inherit"}>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <SubtitlesIcon size={16} />
                    Transcripts
                  </Tab>
                </Skeleton>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <LineChartIcon size={16} />
                    Analysis
                  </Tab>
                </Skeleton>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <Skeleton rounded={8} isLoaded={log.state === "success"}>
                    <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                      <Text fontWeight={"bold"} fontSize={"sm"}>Transcript</Text>
                      {(log.data?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).filter((v: any) => v.role !== "system").map((message: any) => (
                        <Text fontSize={"sm"}>
                          <Text fontWeight={600} as={"span"}>{["bot", "assistant", "ai"].includes(message.role) ? "AI" : "User"}: </Text>
                          <Text as={"span"}>{message.message || message.content}</Text>
                        </Text>
                      ))}
                      {(log.data?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).length === 0 ? <Text fontSize={"sm"}>No Transcript</Text> : <></>}
                    </Stack>
                  </Skeleton>
                </TabPanel>
                <TabPanel px={0}>
                  <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Summary</Text>
                        <Markdown>{log.data?.analysis?.summary || 'No summary'}</Markdown>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Evaluation</Text>
                        <Text>{log.data?.analysis?.evaluation?.summary || 'None'}</Text>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Data</Text>
                        <Text fontSize={"xs"} color={"gray"}>This is the data extracted based on the interaction.</Text>
                        <JsonView value={log.data?.analysis?.extraction || {}} style={githubDarkTheme} />
                      </Stack>
                    </Skeleton>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Stack  flex={0.35} p={4} py={4}>
           <Skeleton hidden rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>ID</Text>
                <Text>{log.data.id}</Text>
              </Stack>
           </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
              <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>Ended Reason</Text>
                <Text>{generateCanonicalNames(log.data?.state || "Ended")}</Text>
              </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Agent Name</Text>
              <Text>{log.data?.assistant?.name}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>User Phone Number</Text>
              <Text>{log.data.meta?.customer?.number || log.data.meta?.metadata?.user_phone_number || "Unknown number"}</Text>
            </Stack>
            </Skeleton>
            {/* <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Phone Number</Text>
              <Text>{log.data.phoneNumber || "Unknown"}</Text>
            </Stack>
            </Skeleton> */}
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Conversation Channel</Text>
              <Text>{_.capitalize(log.data.meta?.type || '')}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Started At</Text>
              <Text> {moment(typeof log.data.meta?.startedAt === "number" 
    ? new Date(log.data.meta.startedAt * 1000) 
    : new Date(log.data.meta?.startedAt))
    .format("DD MMM, hh:mm a")}</Text>
            </Stack></Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Ended At</Text>
              <Text>{moment(log.data.meta?.endedAt || (moment(new Date(log.data.meta?.startedAt * 1000)).add(log.data.meta?.duration, "seconds").toLocaleString())).format("DD MMM, YYYY, hh:mm a")}</Text>
            </Stack>
            </Skeleton>
          </Stack>
        </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* <Flex mb={4} ml={3} gap={2}>
        <RangeDatepicker
          configs={{
            dateFormat: "dd MMM",
          }}
          propsConfigs={{
            dayOfMonthBtnProps: {
              selectedBtnProps: {
                colorScheme: "primary",
                background: "primary.400",
                color: "white",
                rounded: "full",
              },
              isInRangeBtnProps: {
                colorScheme: "primary",
                background: "primary.400",
                rounded: "full",
                color: "white",
              },
            },
            inputProps: {
              rounded: "full",
              w: 120,
              p: 0,
              margin: 0,
              border: 0,
              outline: 0,
              textAlign: "end",
              cursor: "pointer",
              isReadOnly: true,
              _focusVisible: {
                border: 0,
              },
            },
          }}
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
        />
      </Flex> */}
      {!organization.data.providers?.vapi?.api_key ? <Center h={"80vh"}>
        <Stack align={"center"}>
            <Building2Icon size={30} />
            <Text fontWeight={600} fontSize={"lg"}>We are setting up your organisation</Text>
            <Text>Kindly re-visit after 24 hours</Text>
        </Stack>
      </Center> : <Stack py={3} className="border">
      <Stack mb={2} ml={4} w={200} gap={1}>
          <Text fontSize={"sm"}>
            Choose agent
          </Text>
          <Select
            colorScheme="primary"
            chakraStyles={dropdownStyles}
            components={dropdownComponents}
            size={"sm"}
            variant="outline"
            value={selectedAgent}
            onChange={(v) => {
              // @ts-ignore
              setSelectedAgent(v)
            }}
            options={assistants.data.map((d: any) => ({
              label: d.name,
              value: d.id
            }))}
          />
        </Stack>
        <Flex>

        <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
          <TableHeader className="!bg-slate-50">
            <TableRow>
              <TableHead className="w-[300px] font-semibold">Id</TableHead>
              <TableHead className="font-semibold">Type</TableHead>
              <TableHead className="font-semibold">Ended Reason</TableHead>
              <TableHead className="font-semibold">Call Sentiment</TableHead>
              <TableHead className="font-semibold">Recording</TableHead>
              <TableHead className="font-semibold text-right pr-6">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.data.map((call: any) => {
              const score = call?.analysis?.[0]?.evaluation?.answer
              return (
                <TableRow className="cursor-pointer" onClick={() => {
                  dispatch(getSpecificLog(organization.data.id, call.id, call.agent_id))
                  onOpen()
                }}>
                  <TableCell className="font-medium border-b">{call.id}</TableCell>
                  <TableCell className="border-b">{call.meta?.type || (call.meta?.voip?.number ? "Phone Call" : "Web Call")}</TableCell>
                  <TableCell  className="border-b">{<Badge variant={generateCanonicalVariants(call.state || "Ended") as any}>{generateCanonicalNames(call.state || "Ended")}</Badge>}</TableCell>
                  <TableCell className="font-semibold border-b">{<Badge
                        variant="transparent"
                        className={"text-sm text-medium gap-2"}
                      >
                        {score > 7 ? (
                          <ZapIcon size={16} fill="#22c55e" stroke={"#22c55e"} />
                        ) : (
                          <div
                            className={`w-2.5 h-2.5 rounded-sm ${
                              score > 7
                                ? "bg-green-500"
                                : score > 5
                                ? "bg-blue-500"
                                : score > 3
                                ? "bg-yellow-500"
                                : score !== undefined ? "bg-red-500" : "bg-gray-500"
                            }`}
                          />
                        )}
                        {score > 7
                          ? "Very Positive"
                          : score > 5
                          ? "Positive"
                          : score > 3
                          ? "Good"
                          : score > 1
                          ? "Poor"
                          : score !== undefined ? "Very Poor" : "Unknown" }
                      </Badge>}</TableCell>
                  <TableCell className="font-semibold pr-6 border-b">
                   {/* <Flex alignItems={"center"} gap={1}>{call.meta?.recordingUrl ? <TableAudio url={call.meta?.recordingUrl} onInteraction={(state) => {}} /> : <></>}<Text whiteSpace={"nowrap"}>{convertSecondsToReadableFormat(call.meta?.duration) || "-"}</Text></Flex> */}
                    <AudioPlayer url={call.meta?.recordingUrl } />
                  </TableCell>
                  <TableCell className="text-right pr-6 border-b">
                    <IconButton onClick={() => {
                      window.open(log.data.meta?.stereoRecordingUrl || log.data.meta?.recordingUrl, "_blank")
                    }} variant={"outline"} size={"xs"} aria-label="" icon={<DownloadIcon size={14} />} />
                  </TableCell>
                </TableRow>
              )
            })}
            {logs.state === "loading" ? <>
            {[...Array(18)].map((e: any) => {
              return ( <TableRow>
               <TableCell ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell colSpan={2} ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
           </TableRow>)
            })}
             
              
            
            </> : <></>}
          </TableBody>
        </Table>
        {logs.state === "loading" ? <></> : logs.state === "success" && !logs.data.length ? <>
        <Center h={'calc(100vh - 54px)'}borderRight={"1px solid #e4e4e4"} flex={1}>
          <Stack align={"center"}>
              <PhoneOutgoingIcon size={30} />
              <Text fontWeight={600} fontSize={"lg"}>
                No conversations found
              </Text>
              <Text>Your logs will show up as soon as you speak with any agent</Text>
            </Stack>
        </Center>
        </> : <Stack h={"80vh"} overflow={"scroll"} borderRight={"1px solid #e4e4e4"} flex={0.3}>
        {logs.data.map((d: any, i: number) => (
            <Stack
              background={d.id === assistantId ? 'var(--chakra-colors-primary-50)' : 'white'}
              p={4}
              py={2}
              onClick={() => {
                setAssistantId(d.id)
              }}
              cursor={"pointer"}
              borderBottom={"1px solid #e4e4e4"}
              _hover={{
                backgroundColor: d.id === assistantId ? 'var(--chakra-colors-primary-50)' : "#fcfbff",
                borderRadius: 8
              }}
            >
              <Flex justifyContent={"space-between"}>
                <Box>
                  <Text>{d.assistant?.name}</Text>
                  <Flex mt={2} gap={2} flexWrap={"wrap"}>
                    <Tag colorScheme="primary" variant={"solid"} size={"sm"}>
                      {d.meta?.type === "webCall" ? <> <TagLeftIcon as={GlobeIcon} boxSize={2.5} />
                      <TagLabel>Web</TagLabel></> : <><TagLeftIcon as={PhoneIcon} boxSize={2.5} />
                      <TagLabel>Call</TagLabel></>}
                    </Tag>
                    <Tag colorScheme={d.meta?.type === "outboundPhoneCall" ? "red" : "teal"} variant={"solid"} size={"sm"}>
                      <TagLeftIcon as={CircleIcon} boxSize={2.5} />
                      {d.meta?.type === "inboundPhoneCall" ? <TagLabel>Inbound</TagLabel> : <TagLabel>Outbound</TagLabel>}
                    </Tag>
                    {/* <Tag colorScheme="purple" variant={"solid"} size={"sm"}>
                      <TagLabel>${Number(d.cost || 0).toFixed(2)}</TagLabel>
                    </Tag> */}
                  </Flex>
                </Box>
                <Flex gap={2}>
                  {/* <IconButton
                    aria-label=""
                    icon={<EllipsisIcon size={16} />}
                    variant={"outline"}
                    size={"xs"}
                  />
                  <IconButton
                    aria-label=""
                    icon={<DownloadCloudIcon size={16} />}
                    variant={"outline"}
                    size={"xs"}
                  /> */}
                </Flex>
              </Flex>
              {/* <Flex gap={2} alignItems={"center"}>
                    <Avatar
                      src="https://i.ibb.co/7bqv4xq/stacy.png"
                      size={"xs"}
                    />
                    <Text fontWeight={500}>Stacy</Text>
                  </Flex> */}
              <Text fontSize={"sm"}>{moment(d.created_at).format("DD MMM YYYY, hh:mm a")}</Text>
              <Text></Text>
            </Stack>
          ))}
        </Stack>}
        {/*  */}
      </Flex></Stack>}

      <Flex display={logs.state === "success" && logs.data?.length > 0 ? "flex" : "none"} ml={2} justifyContent={"space-between"}>
          <Flex align={"center"} gap={2}>
            <CoreSelect onChange={(v) => {
              setSize(Number.parseInt(v.target.value, 10))
            }}  defaultValue={size} size="sm" borderRadius={8}>
              <option value={15} selected>
                15
              </option>
              <option value={30}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CoreSelect>
          </Flex>
          <Flex gap={2} align={"center"}>
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(page - 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                setPage(page + 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronRightIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                // @ts-ignore
                setPage(Math.ceil(metadata?.total_count / size))
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsRightIcon size={16} />}
              aria-label=""
            />
          </Flex>
        </Flex>
    </Page>
  );
}
