"use client"

import * as React from "react"

import { dataTableConfig, type DataTableConfig } from "~/config/data-table"
import { ToggleGroup, ToggleGroupItem } from "~/components/ui/toggle-group"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip"

type FeatureFlagValue = DataTableConfig["featureFlags"][number]["value"]

interface ContactsTableContextProps {
  featureFlags: FeatureFlagValue[]
  setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagValue[]>>
  onCellClick?: (call: any) => void
}

const ContactsTableContext = React.createContext<ContactsTableContextProps>({
  featureFlags: [],
  setFeatureFlags: () => {}
})

export function useContactsTable() {
  const context = React.useContext(ContactsTableContext)
  if (!context) {
    throw new Error("useContactsTable must be used within a ContactsTableProvider")
  }
  return context
}

interface ContactsTableProviderProps {
  onCellClick?: (call: any) => void;
}

export function ContactsTableProvider({ children, onCellClick }: React.PropsWithChildren<ContactsTableProviderProps>) {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagValue[]>([])

  return (
    <ContactsTableContext.Provider
      value={{
        featureFlags,
        setFeatureFlags,
        onCellClick
      }}
    >
      {children}
    </ContactsTableContext.Provider>
  )
}
