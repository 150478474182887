import { AppDispatch } from '../../store';
import Cookies from 'universal-cookie';
import moment from "moment-timezone";
import { setForgotPasswordError, setForgotPasswordLoading, setForgotPasswordSuccess, setLoginError, setLoginLoading, setLoginSuccess, setResetPasswordError, setResetPasswordLoading, setResetPasswordSuccess } from "./actions"
import { axios, axiosv2 } from "../../../utils/axios.loader"
import { setUserProfile } from "../../app/platform/actions";

const cookies = new Cookies();

export const initiateEmailLogin = (email: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoginLoading())
    try {
        const request = await axios.post('/users/login', {
            email,
            password
        })
        const { username, id, profile_pic, access_token, refresh_token } = request.data;
        cookies.set("accessToken", access_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        cookies.set("refreshToken", refresh_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        axios.defaults.headers.Authorization = `Bearer ${access_token}`
        axiosv2.defaults.headers.Authorization = `Bearer ${access_token}`
        dispatch(setUserProfile({
            image: profile_pic,
            id,
            username
        }))
        dispatch(setLoginSuccess({
            username,
            id,
            image: profile_pic
        }))

    } catch (err: any) {
        dispatch(setLoginError(err.toString()))
    }
}

export const initiateGoogleLogin = (code: string, onError?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setLoginLoading())
    try {
        const request = await axios.post('/users/google/login', {
            code,
            mode: "authorization-code-flow"
        })
        const { username, id, profile_pic, access_token, refresh_token } = request.data;
        cookies.set("accessToken", access_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        cookies.set("refreshToken", refresh_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        axios.defaults.headers.Authorization = `Bearer ${access_token}`
        axiosv2.defaults.headers.Authorization = `Bearer ${access_token}`
        dispatch(setUserProfile({
            image: profile_pic,
            id,
            username
        }))
        dispatch(setLoginSuccess({
            username,
            id,
            image: profile_pic
        }))
    } catch (err: any) {
        onError?.()
        dispatch(setLoginError(err.toString()))
    }
}

export const initiateMicrosoftLogin = (code: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoginLoading())
    try {
        const request = await axios.post('/users/microsoft/login', {
            code,
            mode: "implicit-grant-flow"
        })
        const { username, id, profile_pic, access_token, refresh_token } = request.data;
        cookies.set("accessToken", access_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        cookies.set("refreshToken", refresh_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        axios.defaults.headers.Authorization = `Bearer ${access_token}`
        axiosv2.defaults.headers.Authorization = `Bearer ${access_token}`
        dispatch(setUserProfile({
            image: profile_pic,
            id,
            username
        }))
        dispatch(setLoginSuccess({
            username,
            id,
            image: profile_pic
        }))
    } catch (err: any) {
        dispatch(setLoginError(err.toString()))
    }
}

export const initiateForgotPassword = (email: string) => async (dispatch: AppDispatch) => {
    dispatch(setForgotPasswordLoading())
    try {
        await axios.post('/users/forgot-password', {
            email
        })
        dispatch(setForgotPasswordSuccess({}))

    } catch (err: any) {
        dispatch(setForgotPasswordError(err.toString()))
    }
}

export const initiateResetPassword = (email: string, token: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(setResetPasswordLoading())
    try {
        await axios.post('/users/reset-password', {
            email,
            password,
            token
        })
        dispatch(setResetPasswordSuccess({}))
    } catch (err: any) {
        dispatch(setResetPasswordError(err.toString()))
    }
}