"use client"

import * as React from "react"
import { type DataTableFilterField } from "~/types"

import { useDataTable } from "~/hooks/use-contact-table"
import { DataTableAdvancedToolbar } from "~/components/data-table/advanced/data-table-advanced-toolbar"
import { DataTable } from "~/components/data-table/data-table"
import { DataTableToolbar } from "~/components/data-table/data-table-toolbar"

import { getColumns } from "./table-columns"
import { TasksTableFloatingBar } from "./table-floating-bar"
import { useContactsTable } from "./table-provider"
import { useAppDispatch, useAppSelector } from "~/lib/hooks"
import { getAllLogs } from "~/lib/app/logs/thunk"
import { listContacts } from "~/lib/app/contacts/thunk"
import { useParams } from "react-router-dom"


export function ContactsTable() {
  // Feature flags for showcasing some additional features. Feel free to remove them.
  const { featureFlags, onCellClick } = useContactsTable()
  const dispatch = useAppDispatch()
  const assistants = useAppSelector((state) => state.app.platform.agents.list)

  const { data, metadata } = useAppSelector(state => state.app.contacts.list)
  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const { organization } = useParams()

  // Memoize the columns so they don't re-render on every render
  const columns = React.useMemo(() => getColumns(), [])

 
  const filterFields: DataTableFilterField<any>[] = [
    {
      label: "ID",
      value: "id",
      placeholder: "Search by customer id, phone number, email address",
    },
    {
      label: "Type",
      value: "contact_type",
      options: [
        {
          "label": "Contact",
          "value": "CONTACT"
        },
        {
          "label": "Candidate",
          "value": "CANDIDATE"
        },
        {
          "label": "Lead",
          "value": "LEAD"
        },
        {
          "label": "Customer",
          "value": "CUSTOMER"
        },
        {
          "label": "Prospect",
          "value": "PROSPECT"
        }
      ].map((status) => ({
        label: status.label,
        value: status.value,
        withCount: true,
      })),
    },
  ]

  React.useEffect(() => {
    if (organization) {
      dispatch(listContacts(organization))
    }
  }, [organization])

  const { table, debouncedSetFilterParams } = useDataTable({
    data,
    columns,
    pageCount: metadata?.total_count || 0,
    filterFields,
    initialState: {
      sorting: [{ id: "createdAt", desc: true }],
      columnPinning: { right: ["actions"] },
      columnVisibility: {
        agent: false,
        serial: false
      }
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    shallow: false,
    clearOnDefault: true,
  })

  const Toolbar = DataTableToolbar

  return (
    <DataTable
      onCellClick={onCellClick}
      table={table}
    >
      <Toolbar hideCalendar setFilterParams={debouncedSetFilterParams} table={table} filterFields={filterFields}>
        {/* <TasksTableToolbarActions table={table} /> */}
      </Toolbar>
    </DataTable>
  )
}
