import { RESET_STATE, SET_FORGOT_PASSWORD_ERROR, SET_FORGOT_PASSWORD_LOADING, SET_FORGOT_PASSWORD_SUCCESS, SET_LOGIN_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS, SET_RESET_PASSWORD_ERROR, SET_RESET_PASSWORD_LOADING, SET_RESET_PASSWORD_SUCCESS } from "./constants"

const INITIAL_STATE = {
    login: {
        state: "pending",
        data: {},
        error: ''
    },
    forgot: {
        state: "pending",
        data: {},
        error: ''
    },
    reset: {
        state: "pending",
        data: {},
        error: ''
    }
}

export const LoginReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_LOGIN_LOADING:
            return {
                ...state,
                login: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_LOGIN_ERROR:
            return {
                ...state,
                login: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case SET_FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                forgot: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgot: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgot: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case SET_RESET_PASSWORD_LOADING:
            return {
                ...state,
                reset: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                reset: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_RESET_PASSWORD_ERROR:
            return {
                ...state,
                reset: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case RESET_STATE:
            return {
                login: {
                    state: "pending",
                    data: {},
                    error: ''
                },
                forgot: {
                    state: "pending",
                    data: {},
                    error: ''
                },
                reset: {
                    state: "pending",
                    data: {},
                    error: ''
                }
            }
        default:
            return state
    }
}