import { clsx, type ClassValue } from "clsx"
import moment from "moment-timezone"
import { twMerge } from "tailwind-merge"

export function ny(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(
  date: Date | string | number,
  opts: Intl.DateTimeFormatOptions = {}
) {
  return moment(new Date(date)).format("DD MMM, hh:mm a")
}